import React, { useEffect, useState } from 'react'
import { GalleryUploader } from './GalleryUpload'
import { deleteFile, fetchFiles } from './RestClient'
import { FileModel } from './models/File'
import { GalleryItems } from './GalleryItems'
import { Grid } from '@mui/material'

export const GalleryPage: React.FC = () => {
    const [fileList, setFileList] = useState<FileModel[] | null>(null) //only one

    const getList = async () => {
        const data = await fetchFiles()
        if (data && data.length) {
            setFileList(data)
        }
    }

    const removeFile = async (id?: string) => {
        if (!id) {
            throw new Error('ID Not found')
        }
        await deleteFile(id)
        getList()
    }

    useEffect(() => {
        getList()
    }, [])

    return (
        <Grid sx={{ p: 4 }} container spacing={3}>
            <Grid item xs={2}>
                <GalleryUploader fetchFiles={getList} />
            </Grid>

            <hr />
            <Grid item xs={10}>
                {fileList && <GalleryItems removeFile={removeFile} files={fileList} />}
            </Grid>
        </Grid>
    )
}
