import * as Yup from 'yup'
import { TelegramConfigModel, TrelloConfigModel } from '../SettingsModel'

export interface SettingModel {
    trelloConfig: TrelloConfigModel | null
    telegramConfig: TelegramConfigModel | null
}

// ----------------- TrelloConfig -----------------

export const trelloConfigFormInitialValue: TrelloConfigModel = {
    apiKey: '',
    boardId: '',
    listId: '',
    token: ''
}
export const trelloConfigFormValidation = Yup.object({
    boardId: Yup.string().min(1, 'Min 1 Symbol').required('Required'),
    apiKey: Yup.string().min(1, 'Min 1 Symbol').required('Required'),
    token: Yup.string().min(1, 'Min 1 Symbol').required('Required'),
    listId: Yup.string().min(1, 'Min 1 Symbol').required('Required')
})

// ----------------- TrelloConfig -----------------

export const telegramConfigFormInitialValue: TelegramConfigModel = {
    botToken: '',
    chatId: ''
}
export const telegramConfigFormValidation = Yup.object({
    botToken: Yup.string().min(1, 'Min 1 Symbol').required('Required'),
    chatId: Yup.string().min(1, 'Min 1 Symbol').required('Required')
})
