import Paper, { PaperProps } from '@mui/material/Paper'
import Draggable from 'react-draggable'
import 'react-resizable/css/styles.css'

export function PaperComponent(props: PaperProps) {
    return (
        <Draggable handle="#draggable-dialog-title" cancel={'[class*="MuiDialogContent-root"]'}>
            <Paper {...props} />
        </Draggable>
    )
}
