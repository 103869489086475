import { Answer, MatrixTableQuestionUiModel, Statement } from '@feeba/types'
import { BoxProps, Stack, ToggleButton, ToggleButtonGroup, Typography } from '@mui/material'
import { FC } from 'react'
import { FramelessEdit } from '../../component/FramelessEdit'

export const MatrixTableComponent: FC<Props> = ({ initData: data, sx, enableEdit = true, onUpdate }) => {
    const setQuestionTextAction = (str: string) => {
        onUpdate({ ...data, question: { ...data.question, text: str } })
    }

    const changeStatementText = (statement: Statement, text: string) => {
        const newStatements = data.statements.map((s) => {
            if (s.id === statement.id) {
                return { ...s, text: text }
            } else {
                return s
            }
        })
        onUpdate({ ...data, statements: newStatements })
    }

    const changeAnswer = (answer: Answer, text: string) => {
        const newAnswers = data.answers.map((s) => {
            if (s.id === answer.id) {
                return { ...s, text: text }
            } else {
                return s
            }
        })
        onUpdate({ ...data, answers: newAnswers })
    }

    return (
        <Stack direction="column" sx={{ p: 1 }}>
            <FramelessEdit value={data.question.text} size="small" sx={{ pl: 2 }} onChange={(event) => enableEdit && setQuestionTextAction(event.target.value)} />
            {data.statements.map((statement, index: number) => {
                return (
                    <Stack direction="row" sx={{ pl: 2 }} key={index}>
                        <Typography sx={{ marginTop: '10px' }} alignContent="bottom" fontSize={14} component="div">
                            {index + 1}
                        </Typography>
                        <FramelessEdit
                            value={statement.text}
                            size="small"
                            sx={{ width: '100%', pl: 1, flexGrow: 1, color: 'white' }}
                            onChange={(event) => enableEdit && changeStatementText(statement, event.target.value)}
                        />
                        <ToggleButtonGroup sx={{ pt: 0.5, height: '35px' }} size="small" aria-label="device">
                            {data.answers.map((answer, i, arr) => {
                                let bgcolor = 'yellow'
                                if (i === 0) bgcolor = 'green'
                                else if (i === 1) {
                                    if (arr.length === 2) bgcolor = 'red'
                                    else bgcolor = 'gray'
                                } else if (i === 2) bgcolor = 'red'
                                return (
                                    <ToggleButton value={answer} key={i} sx={{ width: '55px', height: '35px', color: 'white', bgcolor, p: 0, m: 0 }}>
                                        <FramelessEdit
                                            sx={{ flexGrow: 1, width: '100%' }}
                                            inputProps={{
                                                style: { color: 'white', fontSize: 12, padding: 0, textAlign: 'center' }
                                            }}
                                            frameless={false}
                                            value={answer.text}
                                            onChange={(event) => enableEdit && changeAnswer(answer, event.target.value)}
                                        />
                                    </ToggleButton>
                                )
                            })}
                        </ToggleButtonGroup>
                    </Stack>
                )
            })}
        </Stack>
    )
}

export interface Props extends BoxProps {
    initData: MatrixTableQuestionUiModel
    onUpdate: (data: MatrixTableQuestionUiModel) => void
    enableEdit: boolean
}
