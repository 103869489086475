import { ItemType } from '@feeba/types'
import { Add } from '@mui/icons-material'
import { Box, BoxProps, Button, Typography } from '@mui/material'
import React, { FC } from 'react'
import { ActionTypes, PageBreakAction } from '../state/actions'
import { surveyDispatcher } from '../state/hooks'
import { SelectableItem } from './SelectableItem'

const buttonSx = {
    m: 1,
    textTransform: 'none',
    color: 'blue',
    bgcolor: '#E5F3FD',
    '&:hover': {
        bgcolor: '#E5F3FD',
        color: 'blue'
    },
    '&': {
        bgcolor: 'transparent',
        color: '#E5F3FD'
    }
}

export const OptionalPageDividerComponent: FC<Props & BoxProps> = ({ data, categoryId, index, sx }) => {
    const [model, setModel] = React.useState<OptionalPageDividerUiModel>(data)
    const dispatch = surveyDispatcher()

    const boxSx = {
        '&:hover': {
            border: '#fff'
        },
        '&': {
            bgcolor: 'transparent'
        },
        height: 40
    }

    let view: React.ReactNode = null

    if (model.isSet) {
        view = (
            <Box sx={{ height: 40 }} position={'relative'} display="flex" justifyContent="center" alignItems="center">
                <Typography position={'absolute'} sx={{ zIndex: 200, p: 1, bgcolor: 'white', color: 'grey' }}>
                    Page break
                </Typography>
                <Box
                    sx={{ zIndex: 199 }}
                    style={{
                        height: 1,
                        width: '100%',
                        borderRadius: 1,
                        borderWidth: 1,
                        borderStyle: 'dashed',
                        borderColor: 'grey'
                    }}
                />
            </Box>
        )
    } else {
        view = (
            <Box sx={boxSx} display="flex" justifyContent="center" alignItems="center">
                <Button
                    sx={buttonSx}
                    startIcon={<Add />}
                    size={'small'}
                    onClick={() => {
                        const newValue = { ...model, isSet: true }
                        setModel(newValue)
                        dispatch({
                            type: ActionTypes.PAGE_BREAK_UPDATE,
                            payload: {
                                currentCategoryId: categoryId,
                                pageBreakIndex: index,
                                data: newValue
                            }
                        } as PageBreakAction)
                    }}
                >
                    Add page break
                </Button>
            </Box>
        )
    }
    return (
        <SelectableItem
            sx={sx}
            showRemoveButtons={model.isSet}
            onRemove={() => {
                const newValue = { ...model, isSet: false }
                setModel(newValue)
                dispatch({
                    type: ActionTypes.PAGE_BREAK_UPDATE,
                    payload: {
                        currentCategoryId: categoryId,
                        pageBreakIndex: index,
                        data: newValue
                    }
                } as PageBreakAction)
            }}
            showAddButtons={false}
            showBorder={false}
            isSelected={false}
            onSelected={() => {}}
        >
            {view}
        </SelectableItem>
    )
}

export interface Props {
    data: OptionalPageDividerUiModel
    categoryId: string
    index: number
}

export interface OptionalPageDividerUiModel {
    type: ItemType.Divider
    isSet: boolean
}
