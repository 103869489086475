import React, { FC, useEffect, useRef, useState } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { LayoutSplashScreen } from '../../../../_metronic/layout/core'
import { bootstrap } from './AuthCRUD'
import { IAuthState, actions } from './AuthRedux'
import { Feeba } from '@feeba/web-sdk'

interface OwnProps {
    children: React.ReactNode
}
enum BootstrapState {
    None,
    Requested,
    Success
}

export const AppBoostrap: FC<OwnProps> = (props) => {
    const bootstrapping = useRef<boolean>(false)
    const dispatch = useDispatch()
    const authState = useSelector<IAuthState>((authState) => authState, shallowEqual) as IAuthState

    // We should request fetch bootsrap config if we identify that user is logged in but has not fetched the Bootstrap config yet
    useEffect(() => {
        const requestBootstrap = async () => {
            try {
                if (!authState.auth?.bootstrap) {
                    console.log('Bootstraping...')
                    bootstrapping.current = true
                    const bootstrapConfig = await bootstrap()
                    dispatch(actions.bootStrap(bootstrapConfig))
                    bootstrapping.current = false
                }
            } catch (error) {
                console.error(`Bootstrap failed. We are logging out:: $error`)
                bootstrapping.current = false
                dispatch(actions.logout())
            }
        }

        if (authState.auth?.accessToken && !authState.auth?.bootstrap && !bootstrapping.current) {
            console.log('Requesting bootstrap...')
            requestBootstrap()
        } else if (!bootstrapping.current && !authState.auth?.bootstrap) {
            dispatch(actions.logout())
        }
    }, [authState])

    let showSplashScreen = true
    if (!authState.auth) {
        // User has not authenticated yet. Login pages need to be shown. Let the children be rendered
        showSplashScreen = false
    } else if (bootstrapping.current) {
        // Bootstrap call is ongoing. Show the splash screen
        showSplashScreen = true
    } else if (authState.auth.bootstrap) {
        // Bootstrap call is successful. Let the children be rendered
        showSplashScreen = false
    }
    console.log('AppBoostrap::showSplashScreen -> ', showSplashScreen, authState)
    return showSplashScreen ? <LayoutSplashScreen /> : <>{props.children}</>
}
