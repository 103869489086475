import { DashboardBootstrapConfig } from '@feeba/types'
import { AdminPanelSettings, Ballot } from '@mui/icons-material'
import AccountCircleIcon from '@mui/icons-material/AccountCircle'
import { Box, IconButton, Tab, Tabs, Toolbar, Typography } from '@mui/material'
import React, { FC, useState } from 'react'
import { shallowEqual, useSelector } from 'react-redux'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import { IAuthState } from '../../../../app/modules/auth'
import { toAbsoluteUrl } from '../../../helpers'
import { HeaderUserMenu } from './HeaderUserMenu'

const tabs = [{ label: 'Home', path: '/surveys_home', icon: <Ballot /> }]
const Topbar: FC = () => {
    const location = useLocation()
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
    const bootstrapingConfig = useSelector<IAuthState>(({ auth }) => auth?.bootstrap, shallowEqual) as DashboardBootstrapConfig
    const navigate = useNavigate()

    const [tabIndex, setTabIndex] = useState(() => {
        // Check if any of the tabValues is in the pathname, if yes return the index of that tab, else return 0
        const index = tabs.findIndex((tabValue) => location.pathname.includes(tabValue.path))
        return index === -1 ? 0 : index
    })

    const handleClick = (event: React.MouseEvent<HTMLElement>) => setAnchorEl(event.currentTarget)
    const handleClose = () => setAnchorEl(null)

    return (
        <Toolbar disableGutters>
            <Link to="/">
                <Box
                    component="img"
                    sx={{
                        mb: -5,
                        mt: -5,
                        maxHeight: { xs: 100, md: 100 },
                        maxWidth: { xs: 100, md: 100 }
                    }}
                    src={toAbsoluteUrl('/media/logos/feeba-logo.png')}
                />
            </Link>
            <Typography
                variant="h6"
                noWrap
                sx={{
                    mr: 2,
                    display: { xs: 'none', md: 'flex' },
                    fontFamily: 'monospace',
                    fontWeight: 700,
                    letterSpacing: '.3rem',
                    color: 'inherit',
                    textDecoration: 'none'
                }}
            >
                FEEBA
            </Typography>

            <Tabs
                value={tabIndex}
                sx={{ flexGrow: 1 }}
                textColor="inherit"
                indicatorColor="primary"
                centered
                TabIndicatorProps={{ sx: { backgroundColor: 'white', height: 5 } }}
                onChange={(event, index) => setTabIndex(index)}
            >
                {tabs.map((tab, index) => (
                    <Tab key={index} label={tab.label} icon={tab.icon} onClick={() => navigate(tabs[index].path, { replace: false })} />
                ))}
            </Tabs>

            <IconButton onClick={handleClick}>
                <AccountCircleIcon sx={{ width: 48, height: 48 }} />
            </IconButton>
            {anchorEl && <HeaderUserMenu anchorEl={anchorEl} onItemClicked={handleClose} />}
        </Toolbar>
    )
}

export { Topbar }
