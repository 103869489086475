import { DashboardBootstrapConfig } from '@feeba/types'
import axios, { AxiosError } from 'axios'
import { AuthToken } from '../models/AuthToken'

export const LOGIN_URL = `/dashboard/auth/login`
export const REGISTER_URL = `/dashboard/auth/register`
export const REQUEST_PASSWORD_URL = `/dashboard/auth/forgot-password`
export const TOKEN_VERIFICATION = `/dashboard/auth/token`
export const RESET_PASSWORD_URL = `/dashboard/auth/reset-password`
export const PROFILE_URL = `/dashboard/settings/profile`

// Server should return AuthModel
export function login(projectName: string, email: string, password: string) {
    return axios
        .post(LOGIN_URL, {
            projectName,
            username: email,
            password
        })
        .catch((err: Error | AxiosError) => {
            if (axios.isAxiosError(err)) {
                const axiosError = err as AxiosError
                if (axiosError.response && (axiosError.response.status >= 400 || axiosError.response.status < 500)) {
                    throw new Error('Login information is incorrect or user not found')
                }
                // Access to config, request, and response
                throw new Error(err.response?.data?.error)
            }
            throw err
        })
}

// Server should return AuthModel
export function register(email: string, firstName: string, lastName: string, projectName: string, password: string, _passwordConfirmation: string) {
    return axios
        .post(REGISTER_URL, {
            email,
            firstName,
            lastName,
            projectName,
            password
        })
        .catch((err: Error | AxiosError) => {
            if (axios.isAxiosError(err)) {
                // Access to config, request, and response
                throw new Error(err.response?.data?.error)
            }
            throw err
        })
}

// Server should return object => { result: boolean } (Is Email in DB)
export function requestPassword(email: string, resetUrl: string) {
    return axios
        .post<{ result: boolean }>(REQUEST_PASSWORD_URL, {
            email,
            resetUrl
        })
        .catch((err: Error | AxiosError) => {
            if (axios.isAxiosError(err)) {
                // Access to config, request, and response
                throw new Error(err.response?.data?.error)
            }
            throw err
        })
}

export function resetPassword(id: string, token: string, password: string) {
    console.log('reset passowrd requested for ', id)

    return axios
        .post(RESET_PASSWORD_URL, {
            id,
            token,
            password
        })
        .catch((err: Error | AxiosError) => {
            if (axios.isAxiosError(err)) {
                // Access to config, request, and response
                throw new Error(err.response?.data?.error)
            }
            throw err
        })
}

export function getAuthToken() {
    return axios.get<AuthToken>(TOKEN_VERIFICATION)
}

export function bootstrap() {
    return axios.get<DashboardBootstrapConfig>('dashboard/bootstrap').then(({ data }) => data)
}
