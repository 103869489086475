import React, { FC, useEffect, useState } from 'react'
import { TrelloConfig } from './components/TrelloConfig'
import { TelegramConfig } from './components/TelegramConfig'
import { Box, Divider, Skeleton } from '@mui/material'
import { AppConfig } from './SettingsModel'
import { fetchSettings } from './RestClient'
import { TokenConfig } from './components/TokenConfig'

export const Settings: FC = () => {
    const [data, setData] = useState<AppConfig>()
    const fetch = () => {
        fetchSettings()
            .then((config) => setData(config))
            .catch((err) => console.log(JSON.stringify(err, null, 2)))
    }
    useEffect(() => {
        fetch()
    }, [])
    return (
        <Box sx={{ p: 4 }}>
            {data ? <TrelloConfig config={data} /> : <Skeleton variant="rectangular" height={118} />}
            <Divider sx={{ m: 1 }} />
            {data ? <TelegramConfig config={data} /> : <Skeleton variant="rectangular" height={118} />}
            <Divider sx={{ m: 1 }} />
            {data ? <TokenConfig config={data} /> : <Skeleton variant="rectangular" height={118} />}
        </Box>
    )
}
