import { RuleSet, RuleType, TriggerRule } from '@feeba/types'
import { Close } from '@mui/icons-material'
import { Box, Divider, IconButton, Stack, Typography } from '@mui/material'
import { FC, useState } from 'react'
import { AddConditionButton, eventMenus } from './AddConditionButton'
import { DurationSinceLastInApp } from './DurationSinceLastInApp'
import { EventTrigger, EventTriggerData } from './EventTrigger'
import { SessionDuration } from './SessionDuration'
import { StartWithKnobBlock } from './StartWithKnobBlock'

export const eventsData = {
    eventsData: {
        property: 'On Event:',
        propertyHint: 'Event name',
        operations: [
            { value: 'ex', label: 'exists' },
            { value: 'eq', label: 'equals' },
            { value: 'neq', label: 'not equal' },
            { value: 'gt', label: 'greater than' },
            { value: 'gte', label: 'greater than or equal' },
            { value: 'lt', label: 'less than' },
            { value: 'lte', label: 'less than or equal' }
        ],
        valueHint: 'Value'
    } as EventTriggerData,
    sessionDurationData: {
        property: 'Session Duration:',
        operations: [
            { value: 'gt', label: 'greater than' },
            { value: 'gte', label: 'greater than or equal' },
            { value: 'lt', label: 'less than' },
            { value: 'lte', label: 'less than or equal' }
        ],
        valueHint: 'Enter number'
    } as EventTriggerData,
    sinceLastData: {
        property: 'Since last survey:',
        operations: [
            { value: 'gt', label: 'greater than' },
            { value: 'gte', label: 'greater than or equal' },
            { value: 'lt', label: 'less than' },
            { value: 'lte', label: 'less than or equal' }
        ],
        valueHint: 'Enter number'
    } as EventTriggerData
}

export const ConditionalAndBlock: FC<Props> = ({ initData: initRules, onAllTriggerRulesRemoved, saveRules }) => {
    const [ruleSet, setRuleSet] = useState<RuleSet>(initRules)

    const addingNewRule = (newRule: TriggerRule) => {
        for (const rule of ruleSet.triggers) {
            // Ensure there is no rule with the same type
            if (rule.type === newRule.type) {
                return
            }
        }

        const updatedRules: RuleSet = {
            triggers: [],
            startWithKnob: ruleSet.startWithKnob
        }
        if (newRule.type === RuleType.EVENT) {
            // Event should stay on top of the rule set
            updatedRules.triggers = [newRule, ...ruleSet.triggers]
        } else {
            updatedRules.triggers = [...ruleSet.triggers, newRule]
        }

        setRuleSet(updatedRules)
        saveRules(updatedRules)
    }

    const updateSigneRuleByIndex = (indexConditional: number, rule: TriggerRule) => {
        // No need to update the state. State is updated by the child component. Array value is used to send to server
        ruleSet.triggers[indexConditional] = rule
        saveRules(ruleSet)
    }

    return (
        <Stack
            direction="column"
            justifyContent="center"
            alignItems="stretch"
            spacing={2}
            sx={{
                bgcolor: '#f5f4f2',
                p: 2,
                border: '1px solid #D3D3D3',
                borderRadius: 2
            }}
        >
            {ruleSet.triggers.map((rule, index, arr) => {
                let ruleComponent: JSX.Element | null = null

                if (rule.type === RuleType.EVENT) {
                    ruleComponent = <EventTrigger data={eventsData.eventsData} key={index} initValue={rule} onUpdate={(newRule) => updateSigneRuleByIndex(index, newRule)} />
                }
                if (rule.type === RuleType.SESSION_DURATION) {
                    ruleComponent = (
                        <SessionDuration data={eventsData.sessionDurationData} key={index} initValue={rule} onUpdate={(newRule) => updateSigneRuleByIndex(index, newRule)} />
                    )
                } else if (rule.type === RuleType.SINCE_LAST) {
                    ruleComponent = (
                        <DurationSinceLastInApp data={eventsData.sinceLastData} key={index} initValue={rule} onUpdate={(newRule) => updateSigneRuleByIndex(index, newRule)} />
                    )
                } else {
                }
                if (ruleComponent == null) return <div></div>

                return (
                    <Stack direction="row" justifyContent="space-between" spacing={1} key={index} alignContent={'center'} alignItems={'center'}>
                        {ruleComponent}
                        <Box sx={{ flexGrow: 1 }} />
                        {index === arr.length - 1 ? (
                            <AddConditionButton onSelection={addingNewRule} disabledTypes={ruleSet.triggers.map((r) => r.type)} menuItems={eventMenus} />
                        ) : (
                            <Typography>Add</Typography>
                        )}
                        <IconButton
                            onClick={() => {
                                if (ruleSet.triggers.length === 1) {
                                    onAllTriggerRulesRemoved()
                                } else {
                                    const newRuleSet: RuleSet = {
                                        triggers: [...ruleSet.triggers],
                                        startWithKnob: ruleSet.startWithKnob
                                    }
                                    newRuleSet.triggers.splice(index, 1)
                                    setRuleSet(newRuleSet)
                                    saveRules(newRuleSet)
                                }
                            }}
                        >
                            <Close />
                        </IconButton>
                    </Stack>
                )
            })}
            <Divider sx={{ bgcolor: 'black', mx: -1, my: -1 }} />
            <StartWithKnobBlock
                initValue={ruleSet.startWithKnob}
                onUpdate={(data) => {
                    const newRuleSet = { ...ruleSet, startWithKnob: data }
                    setRuleSet(newRuleSet)
                    saveRules(newRuleSet)
                }}
            />
        </Stack>
    )
}

export interface Props {
    initData: RuleSet
    onAllTriggerRulesRemoved: () => void
    saveRules: (rules: RuleSet) => void
}
