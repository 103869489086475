import { SurveyModel } from '@feeba/types'
import { Box, CircularProgress, Stack } from '@mui/material'
import { FC, useEffect, useState } from 'react'
import { Navigate, Outlet, Route, Routes } from 'react-router-dom'
import usePopup from '../../../../popups/notifications/hook'
import { fetchSurvey } from '../../RestClient'
import { DistributionSideMenu, navLinks } from './DistributionSideMenu'

export const DistributionContainer: FC<Props> = ({ surveyId }) => {
    const [survey, setSurvey] = useState<SurveyModel | null>(null)
    const { showNotification } = usePopup()

    useEffect(() => {
        fetchSurvey(surveyId)
            .then((result) => {
                setSurvey(result)
            })
            .catch((err) => {
                console.error('fetching', JSON.stringify(err, null, 2))
                showNotification({ text: err.message, type: 'error' })
            })
    }, [surveyId])

    return survey ? (
        <Routes>
            <Route
                element={
                    <Stack direction={'row'} sx={{ width: '100%' }}>
                        <DistributionSideMenu />

                        <Box sx={{ flexGrow: 1, bgcolor: 'lightgray', width: '100%', overflow: 'hidden' }}>
                            <Outlet />
                        </Box>
                    </Stack>
                }
            >
                {navLinks.map((link, index) => {
                    if (link) {
                        return <Route key={index} path={link.path} element={link.element(survey)} />
                    }
                })}
                <Route index element={<Navigate to={navLinks[6]?.path ?? ''} />} />
            </Route>
        </Routes>
    ) : (
        <Box>
            {' '}
            <CircularProgress />{' '}
        </Box>
    )
}
export interface Props {
    surveyId: string
}
