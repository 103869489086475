/**
 * High level router.
 *
 * Note: It's recommended to compose related routes in internal router
 * components (e.g: `src/app/modules/Auth/pages/AuthPage`, `src/app/BasePage`).
 */

import { FC } from 'react'
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { PrivateRoutes } from './PrivateRoutes'
import { ErrorsPage } from '../modules/errors/ErrorsPage'
import { actions, AuthPage, IAuthState, Logout } from '../modules/auth'
import * as _redux from '../../setup'
import { App } from '../App'
import axios from 'axios'

const AppRoutes: FC = () => {
    const isAuthorized = useSelector<IAuthState>(({ auth }) => auth?.accessToken, shallowEqual)
    const dispatch = useDispatch()

    /**
     * Inject Metronic interceptors for axios.
     * @see https://github.com/axios/axios#interceptors
     */
    _redux.setupAxios(axios, () => {
        console.error('on401')
        dispatch(actions.logout())
    })
    return (
        <BrowserRouter>
            <Routes>
                <Route element={<App />}>
                    <Route path="error/*" element={<ErrorsPage />} />
                    <Route path="logout" element={<Logout />} />
                    {isAuthorized ? (
                        <>
                            <Route path="/*" element={<PrivateRoutes />} />
                        </>
                    ) : (
                        <>
                            <Route path="auth/*" element={<AuthPage />} />
                            <Route path="*" element={<Navigate to="/auth" />} />
                        </>
                    )}
                </Route>
            </Routes>
        </BrowserRouter>
    )
}

export { AppRoutes }
