import { RuleType, TriggerRule } from '@feeba/types'
import { Add, AlarmOn, ArrowDropDown, Sell, Timelapse, Web } from '@mui/icons-material'
import { Box, BoxProps, Button, Divider, Menu, MenuItem } from '@mui/material'
import { FC, useState } from 'react'
import { eventsData } from './ConditionalAndBlock'
import { pagesData } from './PageConditionalAndBlock'

export interface Props extends BoxProps {
    onSelection: (rule: TriggerRule) => void
    disabledTypes: RuleType[]
    menuItems: MenuItemData[]
}

export enum ClickType {
    InApp = 0,
    SessionDuration = 1,
    DurationSinceLastInApp = 2
}

export interface MenuItemData {
    label: string
    icon: JSX.Element
    type: RuleType
    default: TriggerRule
}

export const eventMenus: MenuItemData[] = [
    {
        label: 'Event Trigger',
        icon: <Web />,
        type: RuleType.EVENT,
        default: {
            type: RuleType.EVENT,
            eventName: '',
            value: '1',
            conditional: eventsData.eventsData.operations[0].value
        }
    },
    {
        label: 'Session Duration',
        icon: <AlarmOn />,
        type: RuleType.SESSION_DURATION,
        default: {
            type: RuleType.SESSION_DURATION,
            eventName: RuleType.SESSION_DURATION,
            value: '90',
            conditional: eventsData.sessionDurationData.operations[0].value
        }
    },
    {
        label: 'Duration since Survey',
        icon: <Timelapse />,
        type: RuleType.SINCE_LAST,
        default: {
            type: RuleType.SINCE_LAST,
            eventName: RuleType.SINCE_LAST,
            value: '86400',
            conditional: eventsData.sinceLastData.operations[0].value
        }
    }
]

export const pagesMenus: MenuItemData[] = [
    {
        label: 'Page Trigger',
        icon: <Sell />,
        type: RuleType.SCREEN,
        default: {
            type: RuleType.SCREEN,
            eventName: '',
            value: '1',
            conditional: pagesData.eventsData.operations[0].value
        }
    }
]

export const AddConditionButton: FC<Props> = ({ onSelection, sx, disabledTypes, menuItems }) => {
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
    const open = Boolean(anchorEl)
    const handleClick = (event: React.MouseEvent<HTMLElement>) => setAnchorEl(event.currentTarget)
    const handleClose = () => setAnchorEl(null)

    return (
        <Box sx={{ ...sx, bgcolor: 'white' }}>
            <Button
                variant="outlined"
                startIcon={<Add />}
                endIcon={<ArrowDropDown />}
                aria-controls={open ? 'demo-customized-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={open ? 'true' : undefined}
                disableElevation
                onClick={handleClick}
            >
                Add Trigger
            </Button>

            <Menu MenuListProps={{ 'aria-labelledby': 'demo-customized-button' }} anchorEl={anchorEl} open={open} onClose={handleClose}>
                {menuItems
                    .map((menu, index) => {
                        return (
                            <MenuItem
                                key={index}
                                disabled={disabledTypes.some((e) => e === menu.type)}
                                onClick={() => {
                                    onSelection(menu.default)
                                    handleClose()
                                }}
                            >
                                {menu.icon}
                                {menu.label}
                            </MenuItem>
                        )
                    })
                    .flatMap((menuItem, index) => {
                        return index < menuItems.length - 1 ? [menuItem, <Divider key={100 + index} sx={{ my: 0.5 }} />] : [menuItem]
                    })}
            </Menu>
        </Box>
    )
}
