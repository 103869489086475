import { ModalPosition } from '@feeba/types'
import { Stack, SvgIcon, Typography } from '@mui/material'
import { FC, useState } from 'react'

export const ModalLocationSelection: FC<Props> = ({ modalPosition, onPositionChange }) => {
    const [currentSelection, setCurrentSelection] = useState(modalPosition)

    return (
        <Stack direction={'row'} alignContent={'center'} spacing={2}>
            {items.map((item, index) => (
                <Stack
                    key={index}
                    direction="column"
                    alignItems="center"
                    justifyContent={'center'}
                    sx={{
                        cursor: 'pointer',
                        '&:hover': { color: 'primary.main' },
                        borderRadius: 2,
                        width: '60px',
                        height: '110px',
                        p: 2,
                        bgcolor: currentSelection === item.value ? 'primary.light' : 'inherit',
                        border: `2px solid ${currentSelection === item.value ? 'black' : 'grey'}`
                    }}
                    onClick={() => {
                        setCurrentSelection(item.value)
                        onPositionChange(item.value)
                    }}
                >
                    {item.icon(currentSelection === item.value ? 'blue' : 'grey')}
                    <Typography variant="body1">{item.label}</Typography>
                </Stack>
            ))}
        </Stack>
    )
}

export interface Props {
    modalPosition: ModalPosition
    onPositionChange: (position: ModalPosition) => void
}

const items = [
    {
        label: 'Top',
        value: ModalPosition.TOP,
        icon: (color: string) => (
            <SvgIcon sx={{ width: '100%', height: '100%', color: 'red' }}>
                <svg width="100%" height="100%" viewBox="0 0 40 70" fill="none">
                    <rect className="primaryStroke" x="1" y="1" width="38" height="68" rx="2" stroke={color} strokeWidth="2"></rect>
                    <rect className="primaryFill" x="4" y="4" width="32" height="12" rx="1" fill={color}></rect>
                </svg>
            </SvgIcon>
        )
    },
    {
        label: 'Center',
        value: ModalPosition.CENTER,
        icon: (color: string) => (
            <SvgIcon sx={{ width: '100%', height: '100%' }}>
                <svg width="100%" height="100%" className="" viewBox="0 0 40 70" fill="none">
                    <rect className="primaryStroke" x="1" y="1" width="38" height="68" rx="2" stroke={color} strokeWidth="2"></rect>
                    <rect className="primaryFill" x="4" y="23" width="32" height="24" rx="1" fill={color}></rect>
                </svg>
            </SvgIcon>
        )
    },
    {
        label: 'Bottom',
        value: ModalPosition.BOTTOM,
        icon: (color: string) => (
            <SvgIcon sx={{ width: '100%', height: '100%' }}>
                <svg width="100%" height="100%" className="" viewBox="0 0 40 70" fill="none">
                    <rect className="primaryStroke" x="1" y="1" width="38" height="68" rx="2" stroke={color} strokeWidth="2"></rect>
                    <rect className="primaryFill" x="4" y="54" width="32" height="12" rx="1" fill={color}></rect>
                </svg>
            </SvgIcon>
        )
    },
    {
        label: 'Full',
        value: ModalPosition.FULL,
        icon: (color: string) => (
            <SvgIcon sx={{ width: '100%', height: '100%' }}>
                <svg width="100%" height="100%" className="" viewBox="0 0 40 70" fill="none">
                    <rect className="primaryStroke" x="1" y="1" width="38" height="68" rx="2" stroke={color} strokeWidth="2"></rect>
                    <rect className="primaryFill" x="4" y="4" width="32" height="62" rx="1" fill={color}></rect>
                </svg>
            </SvgIcon>
        )
    }
]
