import { Box, CircularProgress, Stack, Typography } from '@mui/material'
import { FC, ReactElement } from 'react'
import { useSelector } from 'react-redux'
import { SurveyColumnComponent } from './SurveyColumnComponent'
import { BuilderTopBar } from './component/BuilderTopBar'
import { PropertyColumn } from './property_column/PropertyColumn'
import { IState } from './state/hooks'

export const SurveyBuilder: FC<Props> = () => {
    const surveyToEdit = useSelector((state: IState) => state.survey)

    let view: ReactElement
    if (!surveyToEdit) {
        view = <Typography>Survey not found</Typography>
    } else if (surveyToEdit) {
        view = (
            <Stack direction={'row'} sx={{ display: 'flex', height: '100%', width: '100%' }}>
                <Stack direction={'column'} sx={{ width: '100%' }}>
                    <BuilderTopBar />
                    {/* I need block with margin*/}
                    <Stack direction={'column'} sx={{ padding: 1 }}>
                        <SurveyColumnComponent surveyToEdit={surveyToEdit} onSurveyAdded={() => {}} />
                    </Stack>
                </Stack>
                <Box sx={{ height: '70%', bgcolor: '#fff' }}>
                    <PropertyColumn onCategoryUpdated={() => {}} />
                </Box>
            </Stack>
        )
    } else {
        view = (
            <Box>
                {' '}
                <CircularProgress />{' '}
            </Box>
        )
    }
    return view
}

export interface Props {}
