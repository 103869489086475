import { AppRegistrationOutlined, CalendarMonthOutlined, CancelPresentationOutlined, ReportProblemOutlined, SentimentSatisfiedAltOutlined } from '@mui/icons-material'
import CloseIcon from '@mui/icons-material/Close'
import { Box, Card, CardActionArea, CardContent, Grid } from '@mui/material'
import AppBar from '@mui/material/AppBar'
import Dialog from '@mui/material/Dialog'
import IconButton from '@mui/material/IconButton'
import Toolbar from '@mui/material/Toolbar'
import Typography from '@mui/material/Typography'
import { green } from '@mui/material/colors'
import { makeStyles } from '@mui/styles'
import { FC, Fragment } from 'react'
import { useNavigate } from 'react-router-dom'
import { createSurvey, createSurveyTemplate } from '../../RestClient'
import { Transition } from './util/Transition'

interface TemplatesProps {
    open: boolean
    onClose: () => void
    includeBlackTemplate?: boolean
}

const useStyles = makeStyles({
    card: {
        cursor: 'pointer',
        transition: 'border-color 0.3s',
        borderColor: '#007FAA',
        borderWidth: '1px',
        borderStyle: 'solid',
        '&:hover': {
            borderColor: '#02BF6F',
            backgroundColor: '#E5F9F1'
        }
    }
})

export const Templates: FC<TemplatesProps> = ({ open, onClose, includeBlackTemplate = false }) => {
    const classes = useStyles()
    const navigate = useNavigate()

    const handleClose = () => {
        onClose() // Call the onClose prop to notify the parent component
    }

    const saveSurvey = (surveyName: string, surveyType: string) => {
        console.log('Creating Survey:', surveyName)
        createSurveyTemplate(surveyName, surveyType)
            .then((data) => {
                console.log('data from survey template', data)
                navigate({
                    pathname: `/survey/${data.id}`
                })
            })
            .catch((err: Error) => {
                console.error(JSON.stringify(err, null, 2))
            })
    }

    const handleReportProblem = () => {
        console.log('Report Problem')
        const surveyName = 'report-problem-survey-template'
        saveSurvey(surveyName, 'report-problem')
        handleClose()
    }

    function handleRegistration() {
        const surveyName = 'registration-failed-template'
        console.log('Registration: ', surveyName)
        saveSurvey(surveyName, 'registration')
        handleClose()
    }

    function handleCartCancellation() {
        const surveyName = 'cart-cancellation-template'
        console.log('Cart Cancellation: ', surveyName)
        saveSurvey(surveyName, 'cart-cancellation')
        handleClose()
    }

    function handleNpsSurvey() {
        const surveyName = 'nps-survey-template'
        console.log('NPS Survey: ', surveyName)
        saveSurvey(surveyName, 'nps-survey')
        handleClose()
    }

    function handleBlankSurvey() {
        console.log('Create survey from scratch')
        createSurvey('Untitled Survey')
            .then((data) => {
                console.log('data from survey template', data)
                navigate({
                    pathname: `/survey/${data.id}`
                })
            })
            .catch((err: Error) => {
                console.error(JSON.stringify(err, null, 2))
            })
    }

    return (
        <Fragment>
            <Dialog fullScreen open={open} onClose={handleClose} TransitionComponent={Transition}>
                <AppBar sx={{ position: 'relative' }}>
                    <Toolbar>
                        <IconButton edge="start" color="inherit" onClick={handleClose} aria-label="close">
                            <CloseIcon />
                        </IconButton>
                        <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
                            Quick start Templates
                        </Typography>
                        {/*<Button autoFocus color="inherit" onClick={handleClose}>*/}
                        {/*    CREATE*/}
                        {/*</Button>*/}
                    </Toolbar>
                </AppBar>

                <Box sx={{ m: 3 }}>
                    <Grid container spacing={2}>
                        {includeBlackTemplate && (
                            <Grid item>
                                <CardActionArea onClick={handleBlankSurvey}>
                                    <Card sx={{ maxWidth: 250, borderRadius: 2, height: 210 }} className={classes.card}>
                                        <CardContent>
                                            <Box sx={{ display: 'flex', alignItems: 'center', pl: 1, pb: 1 }}>
                                                <CalendarMonthOutlined sx={{ color: green[500], fontSize: 45 }} />
                                            </Box>
                                            <Typography gutterBottom variant="h6" component="div">
                                                Create survey from scratch
                                            </Typography>
                                            <Typography variant="body2" color="text.secondary">
                                                Begin with a blank survey and add your own questions
                                            </Typography>
                                        </CardContent>
                                    </Card>
                                </CardActionArea>
                            </Grid>
                        )}

                        <Grid item>
                            <CardActionArea onClick={handleReportProblem}>
                                <Card sx={{ maxWidth: 250, borderRadius: 2, height: 210 }} className={classes.card}>
                                    <CardContent>
                                        <Box sx={{ display: 'flex', alignItems: 'center', pl: 1, pb: 1 }}>
                                            <ReportProblemOutlined sx={{ color: green[500], fontSize: 45 }} />
                                        </Box>
                                        <Typography gutterBottom variant="h6" component="div">
                                            Report a problem
                                        </Typography>
                                        <Typography variant="body2" color="text.secondary">
                                            Include a button to trigger a survey when a user reports a problem
                                        </Typography>
                                    </CardContent>
                                </Card>
                            </CardActionArea>
                        </Grid>

                        <Grid item>
                            <CardActionArea onClick={handleRegistration}>
                                <Card sx={{ maxWidth: 250, borderRadius: 2, height: 210 }} className={classes.card}>
                                    <CardContent>
                                        <Box sx={{ display: 'flex', alignItems: 'center', pl: 1, pb: 1 }}>
                                            <AppRegistrationOutlined sx={{ color: green[500], fontSize: 45 }} />
                                        </Box>
                                        <Typography gutterBottom variant="h6" component="div">
                                            Registration fail
                                        </Typography>
                                        <Typography variant="body2" color="text.secondary">
                                            Trigger survey if your user fails to register
                                        </Typography>
                                    </CardContent>
                                </Card>
                            </CardActionArea>
                        </Grid>

                        <Grid item>
                            <CardActionArea onClick={handleCartCancellation}>
                                <Card sx={{ maxWidth: 250, borderRadius: 2, height: 210 }} className={classes.card}>
                                    <CardContent>
                                        <Box sx={{ display: 'flex', alignItems: 'center', pl: 1, pb: 1 }}>
                                            <CancelPresentationOutlined sx={{ color: green[500], fontSize: 45 }} />
                                        </Box>
                                        <Typography gutterBottom variant="h6" component="div">
                                            Cart Cancellation
                                        </Typography>
                                        <Typography variant="body2" color="text.secondary">
                                            Understand why users cancel their purchase/order
                                        </Typography>
                                    </CardContent>
                                </Card>
                            </CardActionArea>
                        </Grid>

                        <Grid item>
                            <CardActionArea onClick={handleNpsSurvey}>
                                <Card sx={{ maxWidth: 250, borderRadius: 2, height: 210 }} className={classes.card}>
                                    <CardContent>
                                        <Box sx={{ display: 'flex', alignItems: 'center', pl: 1, pb: 1 }}>
                                            <SentimentSatisfiedAltOutlined sx={{ color: green[500], fontSize: 45 }} />
                                        </Box>
                                        <Typography gutterBottom variant="h6" component="div">
                                            NPS Survey
                                        </Typography>
                                        <Typography variant="body2" color="text.secondary">
                                            Measure the loyalty of your customers
                                        </Typography>
                                    </CardContent>
                                </Card>
                            </CardActionArea>
                        </Grid>
                    </Grid>
                </Box>
            </Dialog>
        </Fragment>
    )
}
