import { Add } from '@mui/icons-material'
import { Box, Button } from '@mui/material'
import { FC } from 'react'

const boxSx = {
    height: 40
}
const buttonSx = {
    m: 1,
    textTransform: 'none',
    color: 'blue',
    bgcolor: '#E5F3FD',
    '&:hover': {
        bgcolor: '#E5F3FD',
        color: 'blue'
    },
    '&': {
        bgcolor: '#E5F3FD',
        color: 'blue'
    }
}

export const AddCategoryComponent: FC<Props> = ({ onCategoryAddClicked }) => {
    return (
        <Box sx={boxSx} display="flex" justifyContent="center" alignItems="center">
            <Button sx={buttonSx} startIcon={<Add />} onClick={() => onCategoryAddClicked()}>
                Add Category
            </Button>
        </Box>
    )
}

export interface Props {
    onCategoryAddClicked: () => void
}
