import { HighLevelTriggerType, RuleSet, TriggerEnum, reverseMapTriggerString } from '@feeba/types'
import { Box, Card, CardContent, CardHeader, FormControl, FormControlLabel, FormLabel, Radio, RadioGroup, Stack, Typography } from '@mui/material'
import { ChangeEvent, useState } from 'react'
import { AddConditionButton, eventMenus, pagesMenus } from './AddConditionButton'
import { ConditionalAndBlock } from './ConditionalAndBlock'
import { PageConditionalAndBlock } from './PageConditionalAndBlock'

export const SectionTriggers = ({ initData, onUpdate }: Props) => {
    const [triggerType, setTriggerType] = useState<TriggerEnum>(initData.type)
    const [data, setData] = useState<SectionTriggersUiModel>(convertHighLevelTriggerTypeToUiModel(initData))

    const dataRefreshed = () => onUpdate(convertToHighLevelTriggerType(data, triggerType))

    return (
        <Card>
            <CardHeader title={'2. Triggers'}></CardHeader>
            <CardContent>
                <FormControl fullWidth>
                    <FormLabel>When should users see this message?</FormLabel>
                    <RadioGroup
                        name="radio-buttons-group"
                        value={triggerType}
                        onChange={(event: ChangeEvent<HTMLInputElement>) => setTriggerType(reverseMapTriggerString(event.target.value))}
                    >
                        <FormControlLabel value={TriggerEnum.ON_APP_OPEN} control={<Radio size="small" />} label="On app open" />
                        <FormControlLabel value={TriggerEnum.ON_SCREEN} control={<Radio size="small" />} label="When certain screen is opened" />
                        {triggerType === TriggerEnum.ON_SCREEN &&
                            data.onScreen.ruleSetList
                                .flatMap((el, index, arr) => (index === arr.length - 1 ? [el] : [el, null]))
                                .map((ruleSetWithId, indexElement) =>
                                    ruleSetWithId ? (
                                        <PageConditionalAndBlock
                                            updateRuleSet={(rules) => {
                                                const indexOf = data.onScreen.ruleSetList.indexOf(ruleSetWithId)
                                                data.onScreen.ruleSetList[indexOf].ruleSet = rules
                                                setData({
                                                    ...data,
                                                    onScreen: { ...data.onScreen, ruleSetList: [...data.onScreen.ruleSetList] }
                                                })
                                                dataRefreshed()
                                            }}
                                            key={ruleSetWithId.ruleSetUiId}
                                            initData={ruleSetWithId.ruleSet}
                                            onAllTriggerRulesRemoved={() => {
                                                // remove the current item from conditional array
                                                let onScreen = data.onScreen.ruleSetList
                                                onScreen.splice(onScreen.indexOf(ruleSetWithId), 1)
                                                const newData = {
                                                    ...data,
                                                    onScreen: {
                                                        ...data.onScreen,
                                                        conditional: [...onScreen]
                                                    }
                                                }
                                                setData(newData)
                                                dataRefreshed()
                                            }}
                                        />
                                    ) : (
                                        <Stack direction="row" alignItems="center" key={indexElement}>
                                            <Typography>Or</Typography>
                                            <Box
                                                sx={{
                                                    height: 1,
                                                    width: '100%',
                                                    ml: 2,
                                                    borderRadius: 1,
                                                    borderWidth: 1,
                                                    borderStyle: 'dashed',
                                                    borderColor: 'grey'
                                                }}
                                            />
                                        </Stack>
                                    )
                                )}
                        {triggerType === TriggerEnum.ON_SCREEN && (
                            <AddConditionButton
                                sx={{ mt: 2 }}
                                onSelection={(rule) => {
                                    data.onScreen.ruleSetList.push({
                                        ruleSetUiId: (Math.random() * 1000).toString(),
                                        ruleSet: { triggers: [rule], startWithKnob: null }
                                    })
                                    setData({
                                        ...data,
                                        onScreen: { ...data.onScreen, ruleSetList: [...data.onScreen.ruleSetList] }
                                    })
                                    dataRefreshed()
                                }}
                                disabledTypes={[]}
                                menuItems={pagesMenus}
                            />
                        )}
                        <FormControlLabel value={TriggerEnum.ON_CONDITION} control={<Radio size="small" />} label="When certain conditions are satisfied during the session" />
                        {triggerType === TriggerEnum.ON_CONDITION &&
                            data.conditions.ruleSetList
                                .flatMap((el, index, arr) => (index === arr.length - 1 ? [el] : [el, null]))
                                .map((ruleSetWithId, indexElement) =>
                                    ruleSetWithId ? (
                                        <ConditionalAndBlock
                                            saveRules={(rules) => {
                                                const indexOf = data.conditions.ruleSetList.indexOf(ruleSetWithId)
                                                data.conditions.ruleSetList[indexOf].ruleSet = rules
                                                setData({
                                                    ...data,
                                                    conditions: { ...data.conditions, ruleSetList: [...data.conditions.ruleSetList] }
                                                })
                                                dataRefreshed()
                                            }}
                                            key={ruleSetWithId.ruleSetUiId}
                                            initData={ruleSetWithId.ruleSet}
                                            onAllTriggerRulesRemoved={() => {
                                                // remove the current item from conditional array
                                                let conditions = data.conditions.ruleSetList
                                                conditions.splice(conditions.indexOf(ruleSetWithId), 1)
                                                const newData = {
                                                    ...data,
                                                    conditions: {
                                                        ...data.conditions,
                                                        conditional: [...conditions]
                                                    }
                                                }
                                                setData(newData)
                                                dataRefreshed()
                                            }}
                                        />
                                    ) : (
                                        <Stack direction="row" alignItems="center" key={indexElement}>
                                            <Typography>Or</Typography>
                                            <Box
                                                sx={{
                                                    height: 1,
                                                    width: '100%',
                                                    ml: 2,
                                                    borderRadius: 1,
                                                    borderWidth: 1,
                                                    borderStyle: 'dashed',
                                                    borderColor: 'grey'
                                                }}
                                            />
                                        </Stack>
                                    )
                                )}
                        {triggerType === TriggerEnum.ON_CONDITION && (
                            <AddConditionButton
                                sx={{ mt: 2 }}
                                onSelection={(rule) => {
                                    data.conditions.ruleSetList.push({
                                        ruleSetUiId: (Math.random() * 1000).toString(),
                                        ruleSet: { triggers: [rule], startWithKnob: null }
                                    })
                                    setData({
                                        ...data,
                                        conditions: { ...data.conditions, ruleSetList: [...data.conditions.ruleSetList] }
                                    })
                                    dataRefreshed()
                                }}
                                disabledTypes={[]}
                                menuItems={eventMenus}
                            />
                        )}
                    </RadioGroup>
                </FormControl>

                <FormControl sx={{ pt: 2 }} fullWidth disabled>
                    <FormLabel>When should this message dismiss?</FormLabel>
                    <RadioGroup
                        name="radio-buttons-group"
                        // value={value} onChange={handleChange}
                    >
                        <FormControlLabel value={0} control={<Radio size="small" />} label="Show until dismissed" />
                        <FormControlLabel value={1} control={<Radio size="small" />} label="Dismiss after certain amount of time" />
                    </RadioGroup>
                </FormControl>
            </CardContent>
        </Card>
    )
}

export interface Props {
    initData: HighLevelTriggerType
    onUpdate: (data: HighLevelTriggerType) => void
}

interface SectionTriggersUiModel {
    onOpen: {
        type: TriggerEnum.ON_APP_OPEN
    }
    onScreen: {
        type: TriggerEnum.ON_SCREEN
        ruleSetList: Array<{
            ruleSet: RuleSet
            ruleSetUiId: string
        }>
    }
    conditions: {
        type: TriggerEnum.ON_CONDITION
        ruleSetList: Array<{
            ruleSet: RuleSet
            ruleSetUiId: string
        }>
    }
}

const convertHighLevelTriggerTypeToUiModel = (data: HighLevelTriggerType): SectionTriggersUiModel => {
    const result: SectionTriggersUiModel = {
        onOpen: {
            type: TriggerEnum.ON_APP_OPEN
        },
        onScreen: {
            type: TriggerEnum.ON_SCREEN,
            ruleSetList: data.type === TriggerEnum.ON_SCREEN ? data.screen.rules.map((el) => ({ ruleSetUiId: (Math.random() * 1000).toString(), ruleSet: el })) : []
        },
        conditions: {
            type: TriggerEnum.ON_CONDITION,
            ruleSetList:
                data.type === TriggerEnum.ON_CONDITION
                    ? data.conditional.rules.map((el) => ({
                          ruleSetUiId: (Math.random() * 1000).toString(),
                          ruleSet: el
                      }))
                    : []
        }
    }
    console.log('convertHighLevelTriggerTypeToUiModel', result)
    return result
}

const convertToHighLevelTriggerType = (data: SectionTriggersUiModel, triggerType: TriggerEnum): HighLevelTriggerType => {
    const result: HighLevelTriggerType = {
        type: triggerType,
        conditional: { rules: data.conditions.ruleSetList.map((el) => el.ruleSet) },
        appOpen: {},
        screen: { rules: data.onScreen.ruleSetList.map((el) => el.ruleSet) }
    }
    return result
}
