import { createContext, useContext, useEffect, useState } from 'react'

export interface TimeRange {
    start: Date
    end: Date
}

export interface TimeRangeContextValue {
    timeRange: TimeRange
    setTimeRange: (timeRange: TimeRange) => void
}

export const TimeRangeContext = createContext<TimeRangeContextValue>({
    timeRange: { start: new Date(), end: new Date() },
    setTimeRange: () => {}
})

export const useTimeRange = () => useContext(TimeRangeContext)
