import { Box, Button, FormControl, Select, SelectChangeEvent, Stack, TextField, Typography } from '@mui/material'
import MenuItem from '@mui/material/MenuItem'
import { FC, useState } from 'react'
import { useSelector } from 'react-redux'
import { Props } from '../../builder/SurveyBuilder'
import { ActionTypes } from '../../builder/state/actions'
import { IState, surveyDispatcher } from '../../builder/state/hooks'
import ColorSelectionButton from './ColorSelectionButton'
import { SurveyStyleUiModel } from '@feeba/types'

const fonts = ['Default', 'Arial', 'Georgia', 'Helvetica', 'Tahoma', 'Times New Roman', 'Verdana']

const fContrast = ['High', 'Medium', 'Low']

const qSPacing = ['Comfortable', 'Extended', 'Compact']

export const Style: FC<Props> = () => {
    const dispatch = surveyDispatcher()
    const surveyToEdit = useSelector((state: IState) => state.survey)

    const updateGlobalSurveyInstance = (surveyStyle: SurveyStyleUiModel) => {
        dispatch({
            type: ActionTypes.SURVEY_BODY_UPDATE,
            payload: {
                survey: {
                    ...surveyToEdit,
                    presentation: {
                        ...surveyToEdit?.presentation,
                        style: surveyStyle
                    }
                }
            }
        })
    }

    const surveyStyle = surveyToEdit?.presentation?.style || {
        primaryColor: '#309DAB',
        secondaryColor: '#309DAB',
        fontFamily: fonts[0],
        foregroundContrast: fContrast[1].toLowerCase(),
        questionSpacing: qSPacing[0],
        questionFontSize: '20px',
        answerFontSize: '16px',
        isAnswerBold: false,
        isQuestionBold: false
    }

    const [questionText, setQuestionText] = useState(parseInt(surveyStyle?.questionFontSize || '20', 10))
    const [answerText, setAnswerText] = useState(parseInt(surveyStyle?.answerFontSize || '16', 10))

    return (
        <Stack sx={{ padding: '2rem', width: '100%', gap: '1.5rem' }}>
            {/* Color Settings Block */}
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: '1rem', // общий отступ между элементами
                    padding: '1rem',
                    backgroundColor: '#f9f9f9',
                    borderRadius: '8px',
                    boxShadow: '0 2px 8px rgba(0,0,0,0.1)'
                }}
            >
                <FormControl fullWidth>
                    <Typography variant="subtitle2" sx={{ mb: 1, fontWeight: '500', color: '#555', fontSize: '1.2rem' }}>
                        Style setting
                    </Typography>
                    <Box
                        display="flex"
                        flexDirection="row"
                        alignItems="center"
                        sx={{
                            marginBottom: '1rem'
                        }}
                    >
                        <ColorSelectionButton
                            color="primary"
                            defaultColor={surveyStyle.primaryColor}
                            onColorChange={(color) => updateGlobalSurveyInstance({ ...surveyStyle, primaryColor: color })}
                        />
                    </Box>
                    <Box display="flex" flexDirection="row" alignItems="center">
                        <ColorSelectionButton
                            color="secondary"
                            defaultColor={surveyStyle.secondaryColor}
                            onColorChange={(color) => updateGlobalSurveyInstance({ ...surveyStyle, secondaryColor: color })}
                        />
                    </Box>
                </FormControl>
            </Box>

            {/* Font Settings Block */}
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: '1rem',
                    padding: '1rem',
                    backgroundColor: '#f9f9f9',
                    borderRadius: '8px',
                    boxShadow: '0 2px 8px rgba(0,0,0,0.1)'
                }}
            >
                <FormControl fullWidth>
                    <Typography variant="subtitle2" sx={{ mb: 1, fontWeight: '500', color: '#555', fontSize: '1.2rem' }}>
                        Font
                    </Typography>
                    <Select
                        id="font-select"
                        value={surveyStyle.fontFamily}
                        size="small"
                        onChange={(event: SelectChangeEvent) => updateGlobalSurveyInstance({ ...surveyStyle, fontFamily: event.target.value as string })}
                        sx={{ borderRadius: '6px', backgroundColor: '#fff', padding: '0.25rem' }}
                    >
                        {fonts.map((font, i) => (
                            <MenuItem key={i} value={font}>
                                <Typography fontFamily={fonts[i] === 'Default' ? 'Helvetica' : fonts[i]}>{font}</Typography>
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
            </Box>

            {/* Foreground Contrast Block */}
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: '1rem',
                    padding: '1rem',
                    backgroundColor: '#f9f9f9',
                    borderRadius: '8px',
                    boxShadow: '0 2px 8px rgba(0,0,0,0.1)'
                }}
            >
                <FormControl fullWidth>
                    <Typography variant="subtitle2" sx={{ mb: 1, fontWeight: '500', color: '#555', fontSize: '1.2rem' }}>
                        Foreground Contrast
                    </Typography>
                    <Select
                        id="foreground-select"
                        value={surveyStyle.foregroundContrast}
                        size="small"
                        onChange={(event: SelectChangeEvent) => updateGlobalSurveyInstance({ ...surveyStyle, foregroundContrast: event.target.value as string })}
                        sx={{ borderRadius: '6px', backgroundColor: '#fff', padding: '0.25rem' }}
                    >
                        {fContrast.map((f, i) => (
                            <MenuItem key={i} value={f}>
                                <Typography>{f}</Typography>
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
            </Box>

            {/* Question Text Size Block */}
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: '1rem',
                    padding: '1rem',
                    backgroundColor: '#f9f9f9',
                    borderRadius: '8px',
                    boxShadow: '0 2px 8px rgba(0,0,0,0.1)'
                }}
            >
                <Typography variant="subtitle2" sx={{ mb: 1, fontWeight: '500', color: '#555', fontSize: '1.2rem' }}>
                    Question Text (px)
                </Typography>
                <Box display="flex" flexDirection="row" alignItems="center" gap="0.5rem">
                    <Button
                        variant="outlined"
                        onClick={() => {
                            setQuestionText(questionText + 1)
                            updateGlobalSurveyInstance({ ...surveyStyle, questionFontSize: questionText + 1 + 'px' })
                        }}
                        sx={{ minWidth: '36px', padding: '0.12rem', fontSize: '18px' }}
                    >
                        +
                    </Button>
                    <TextField
                        variant="outlined"
                        value={questionText}
                        size="small"
                        onChange={(e) => setQuestionText(parseInt(e.target.value, 10))}
                        sx={{ width: '60px', textAlign: 'center', borderRadius: '6px', fontWeight: 300, padding: '0.2rem', fontSize: '12px' }}
                        inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
                    />
                    <Button
                        variant="outlined"
                        onClick={() => {
                            if (questionText > 0) {
                                setQuestionText(questionText - 1)
                                updateGlobalSurveyInstance({ ...surveyStyle, questionFontSize: questionText - 1 + 'px' })
                            }
                        }}
                        sx={{ minWidth: '36px', padding: '0.12rem', fontSize: '18px' }}
                    >
                        -
                    </Button>
                    <Button
                        variant={surveyStyle.isQuestionBold ? 'contained' : 'outlined'}
                        sx={{ borderRadius: '6px' }}
                        onClick={() => updateGlobalSurveyInstance({ ...surveyStyle, isQuestionBold: !surveyStyle.isQuestionBold })}
                    >
                        B
                    </Button>
                </Box>
            </Box>

            {/* Answer Text Size Block */}
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: '1rem',
                    padding: '1rem',
                    backgroundColor: '#f9f9f9',
                    borderRadius: '8px',
                    boxShadow: '0 2px 8px rgba(0,0,0,0.1)'
                }}
            >
                <Typography variant="subtitle2" sx={{ mb: 1, fontWeight: '500', color: '#555', fontSize: '1.2rem' }}>
                    Answer Text (px)
                </Typography>
                <Box display="flex" flexDirection="row" alignItems="center" gap="0.5rem">
                    <Button
                        variant="outlined"
                        onClick={() => {
                            setAnswerText(answerText + 1)
                            updateGlobalSurveyInstance({ ...surveyStyle, answerFontSize: answerText + 1 + 'px' })
                        }}
                        sx={{ minWidth: '36px', padding: '0.12rem', fontSize: '18px' }}
                    >
                        +
                    </Button>
                    <TextField
                        variant="outlined"
                        value={answerText}
                        size="small"
                        onChange={(e) => setAnswerText(parseInt(e.target.value, 10))}
                        sx={{ width: '60px', textAlign: 'center', fontSize: '12px', borderRadius: '6px' }}
                        inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
                    />
                    <Button
                        variant="outlined"
                        onClick={() => {
                            if (answerText > 0) {
                                setAnswerText(answerText - 1)
                                updateGlobalSurveyInstance({ ...surveyStyle, answerFontSize: answerText - 1 + 'px' })
                            }
                        }}
                        sx={{ minWidth: '36px', padding: '0.12rem', fontSize: '18px' }}
                    >
                        -
                    </Button>
                    <Button
                        variant={surveyStyle.isAnswerBold ? 'contained' : 'outlined'}
                        sx={{ borderRadius: '6px' }}
                        onClick={() => updateGlobalSurveyInstance({ ...surveyStyle, isAnswerBold: !surveyStyle.isAnswerBold })}
                    >
                        B
                    </Button>
                </Box>
            </Box>
        </Stack>
    )
}
