import { Box, Button, CircularProgress, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, Typography } from '@mui/material'
import React, { FC, useEffect, useState } from 'react'

import { SurveyTableDataRow } from '@feeba/types'
import { getSurveyTableData, tableSaveAsCSV } from '../../RestClient'

interface Props {
    surveyId: string
}

const lang = 'en'

export const SurveyTable: FC<Props> = ({ surveyId }) => {
    const [data, setData] = useState<undefined | null | Array<SurveyTableDataRow>>(undefined)
    const [pageSize, setPageSize] = useState(5)
    const [page, setPage] = useState(0)
    const [totalCount, setTotalCount] = useState(page)

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
        setPageSize(parseInt(event.target.value, 10))
        setPage(0)
    }

    const handleChangePage = (event: unknown, newPage: number) => {
        setPage(newPage)
    }

    useEffect(() => {
        console.log('SurveyTable for surveyId: ', surveyId)

        getSurveyTableData(surveyId, lang, page, pageSize)
            .then((e) => {
                setData(e)
                setTotalCount(e[0]?.TotalCount)
            })
            .catch((err) => {
                console.error(err)
                setData(null)
            })
    }, [pageSize, page])

    console.log('Page --> ', page)

    console.log('SurveyTable data: ', data)

    const downloadCsv = async (surveyId: any, lang: any) => {
        try {
            const result = await tableSaveAsCSV(surveyId, lang)
            const blob = new Blob([result], { type: 'text/csv;charset=utf-8;' })
            const url = URL.createObjectURL(blob)

            const link = document.createElement('a')
            link.href = url
            link.setAttribute('download', `survey_${surveyId}_${lang}.csv`)
            document.body.appendChild(link)
            link.click()

            document.body.removeChild(link)
            URL.revokeObjectURL(url)
        } catch (err) {
            console.error(err)
        }
    }

    return (
        <Box sx={{ m: 2 }}>
            {data && (
                <>
                    {/* Implement the export button. The position of the button is on the left side of the screen. along with the label Updated */}
                    <Box sx={{ display: 'flex', justifyContent: 'left' }}>
                        <Button variant="contained" color="primary" onClick={() => downloadCsv(surveyId, lang)}>
                            Save as CSV
                        </Button>
                    </Box>

                    <TableContainer>
                        <Table stickyHeader aria-label="sticky table">
                            <TableHead>
                                <TableRow>
                                    <TableCell>Updated</TableCell>
                                    <TableCell>SessionID</TableCell>
                                    <TableCell>Email</TableCell>
                                    <TableCell>Phone</TableCell>
                                    <TableCell>UserID</TableCell>
                                    <TableCell>DriverID</TableCell>
                                    <TableCell>Ride ID</TableCell>

                                    {data &&
                                        data.length > 0 &&
                                        Object.keys(data[0]).map((key: string) => {
                                            // Exclude fixed properties and TotalCount
                                            if (!['Updated', 'SessionID', 'Email', 'Phone', 'UserID', 'DriverID', 'RideID', 'TotalCount'].includes(key)) {
                                                return <TableCell key={key}>{key}</TableCell>
                                            }
                                            return null // Exclude other fixed properties
                                        })}
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {data &&
                                    data.map((row: SurveyTableDataRow, index: number) => (
                                        <TableRow key={row.SessionID}>
                                            <TableCell>{row.Updated}</TableCell>
                                            <TableCell>{row.SessionID}</TableCell>
                                            <TableCell>{row.Email}</TableCell>
                                            <TableCell>{row.Phone}</TableCell>
                                            <TableCell>{row.UserID}</TableCell>
                                            <TableCell>{row.DriverID}</TableCell>
                                            <TableCell>{row.RideID}</TableCell>
                                            {/* Render answer for each question dynamically */}
                                            {Object.keys(row).map((key: string) => {
                                                // Exclude fixed properties and TotalCount
                                                if (!['Updated', 'SessionID', 'Email', 'Phone', 'UserID', 'DriverID', 'RideID', 'TotalCount'].includes(key)) {
                                                    return <TableCell key={key}>{row[key]}</TableCell>
                                                }
                                                return null // Exclude other fixed properties
                                            })}
                                        </TableRow>
                                    ))}
                            </TableBody>
                        </Table>
                    </TableContainer>

                    <TablePagination
                        rowsPerPageOptions={[5, 10, 30]}
                        component="div"
                        count={totalCount}
                        rowsPerPage={pageSize}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                </>
            )}
            {data === null && (
                <Box sx={{ display: 'flex', justifyContent: 'center', height: '100vh' }}>
                    <Typography sx={{ mt: 15 }} color="red" variant="h5">
                        Something went wrong. Error fetching data.
                    </Typography>
                </Box>
            )}
            {data === undefined && (
                <Box sx={{ mt: 15, display: 'flex', justifyContent: 'center', height: '100vh' }}>
                    <CircularProgress />
                    <Typography variant="h6">Loading...</Typography>
                </Box>
            )}
        </Box>
    )
}
