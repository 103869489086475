import { Box, Tab, Tabs } from '@mui/material'
import { FC, useState } from 'react'

const tabValues = [
    {
        label: 'Manually configured',
        element: () => <></>
    },
    {
        label: 'Programmatic',
        element: () => <></>
    }
]

export const Push: FC = () => {
    const [tabIndex, setTabIndex] = useState(0)

    return (
        <Box sx={{ m: 1 }}>
            <Tabs sx={{ backgroundColor: 'white' }} centered textColor="inherit" indicatorColor="primary" value={tabIndex} onChange={(event, newValue) => setTabIndex(newValue)}>
                {tabValues.map((tab, index) => (
                    <Tab key={index} label={tab.label} value={index} />
                ))}
            </Tabs>
            {tabValues.map((tab, index) => tab.element())}
        </Box>
    )
}
