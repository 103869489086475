import { SurveyModel } from '@feeba/types'
import { Dialog } from '@mui/material'
import { FC } from 'react'

export const UpsertSurveyDialog: FC<Props> = ({ surveyToEdit, onClose, open, onSurveyAdded }) => {
    // const [loading, setLoading] = useState(false)
    // const [surveyLangList, setSurveyLangList] = React.useState(languageList);
    // const [surveyLang, setSurveyLang] = React.useState(currentLang);
    // const [questionUiMap, setQuestionUiModelMap] = useState(new Map<string, Array<QuestionsUiModel>>())
    // const [questionList, setQuestionList] = useState<Array<QuestionsModel>>([])
    // const {showNotification} = usePopup()
    //
    // const morphedSurveyToEdit: Record<string, SurveyModel> | u@feeba/typess(
    //     Array.from(surveyToEdit.entries()).map(x => [x[0], x[1]])
    // )
    //
    // const initValue: Record<string, SurveyModel> = m@feeba/types=>
    //     [l, {name: '', description: '', items: [null]}]
    // ))
    //
    // useEffect(() => {
    //     fetchQuestionList(null, null)
    //         .then(value => {
    //             const langSet = new Set<string>()
    //             const questionsMap = new Map<string, Array<QuestionsUiModel>>()
    //
    //             value.map(e => {
    //                 e.items.forEach(q => {
    //                     if (!questionsMap.has(q.lang)) questionsMap.set(q.lang, [])
    //                     questionsMap.get(q.lang)?.push({
    //                         id: e.id,
    //                         questionItem: q.questionItem,
    //                         type: QuestionType.SingleChoice, // FIXME: This is a hack
    //                         created: e.created,
    //                         updated: e.updated,
    //                     })
    //
    //                     langSet.add(q.lang)
    //                 })
    //             })
    //             let langList = Array.from(langSet);
    //             setSurveyLangList(langList)
    //             if (!surveyLang || surveyLang === '') setSurveyLang(langList[0])
    //             setQuestionUiModelMap(questionsMap)
    //             setQuestionList(value)
    //         })
    //         .catch(e => showNotification({type: "error", text: (e as Error).message}))
    // }, [])
    //
    // const saveSurvey = (survey: Record<string, SurveyModel>) =>@feeba/types
    //     console.log('Save Question:', survey)
    //
    //     Object.keys(survey).forEach(lang => {
    //         const surveyUiModel = survey[lang]
    //         if (surveyUiModel.name.trim() === '') {
    //             showNotification({type: "warning", text: `${lang} has not filled Survey title.`})
    //             return
    //         }
    //         for (let e of surveyUiModel.items) {
    //             if (e && e.type === Type.Category) {
    //                 if (e.items.length === 0) {
    //                     showNotification({type: "warning", text: `Category must have at least 1 question}`})
    //                     return
    //                 }
    //             } else if (e && e.type === Type.Question) {
    //                 if (!e.question) {
    //                     showNotification({type: "warning", text: `Question item doesn't have question specified}`})
    //                     return
    //                 }
    //             }
    //         }
    //     })
    //     setLoading(true)
    //     upsertSurvey(languageList, survey, questionList)
    //         .then(data => {
    //             showNotification({text: "Survey created", type: "success"})
    //             onSurveyAdded()
    //         })
    //         .catch((err: Error) => {
    //             console.error(JSON.stringify(err, null, 2))
    //             showNotification({text: err.message, type: "error"})
    //         })
    //         .finally(() => setLoading(false))
    // }
    //
    // console.log('surveyLang', surveyLang)
    return (
        <Dialog
            onClose={(event, reason) => {
                if (reason && reason === 'backdropClick') return
                onClose()
            }}
            open={open}
            fullWidth
            maxWidth={'xl'}
        >
            {/*{*/}
            {/*    questionList.length === 0*/}
            {/*        ?*/}
            {/*        <Box display="flex" justifyContent="center" alignItems="center" minHeight="100vh">*/}
            {/*            <CircularProgress/>*/}
            {/*        </Box>*/}
            {/*        :*/}
            {/*        <Formik initialValues={initValue} validationSchema={formInputValidation}*/}
            {/*                onSubmit={(values, helper) => saveSurvey(values)}>*/}
            {/*            {(formikProps) => {*/}
            {/*                return (<Form noValidate autoComplete="off">*/}
            {/*                    <Stack direction='column'>*/}
            {/*                        <Stack justifyContent={"space-between"} direction='row' sx={{p: 2}}>*/}
            {/*                            <Typography*/}
            {/*                                variant={"h4"}>{surveyToEdit ? 'Edit the Survey' : 'Add a new Survey'}</Typography>*/}
            {/*                            <ToggleButtonGroup*/}
            {/*                                color="primary" value={surveyLang} exclusive aria-label="Platform"*/}
            {/*                                onChange={(event, updatedLang: string) => updatedLang && setSurveyLang(updatedLang)}*/}
            {/*                            >{surveyLangList.map(l => {*/}
            {/*                                const toggleButton = <ToggleButton*/}
            {/*                                    sx={formikProps.errors?.[l]?.name ? {color: 'error.main'} : {}}*/}
            {/*                                    key={l} value={l} aria-label={l}>{l}</ToggleButton>*/}

            {/*                                if (formikProps.errors?.[l]?.name) {*/}
            {/*                                    return <Tooltip open={true} title="Error" key={l}*/}
            {/*                                                    arrow>{toggleButton}</Tooltip>*/}
            {/*                                } else {*/}
            {/*                                    return toggleButton*/}
            {/*                                }*/}
            {/*                            })*/}
            {/*                            }</ToggleButtonGroup>*/}
            {/*                        </Stack>*/}
            {/*                        <Box sx={{flexGrow: 1, p: 2, maxWidth: '100%', overflow: 'auto'}}>*/}
            {/*                            <Stack direction={"column"}>*/}
            {/*                                <Stack direction='row'>*/}
            {/*                                    <TextField*/}
            {/*                                        sx={{width: '20%', mr: 1}}*/}
            {/*                                        id={`${surveyLang}.name`} label="Survey Title" variant="outlined"*/}
            {/*                                        error={formikProps.touched[surveyLang]?.name && Boolean(formikProps.errors[surveyLang]?.name)}*/}
            {/*                                        helperText={formikProps.touched[surveyLang]?.name && formikProps.errors[surveyLang]?.name}*/}
            {/*                                        onBlur={formikProps.handleBlur} onChange={formikProps.handleChange}*/}
            {/*                                        value={formikProps.values[surveyLang].name}*/}
            {/*                                    />*/}
            {/*                                    <TextField*/}
            {/*                                        sx={{width: '100%'}}*/}
            {/*                                        id={`${surveyLang}.description`} label="Optional Survey description"*/}
            {/*                                        variant="outlined" multiline*/}
            {/*                                        error={formikProps.touched[surveyLang]?.description && Boolean(formikProps.errors[surveyLang]?.description)}*/}
            {/*                                        helperText={formikProps.touched[surveyLang]?.description && formikProps.errors[surveyLang]?.description}*/}
            {/*                                        onBlur={formikProps.handleBlur} onChange={formikProps.handleChange}*/}
            {/*                                        value={formikProps.values[surveyLang].description}*/}
            {/*                                    />*/}
            {/*                                </Stack>*/}
            {/*                                {formikProps.values[surveyLang].items.map((e, index, items) => {*/}
            {/*                                    if (e === null) {*/}
            {/*                                        return <SurveyItemConnector*/}
            {/*                                            indexLocation={index} key={`connector#${index}`}*/}
            {/*                                            onQuestionAdd={() => {*/}
            {/*                                                Object.keys(formikProps.values).forEach(lang => formikProps.values[lang].items*/}
            {/*                                                    .splice(index + 1, 0, {*/}
            {/*                                                        question: null,*/}
            {/*                                                        type: Type.Question*/}
            {/*                                                    }, null)*/}
            {/*                                                )*/}
            {/*                                                formikProps.setValues({...formikProps.values})*/}
            {/*                                            }}*/}
            {/*                                            onCategoryAdd={() => {*/}
            {/*                                                Object.keys(formikProps.values).forEach(lang =>*/}
            {/*                                                    formikProps.values[lang].items*/}
            {/*                                                        .splice(index + 1, 0, {*/}
            {/*                                                            items: [],*/}
            {/*                                                            type: Type.Category,*/}
            {/*                                                            name: "",*/}
            {/*                                                            description: "",*/}
            {/*                                                        }, null)*/}
            {/*                                                )*/}
            {/*                                                formikProps.setValues({...formikProps.values})*/}
            {/*                                            }}/>*/}
            {/*                                    } else {*/}
            {/*                                        return <CategoryBlock*/}
            {/*                                            questions={questionUiMap.get(surveyLang)!}*/}
            {/*                                            key={`surveyItem#${index}`}*/}
            {/*                                            categoryWrapper={e.type === Type.Category ? e : null}*/}
            {/*                                            questionWrapper={e.type === Type.Question ? e : null}*/}
            {/*                                            onDelete={() => {*/}
            {/*                                                console.log(`Deleting #${index} from ${items.map(e => e?.type)}`)*/}
            {/*                                                Object.keys(formikProps.values).forEach(lang => formikProps.values[lang].items.splice(index, 2))*/}
            {/*                                                console.log(`Deleted ${items.map(e => e?.type)}`)*/}
            {/*                                                formikProps.setValues({...formikProps.values})*/}
            {/*                                            }}*/}
            {/*                                            onQuestionWrapperUpdate={questionWrapper => {*/}
            {/*                                                Object.keys(formikProps.values).forEach(lang => (formikProps.values[lang].items[index] as QuestionUiModelWrapper).question = questionUiMap.get(lang)?.find(e => e.id === questionWrapper.question?.id)!)*/}
            {/*                                                formikProps.values[surveyLang].items[index] = questionWrapper*/}
            {/*                                                formikProps.setValues({...formikProps.values})*/}
            {/*                                            }}*/}
            {/*                                            onCategoryWrapperUpdate={category => {*/}
            {/*                                                Object.keys(formikProps.values).forEach(lang => {*/}
            {/*                                                    (formikProps.values[lang].items[index] as CategoryWrapperUI).items = category.items.map(noneTranslatedQuestion => questionUiMap.get(lang)?.find(e => e.id === noneTranslatedQuestion.id)!)*/}
            {/*                                                })*/}
            {/*                                                formikProps.values[surveyLang].items[index] = category*/}
            {/*                                                formikProps.setValues({...formikProps.values})*/}
            {/*                                                console.log(`onCategoryWrapperUpdate#DONE`, items)*/}
            {/*                                            }}*/}
            {/*                                        />*/}
            {/*                                    }*/}
            {/*                                })}*/}
            {/*                            </Stack>*/}
            {/*                        </Box>*/}
            {/*                        /!*<Stack direction={'row'}>*!/*/}
            {/*                        /!*<pre style={{textAlign: "left"}}>*!/*/}
            {/*                        /!*    <strong>Items</strong>*!/*/}
            {/*                        /!*    <br/>*!/*/}
            {/*                        /!*    {JSON.stringify(formikProps.values, null, 2)}*!/*/}
            {/*                        /!*</pre>*!/*/}
            {/*                        /!*<pre style={{textAlign: "left"}}>*!/*/}
            {/*                        /!*    <strong>Curr Lang</strong>*!/*/}
            {/*                        /!*    <br/>*!/*/}
            {/*                        /!*    {surveyLang}*!/*/}
            {/*                        /!*</pre>*!/*/}
            {/*                        /!*    <pre style={{textAlign: "left"}}>*!/*/}
            {/*                        /!*    <strong>Touched</strong>*!/*/}
            {/*                        /!*    <br/>*!/*/}
            {/*                        /!*        {JSON.stringify(formikProps.touched, null, 2)}*!/*/}
            {/*                        /!*</pre>*!/*/}

            {/*                        /!*<pre style={{textAlign: "left"}}>*!/*/}
            {/*                        /!*<strong>Errors</strong>*!/*/}
            {/*                        /!*<br/>*!/*/}
            {/*                        /!*    {JSON.stringify(formikProps.errors, null, 2)}*!/*/}
            {/*                        /!*</pre>*!/*/}
            {/*                        /!*</Stack>*!/*/}
            {/*                        <Stack direction={'row'}>*/}
            {/*                            <Button*/}
            {/*                                variant="contained" color={"error"}*/}
            {/*                                sx={{m: 1, position: 'relative', flexGrow: 1, width: '100%'}}*/}
            {/*                                onClick={() => onClose()}*/}
            {/*                                disabled={loading}>Cancel</Button>*/}
            {/*                            <Box sx={{m: 1, position: 'relative', flexGrow: 1, width: '100%'}}>*/}
            {/*                                <Button*/}
            {/*                                    type={"submit"} variant="contained" sx={{flexGrow: 1, width: '100%'}}*/}
            {/*                                    disabled={loading}*/}
            {/*                                >Save</Button>*/}
            {/*                                {loading && (*/}
            {/*                                    <CircularProgress*/}
            {/*                                        size={24}*/}
            {/*                                        sx={{*/}
            {/*                                            color: green[500], position: 'absolute', top: '50%',*/}
            {/*                                            left: '50%', marginTop: '-12px', marginLeft: '-12px',*/}
            {/*                                        }}*/}
            {/*                                    />*/}
            {/*                                )}*/}
            {/*                            </Box>*/}
            {/*                        </Stack>*/}
            {/*                    </Stack>*/}
            {/*                </Form>);*/}
            {/*            }}*/}
            {/*        </Formik>*/}
            {/*}*/}
        </Dialog>
    )
}

export interface Props {
    surveyToEdit?: Map<string, SurveyModel>
    open: boolean
    onClose: () => void
    onSurveyAdded: () => void
}

// const formInputValidation = Yup.lazy((obj) =>
//     Yup.object().shape({
//         // iterate over the object keys and validate each value
//         ...Object.keys(obj).reduce((acc, key) => {
//             // @ts-ignore
//             acc[key] = Yup.object({
//                 name: Yup.string().min(1, 'Min 1 Symbol').max(2000, 'Maximum 2000 symbols').required("Survey title is required property"),
//                 description: Yup.string().min(1, 'Min 1 Symbol').max(2000, 'Maximum 2000 symbols'),
//                 items: Yup.array().of(Yup.object().shape({
//                         // type: Yup.mixed<Type>().oneOf(Object.values(Type)),
//                         name: Yup.string().min(1, 'Min 1 Symbol').max(2000, 'Maximum 2000 symbols'),
//                         description: Yup.string().min(1, 'Min 1 Symbol').max(2000, 'Maximum 2000 symbols'),
//                         question: Yup.object().when('type', {
//                             is: (type: Type) => type === Type.Question,
//                             then: (schema) => Yup.object({
//                                 questionItem: Yup.object().required('You must have question selected')
//                             }),
//                             // otherwise: Yup.object().nullable(true)
//                         }),
//                         items: Yup.array().when('type', {
//                             is: (type: Type) => type === Type.Category,
//                             then: (schema) => schema.min(1, 'You must select at least one item').required('Array can not be empty'),
//                             // otherwise: Yup.object().nullable(true)
//                         }),
//                     }).nullable(true)
//                 ).required()
//             }) // add validation rule for each key
//             return acc;
//         }, {})
//     })
// );
