import { DefaultTriggerDistributionConfig, TriggerDistributionConfig, TriggerEnum } from '@feeba/types'
import axios from 'axios'

// Triggers
export const upsertTriggers = (surveyId: string, config: TriggerDistributionConfig): Promise<void> => {
    console.log(`upsert triggers :: ${JSON.stringify(config, null, 2)}`)
    return axios
        .put<void>(`/survey/dashboard/survey/${surveyId}/distribution/trigger`, config)
        .then((data) => {
            console.log(`upsert triggers :: ${JSON.stringify(data.data, null, 2)}`)
            return
        })
        .catch((err) => {
            console.error(`upsert triggers :: ${JSON.stringify(err, null, 2)}`)
            throw err
        })
}

export const fetchTriggersConfig = (surveyId: string): Promise<TriggerDistributionConfig> => {
    console.log(`fetching trigger config :: ${surveyId}`)
    return axios
        .get<TriggerDistributionConfig>(`/survey/dashboard/survey/${surveyId}/distribution/trigger`)
        .then((data) => {
            console.log(`fetchTriggersConfig :: ${JSON.stringify(data.data, null, 2)}`)
            // Map data.data to TriggerDistributionConfig
            try {
                const result: TriggerDistributionConfig = {
                    triggers: {
                        type: data.data.triggers.type as TriggerEnum,
                        appOpen: {},
                        screen: {
                            rules: data.data.triggers.screen.rules.map((ruleSet) => ({
                                triggers: ruleSet.triggers.map((r) => {
                                    return {
                                        type: r.type,
                                        conditional: r.conditional,
                                        eventName: r.eventName,
                                        value: r.value
                                    }
                                }),
                                startWithKnob: ruleSet.startWithKnob
                            }))
                        },
                        conditional: {
                            rules: data.data.triggers.conditional.rules.map((ruleSet) => {
                                return {
                                    triggers: ruleSet.triggers.map((r) => {
                                        return {
                                            type: r.type,
                                            conditional: r.conditional,
                                            eventName: r.eventName,
                                            value: r.value
                                        }
                                    }),
                                    startWithKnob: ruleSet.startWithKnob
                                }
                            })
                        }
                    },
                    schedule: {
                        showConfig: {
                            time: data.data.schedule.showConfig.time && new Date(data.data.schedule.showConfig.time)
                        },
                        stopConfig: {
                            selection: data.data.schedule.stopConfig.selection,
                            time: data.data.schedule.stopConfig.time && new Date(data.data.schedule.stopConfig.time)
                        },
                        repeat: {
                            selection: data.data.schedule.repeat.selection
                        }
                    }
                }
                return result
            } catch {
                // Parsing error should fall back gracefully to default
                return DefaultTriggerDistributionConfig
            }
        })
        .catch((err) => {
            console.error(`fetchTriggersConfig :: ${JSON.stringify(err, null, 2)}`)
            throw err
        })
}
