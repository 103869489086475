import React, { createContext, useState } from 'react'
import { AlertColor } from '@mui/material/Alert/Alert'

const ALERT_TIME = 5000

interface Alert {
    text: string
    type: AlertColor
}

const PopupContext = createContext<{ alert: null | Alert; showNotification: (alert: Alert | null) => void }>({
    alert: { type: 'error', text: '' },
    showNotification: (alert: Alert | null) => {}
})

export const PopupProvider: React.FC<Props> = ({ children }) => {
    const [alert, setAlert] = useState<Alert | null>(null)

    const showNotification = (alert: Alert | null) => {
        setAlert(alert)
        setTimeout(() => setAlert(null), ALERT_TIME)
    }

    return <PopupContext.Provider value={{ alert, showNotification }}>{children}</PopupContext.Provider>
}

interface Props {
    children: React.ReactNode
}
export default PopupContext
