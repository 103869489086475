import { Box, Stack, TextField } from '@mui/material'
import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'
import React, { useEffect, useRef, useState } from 'react'
import { SketchPicker } from 'react-color'

interface ColorSelectionButtonProps {
    color: string
    defaultColor: string
    onColorChange: (color: string) => void
}

const ColorSelectionButton: React.FC<ColorSelectionButtonProps> = ({ color, defaultColor, onColorChange }) => {
    const [showPicker, setShowPicker] = useState(false)
    const pickerRef = useRef<HTMLDivElement | null>(null)

    if (!defaultColor || defaultColor === '') {
        defaultColor = '#000000'
    }
    const [currentColor, setCurrentColor] = useState(defaultColor)

    const handleColorChange = (color: any) => {
        const newColor = color.hex
        setCurrentColor(newColor)
        onColorChange(newColor) // Call the callback with the new color
    }

    useEffect(() => {
        if (!showPicker) return
        const handleClickOutside = (event: MouseEvent) => {
            if (pickerRef.current && !pickerRef.current.contains(event.target as Node)) {
                setShowPicker(false)
            }
        }

        window.addEventListener('click', handleClickOutside)
        return () => {
            window.removeEventListener('click', handleClickOutside)
        }
    }, [showPicker])

    return (
        <Stack direction={'column'} sx={{ width: '100%', gap: '1rem' }}>
            <Typography align="left" sx={{ fontWeight: '500', color: '#555' }}>
                {color === 'primary' ? 'Primary Color' : 'Secondary Color'}
            </Typography>

            <Box display="flex" flexDirection="row" alignItems="center" gap="1rem">
                <Button
                    variant="outlined"
                    sx={{
                        backgroundColor: currentColor,
                        height: '45px',
                        width: '45px',
                        minWidth: '45px',
                        padding: 0,
                        borderRadius: '4px',
                        '&:hover': {
                            opacity: 0.8
                        }
                    }}
                    onClick={(e) => {
                        e.stopPropagation()
                        setShowPicker(!showPicker)
                    }}
                />
                <TextField
                    value={currentColor}
                    sx={{
                        width: '120px',
                        '& .MuiInputBase-input': {
                            padding: '10px',
                            textAlign: 'center',
                            fontSize: '16px'
                        },
                        '& fieldset': {
                            borderRadius: '4px'
                        }
                    }}
                    InputProps={{
                        readOnly: true
                    }}
                />
            </Box>

            {showPicker && (
                <Box ref={pickerRef} sx={{ position: 'absolute', zIndex: 2 }}>
                    <SketchPicker color={currentColor} onChange={handleColorChange} />
                </Box>
            )}
        </Stack>
    )
}

export default ColorSelectionButton
