import { SurveyModel } from '@feeba/types'
import { Box, Link } from '@mui/material'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import * as React from 'react'
import { Resizable } from 'react-resizable'
import 'react-resizable/css/styles.css'
import { PaperComponent } from '../../../../../components/DraggableDialog'
import { IntegrationMode, SurveyRenderer, SurveyRendererProps, uuidv4 } from '@feeba/web-sdk'

export const PreviewDialog: React.FC<Props> = ({ surveyModel, isOpen, onClose }) => {
    const [height, setHeight] = React.useState(700)
    const [width, setWidth] = React.useState(500)
    const [data, setData] = React.useState<SurveyRendererProps | null>(null)

    const surveyUrl = surveyModel.distribution?.anonymousLink.url

    React.useEffect(() => {
        const sessionId = uuidv4()
        const projectName = surveyModel.name
        const surveyId = surveyModel.id
        const lang = surveyModel.langCode
        const enforcedBreakpoint = 'xs'

        setData({
            surveyPresentation: {
                langs: [lang],
                defaultLang: lang,
                survey: [surveyModel]
            },
            surveyModel,
            projectName,
            surveyId,
            lang,
            sessionId,
            uiConfig: { enforcedBreakpoint, integrationMode: IntegrationMode.Inline }
        })
    })

    return (
        <Dialog PaperComponent={PaperComponent} aria-labelledby="draggable-dialog-title" sx={{ width, height }} fullWidth maxWidth={false} open={isOpen} onClose={onClose}>
            <Resizable
                height={height}
                width={width}
                onResize={(event, data) => {
                    setHeight(data.size.height)
                    setWidth(data.size.width)
                }}
            >
                <>
                    <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">
                        Survey Preview
                    </DialogTitle>
                    <DialogContent>
                        <Box id={'survey-renderer-container'} display="flex" justifyContent="center" sx={{ bgcolor: 'transparent', alignItems: 'center' }}>
                            {data && <SurveyRenderer {...data} />}
                        </Box>
                    </DialogContent>
                    <DialogActions>
                        <Link href={surveyUrl} underline="hover" target="_blank" rel="noopener noreferrer">
                            Open in a new Tab
                        </Link>
                        <Button onClick={onClose}>Close</Button>
                    </DialogActions>
                </>
            </Resizable>
        </Dialog>
    )
}

export interface Props {
    surveyModel: SurveyModel
    isOpen: boolean
    onClose: () => void
}
