import React, { FC } from 'react'
import { Tooltip, Button, TooltipProps, BoxProps, Typography, Box } from '@mui/material'
import { tooltipClasses } from '@mui/material/Tooltip'
import { styled } from '@mui/material/styles'
import { Feeba } from '@feeba/web-sdk'

interface RichToltipsProps {
    origin: string
    children: React.ReactElement<any, any>
    placement?: 'bottom-end' | 'bottom-start' | 'bottom' | 'left-end' | 'left-start' | 'left' | 'right-end' | 'right-start' | 'right' | 'top-end' | 'top-start' | 'top'
}

export const UnlockFeatureTooltip: FC<RichToltipsProps> = ({ children, origin, ...props }) => {
    // Add your custom logic here

    return (
        <Tooltip
            arrow
            {...props}
            title={
                <React.Fragment>
                    <Typography variant="h6">Do you want this feature?</Typography>
                    <Typography>This feature is not ready yet. It is on our roadmap. If you want to see this feature, please request it. We appreciate your feedback.</Typography>
                    <Button onClick={() => Feeba.triggerEvent('feature_request', origin)} variant="contained" color="primary" size="small">
                        Request the feature
                    </Button>
                </React.Fragment>
            }
        >
            {children}
        </Tooltip>
    )
}

interface UpgradeTooltipProps {
    origin: string
    children: React.ReactElement<any, any>
    placement?: 'bottom-end' | 'bottom-start' | 'bottom' | 'left-end' | 'left-start' | 'left' | 'right-end' | 'right-start' | 'right' | 'top-end' | 'top-start' | 'top'
}

export const UpgradeTooltip: FC<RichToltipsProps> = ({ children, ...props }) => {
    // Add your custom logic here

    return (
        <Tooltip
            arrow
            {...props}
            title={
                <Box>
                    <Typography variant="h6">Upgrade your plan</Typography>
                    <Typography>This feature is not available in your current plan. Please upgrade your plan to access this feature.</Typography>
                    <Button sx={{ bgcolor: 'green' }} onClick={() => alert('TODO here')} variant="contained" color="primary" size="small">
                        Upgrade
                    </Button>
                </Box>
            }
        >
            {children}
        </Tooltip>
    )
}

// export const RichToltips = styled(({ className, ...props }: RichToltipsProps) => {
//     return (
//         <Tooltip
//             {...props}
//             title={
//                 <React.Fragment>
//                     <div>{props.text}</div>
//                     <Button variant="contained" color="primary" size="small">
//                         Click me
//                     </Button>
//                 </React.Fragment>
//             }
//             text
//             classes={{ popper: className }}
//         />
//     )
// })(({ theme }) => ({
//     [`& .${tooltipClasses.tooltip}`]: {

//     }
// }))
