import SearchIcon from '@mui/icons-material/Search'
import { Stack } from '@mui/material'
import IconButton from '@mui/material/IconButton'
import TextField from '@mui/material/TextField'
import { FC, useState } from 'react'

export const SearchBar: FC = ({}) => {
    const [searchQuery, setSearchQuery] = useState<string>('')
    const [isSearching, setIsSearching] = useState<boolean>(false)

    return (
        <Stack direction={'row'}>
            {isSearching && (
                <TextField
                    id="search-bar"
                    className="text"
                    variant="outlined"
                    placeholder="Search..."
                    size="small"
                    sx={{ width: '12rem' }}
                    value={searchQuery}
                    onChange={(e) => setSearchQuery(e.target.value)}
                />
            )}
            <IconButton aria-label="search" onClick={() => setIsSearching(!isSearching)}>
                <SearchIcon />
            </IconButton>
        </Stack>
    )
}
