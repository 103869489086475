import { RuleSetWithKnob } from '@feeba/types'
import { Box, Checkbox, Divider, FormControlLabel, Stack, TextField, Typography } from '@mui/material'
import { FC, useState } from 'react'
import { toAbsoluteUrl } from '../../../../../../_metronic/helpers'

const defaultData: RuleSetWithKnob = {
    text: 'Feedback'
}
export const StartWithKnobBlock: FC<Props> = ({ initValue, onUpdate }) => {
    const [data, setData] = useState(initValue)

    const handleChangeValue = (value: string) => {
        const newData = { ...data, text: value }
        setData(newData)
        onUpdate(newData)
    }

    return (
        <Stack direction="row" justifyContent="space-between" spacing={1} alignContent={'center'}>
            <Stack direction="column" justifyContent="space-between" spacing={1} alignContent={'center'}>
                <FormControlLabel
                    label="Start the survey with a side button instead of immediate Survey Popup"
                    control={
                        <Checkbox
                            checked={data != null}
                            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                // creata a new object
                                let updatedData = null
                                if (event.target.checked) updatedData = defaultData
                                else updatedData = null
                                setData(updatedData)
                                onUpdate(updatedData)
                            }}
                            inputProps={{ 'aria-label': 'controlled' }}
                        />
                    }
                />
                {data && (
                    <TextField
                        InputProps={{ inputProps: { min: 0, maxLength: 50 } }}
                        multiline={false}
                        label={'The text that is shown on the side button'}
                        variant="outlined"
                        sx={{ minWidth: 200, bgcolor: 'white' }}
                        size="small"
                        value={data.text}
                        onChange={(event) => handleChangeValue(event.target.value)}
                    />
                )}
            </Stack>
            <Divider orientation="vertical" flexItem sx={{ bgcolor: 'green' }} />
            <Stack direction="row">
                <Box sx={{ marginRight: 3, marginLeft: 1 }}>
                    <Typography flexWrap="wrap">
                        If the flag is checked, the side button/knob will appear first. Actual Survey won't be rendered unless user actually clicks the side button/knob. Side
                        button will disappear once user clicks it first time.
                    </Typography>
                </Box>
                <Box
                    sx={{
                        width: 850
                    }}
                >
                    <img
                        style={{
                            width: '100%',
                            height: '100%',
                            objectFit: 'contain',
                            borderRadius: '10px'
                        }}
                        src={toAbsoluteUrl('/media/preview_jpg.jpg')}
                        alt="Example"
                    />
                </Box>
            </Stack>
        </Stack>
    )
}

export interface Props {
    initValue: RuleSetWithKnob | null
    onUpdate: (data: RuleSetWithKnob | null) => void
}
