export const AUTH_TOKEN = 'accessToken'
export const API_URL = `${process.env.REACT_APP_API_URL || 'http://localhost:8080'}/v1`

export default function setupAxios(axios: any, on401: () => void) {
    console.log(`setupAxios::API_URL :::: ${API_URL}`)
    axios.defaults.baseURL = API_URL
    axios.defaults.headers.Accept = 'application/json'
    axios.interceptors.request.use(
        (config: any) => {
            const accessToken = localStorage.getItem(AUTH_TOKEN)
            if (accessToken) {
                config.headers.Authorization = `Bearer ${accessToken}`
            }

            return config
        },
        (err: any) => Promise.reject(err)
    )
    axios.interceptors.response.use(
        (res: any) => res,
        (err: any) => {
            const status = err.status || err.response.status
            if (status === 401) {
                localStorage.removeItem(AUTH_TOKEN)
                on401()
            }
            return Promise.reject(err)
        }
    )
}
