import { Feeba } from '@feeba/web-sdk'
import SettingsBackupRestoreIcon from '@mui/icons-material/SettingsBackupRestoreOutlined'
import { LoadingButton } from '@mui/lab'
import { Stack } from '@mui/material'
import Avatar from '@mui/material/Avatar'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Container from '@mui/material/Container'
import TextField from '@mui/material/TextField'
import Typography from '@mui/material/Typography'
import { Form, Formik } from 'formik'
import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import * as Yup from 'yup'
import usePopup from '../../../popups/notifications/hook'
import { requestPassword } from '../redux/AuthCRUD'
import { Copyright } from './Login'

const initialValues = {
    email: ''
}

const forgotPasswordSchema = Yup.object().shape({
    email: Yup.string().email('Wrong email format').min(3, 'Minimum 3 symbols').max(100, 'Maximum 100 symbols').required('Email is required')
})

export function ForgotPassword() {
    const resetPassUrl = `${window.location.origin}/auth/reset-password`
    const navigate = useNavigate()
    const { showNotification } = usePopup()

    const onSubmit = (values: any, { setStatus, setSubmitting }: any) => {
        requestPassword(values.email, resetPassUrl)
            .then(({ data: { result } }) => {
                showNotification({ text: 'Reset link is sent. Check your email.', type: 'success' })
                setStatus({
                    message: {
                        success: 'Reset link is sent. Check your email.'
                    }
                })
            })
            .catch((err) => {
                showNotification({ text: err.message, type: 'error' })
                setStatus({
                    message: {
                        error: `Error: ${err.message}`
                    }
                })
            })
            .finally(() => {
                setSubmitting(false)
            })
    }

    useEffect(() => {
        // Linked to: https://dashboard.feeba.io/survey/6689fc3b56ad8437d91f2aff
        Feeba.pageOpened('forgot_password')
        return () => {
            Feeba.pageClosed('forgot_password')
        }
    }, [])

    return (
        <Container component="main" maxWidth="xs">
            <Formik initialValues={initialValues} validationSchema={forgotPasswordSchema} onSubmit={onSubmit}>
                {({ values, touched, errors, handleChange, handleBlur, isValid, status, isSubmitting }) => (
                    <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                        {status && (
                            <Typography
                                sx={{
                                    p: 2,
                                    height: '4rem',
                                    width: '100%',
                                    border: `2px solid ${status.message.success ? '#4CAF50' : '#F44336'}`,
                                    backgroundColor: status.message.success ? '#E8F5E9' : '#FFEBEE',
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center'
                                }}
                            >
                                {status.message.success || status.message.error}
                            </Typography>
                        )}
                        <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
                            <SettingsBackupRestoreIcon />
                        </Avatar>
                        <Typography variant="h5" fontSize={18} sx={{ fontWeight: 'bold' }}>
                            Forgot Password ?
                        </Typography>
                        <Typography fontSize={18} sx={{ fontWeight: 'bold', color: 'lightgray' }}>
                            Enter your email to reset your password.
                        </Typography>
                        <Box sx={{ width: '100%' }}>
                            <Form>
                                <Box sx={{ mt: 3 }} width={'100%'}>
                                    <TextField
                                        margin="normal"
                                        fullWidth
                                        id="email"
                                        label="Email Address"
                                        name="email"
                                        autoComplete="email"
                                        autoFocus
                                        error={touched.email && Boolean(errors.email)}
                                        helperText={touched.email && errors.email}
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        value={values.email}
                                    />
                                    <Stack direction="row" spacing={2} width={'100%'} sx={{ mt: 3, mb: 2 }}>
                                        <LoadingButton loading={isSubmitting} type="submit" fullWidth variant="contained" loadingPosition="start" disabled={!isValid}>
                                            Submit
                                        </LoadingButton>
                                        <Button fullWidth variant="outlined" onClick={() => navigate('/auth/login')}>
                                            Cancel
                                        </Button>
                                    </Stack>
                                </Box>
                            </Form>
                        </Box>
                        <Copyright sx={{ mt: 8, mb: 4 }} />
                    </Box>
                )}
            </Formik>
        </Container>
    )
}
