import { SurveyModel } from '@feeba/types'
import { Add, OpenInFullOutlined } from '@mui/icons-material'
import { Box, CircularProgress, Divider, List, ListItemAvatar, ListItemButton, ListItemIcon, ListItemText, Stack, Tooltip, Typography } from '@mui/material'
import { DateTime } from 'luxon'
import { FC, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import usePopup from '../../../popups/notifications/hook'
import { SurveyListModel } from '../Models'
import { fetchSurveyList } from '../RestClient'
import { UpsertSurveyDialog } from './UpsertSurveyDialog'
import { SurveySummaryPage } from './summary/SurveySummaryPage'
import { Templates } from './template/Templates'

const SurveyBank: FC<Props> = ({}) => {
    const [isAddSurveyDialogOpened, setAddSurveyDialogOpened] = useState(false)
    const [surveyToEdit, setSurveyToEdit] = useState<Map<string, SurveyModel> | null>(null)
    const [data, setData] = useState<Array<SurveyListModel> | null>(null) // Nul denotes that data fetching errored
    // It is safe to set a default to 0. In worst case once the page rendered the first element is selected
    const [selectedIndex, setSelectedIndex] = useState(0)
    const [isLoading, setIsLoading] = useState(true) // Added loading state
    const { showNotification } = usePopup()
    const navigate = useNavigate()

    const updateSurveyList = () => {
        console.log('Get Surveys')
        setIsLoading(true) // Start loading when fetching data
        fetchSurveyList()
            .then((result) => {
                setData(result)
                console.log('fetchSurveys', JSON.stringify(result, null, 2))
            })
            .catch((err) => {
                console.error(JSON.stringify(err, null, 2))
                setData(null)
                showNotification({ text: err.text, type: 'error' })
            })
            .finally(() => setIsLoading(false)) // Stop loading when data is fetched
    }

    useEffect(() => {
        updateSurveyList()
    }, [])

    const handleCreateSurvey = () => {
        console.log('Create survey')
        setAddSurveyDialogOpened(true)
    }

    console.log('selectedIndex', selectedIndex)
    return (
        <>
            {data && (
                <Box sx={{ '& > :not(style)': { m: 1 } }}>
                    <Templates open={isAddSurveyDialogOpened} onClose={() => setAddSurveyDialogOpened(false)} includeBlackTemplate={true} />

                    <Stack direction="row">
                        <Box sx={{ width: '100%', maxWidth: 340, height: '100vh', overflow: 'auto', bgcolor: 'background.paper' }}>
                            <List component="nav" aria-label="create survey">
                                <ListItemButton sx={{ bgcolor: 'primary.main' }} aria-label="add" onClick={(event) => handleCreateSurvey()}>
                                    <ListItemIcon>
                                        <Add />
                                    </ListItemIcon>
                                    <ListItemText primary=" Create Survey" />
                                </ListItemButton>
                            </List>
                            <Divider />
                            <List component="nav" aria-label="surveys">
                                {data.map((survey, index) => (
                                    <ListItemButton selected={selectedIndex === index} onClick={(event) => setSelectedIndex(index)}>
                                        <ListItemText
                                            primary={survey.name}
                                            secondary={
                                                <Stack direction="column">
                                                    <Stack direction="row">
                                                        <Typography sx={{ fontStyle: 'italic' }}>status: </Typography> <Typography>{survey.status} </Typography>{' '}
                                                    </Stack>
                                                    <Stack direction="row">
                                                        <Typography>{DateTime.fromJSDate(survey.updated).toFormat('MMMM dd, yyyy - HH:mm')} </Typography>{' '}
                                                    </Stack>
                                                </Stack>
                                            }
                                        />
                                        {index === selectedIndex && (
                                            <ListItemAvatar sx={{ justifyContent: 'flex-end' }} onClick={() => navigate({ pathname: `/survey/${survey.id}` })}>
                                                <Tooltip title="Open in full">
                                                    <OpenInFullOutlined sx={{ color: 'primary.main' }} />
                                                </Tooltip>
                                            </ListItemAvatar>
                                        )}
                                    </ListItemButton>
                                ))}
                            </List>
                        </Box>
                        <Box sx={{ flexGrow: 1, height: '100vh', overflow: 'auto', bgcolor: 'background.paper', ml: 2 }}>
                            {data[selectedIndex] && <SurveySummaryPage key={selectedIndex} surveyId={data[selectedIndex].id} />}
                        </Box>
                    </Stack>

                    {surveyToEdit && (
                        <UpsertSurveyDialog
                            surveyToEdit={surveyToEdit}
                            open={surveyToEdit !== null}
                            onClose={() => setSurveyToEdit(null)}
                            onSurveyAdded={() => {
                                setSurveyToEdit(null)
                                updateSurveyList()
                            }}
                        />
                    )}
                </Box>
            )}
            {isLoading && <CircularProgress />}
        </>
    )
}

export interface Props {}

export default SurveyBank
