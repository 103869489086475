import axios from 'axios'
import { AppConfig } from './SettingsModel'

const configRelativePath = '/dashboard/settings'

export const updateSettings = (settings: AppConfig) => {
    return axios.post(configRelativePath, settings)
}

export const fetchSettings = async (): Promise<AppConfig> =>
    axios
        .get<AppConfig>(configRelativePath)
        .then((data) => mapAppConfigResponse(data.data))
        .then((data) => {
            console.log(`Fetched config AFTER parsing-> ${JSON.stringify(data, null, 2)}`)
            return data
        })

export const generateToken = async (): Promise<AppConfig> =>
    axios
        .get<AppConfig>(`${configRelativePath}/generate-token`)
        .then((data) => mapAppConfigResponse(data.data))
        .then((data) => {
            console.log(`Fetched config AFTER parsing-> ${JSON.stringify(data, null, 2)}`)
            return data
        })

function mapAppConfigResponse(data: any): AppConfig {
    return {
        _id: data._id,
        name: data.name,
        rateExp: data.rateExp,
        ticketingConfig: data.ticketingConfig,
        chatConfig: data.chatConfig,
        tokens: data.tokens,
        created: data.created,
        updated: data.updated
    }
}
