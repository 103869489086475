import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, TextField } from '@mui/material'
import { FC, useState } from 'react'
import { useSelector } from 'react-redux'
import { ActionTypes } from '../state/actions'
import { IState, surveyDispatcher } from '../state/hooks'

export const NextButtonConfigDialog: FC<Props> = ({ onClose }) => {
    const state = useSelector((state: IState) => state)
    const dispatch = surveyDispatcher()

    const [nextButtonText, setNextButtonText] = useState(state.survey.nextButtonText || { value: '' })

    const [prevButtonText, setPrevButtonText] = useState(state.survey.prevButtonText || { value: '' })

    const dispatchNextPrevButtonText = () => {
        dispatch({
            type: ActionTypes.NEXT_PREV_BUTTON_UPDATE,
            payload: {
                nextButtonText: nextButtonText,
                prevButtonText: nextButtonText
            }
        })
    }
    return (
        <Dialog onClose={(event, reason) => onClose()} open={true} fullWidth>
            <DialogTitle>Next/Previous button text</DialogTitle>
            <DialogContent dividers>
                <Box sx={{ flexGrow: 1 }}>
                    <Grid item xs={12}>
                        <TextField
                            sx={{ width: '100%' }}
                            id="nextButtonText"
                            label="Next button text"
                            variant="outlined"
                            value={nextButtonText.value}
                            onChange={(e) => setNextButtonText({ value: e.target.value })}
                        />
                    </Grid>
                    <br />
                    <Grid item xs={12}>
                        <TextField
                            sx={{ width: '100%' }}
                            id="prevButtonText"
                            label="Prev button text"
                            variant="outlined"
                            value={prevButtonText.value}
                            onChange={(e) => setPrevButtonText({ value: e.target.value })}
                        />
                    </Grid>
                    <br />
                </Box>
            </DialogContent>

            <DialogActions>
                <Button
                    variant="outlined"
                    onClick={() => {
                        dispatchNextPrevButtonText()
                        onClose()
                    }}
                    style={{ color: 'white', backgroundColor: '#2f71f5' }}
                >
                    Save
                </Button>
            </DialogActions>
        </Dialog>
    )
}

export interface Props {
    onClose: () => void
}
