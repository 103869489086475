import { CategoryItem, CategoryModel, SurveyModel } from '@feeba/types'
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux'
import { Dispatch } from 'redux'
import { SelectedComponent } from '../../../Models'
import { SelectionAction } from './actions'

// Infer the `IState` and `AppDispatch` types from the store itself
export interface IState {
    survey: SurveyModel
    selection: {
        category?: CategoryModel
        categoryItem?: CategoryItem
        survey?: SurveyModel
    }
    draft: boolean
    selectedComponent?: SelectedComponent | null | undefined //the default on the system is the "main" block.
}

// Use throughout your app instead of plain `useDispatch` and `useSelector`
export const surveyDispatcher: () => Dispatch<SelectionAction> = useDispatch
export const useCurrentSelection: TypedUseSelectorHook<IState> = useSelector
