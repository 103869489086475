import { CategoryItem } from '@feeba/types'
import { RemoveQuestionItemAction } from './actions'

export const getUpdatedCategoryItems = (items: CategoryItem[], action: RemoveQuestionItemAction): CategoryItem[] => {
    // If the question is the only item on the list, return an empty array
    if (items.length === 1) return []

    const resultArray: CategoryItem[] = Object.assign([], items)
    if (action.payload.selectionIndex === 0) {
        // Remove first two elements from array and return the rest
        return resultArray.slice(2)
    } else if (action.payload.selectionIndex === items.length - 1) {
        // Remove last two elements from array and return the rest
        return resultArray.slice(0, items.length - 2)
    } else {
        // Remove the selected element and the one after it
        return [...resultArray.slice(0, action.payload.selectionIndex), ...resultArray.slice(action.payload.selectionIndex + 2)]
    }
}

//TODO: move the code to the survey initialization
export const defaultMessageEndOfSurvey = 'We thank you for your time spent taking this survey. Your response has been recorded.'

//change \n to br
export const stringConversion = (text: string): string => {
    return text.replace(/\n/g, ' \n ')
}
