import { RandomizationType } from '@feeba/types'
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, FormControlLabel, Radio, RadioGroup, Stack, TextField, Typography } from '@mui/material'
import { FC, useState } from 'react'

export const MultiChoiceRandomizationDialog: FC<Props> = ({ model, onClose, open, onOptionSelected }) => {
    const [selection, setSelection] = useState<RandomizationType>(model)

    return (
        <Dialog
            onClose={(event, reason) => {
                if (reason && reason === 'backdropClick') return
                onClose()
            }}
            open={open}
            fullWidth
        >
            <DialogTitle>Choice Randomization</DialogTitle>
            <DialogContent dividers>
                <FormControl>
                    <RadioGroup
                        value={selection.type}
                        onChange={(event) => {
                            setSelection({ ...selection, type: event.target.value as RandomizationType['type'] })
                        }}
                        name="radio-buttons-group"
                    >
                        <FormControlLabel value={'no'} control={<Radio size={'small'} />} label="No Randomization" />
                        <FormControlLabel value={'random_answers'} control={<Radio size={'small'} />} label="Display answers in a random order" />
                        <FormControlLabel
                            value={'only_answers'}
                            control={<Radio size={'small'} />}
                            label={
                                <Stack direction={'row'} alignItems={'center'}>
                                    <Typography>Display answers in a random order - </Typography>
                                    <TextField
                                        type={'number'}
                                        size={'small'}
                                        inputProps={{ min: 1, max: 9999 }}
                                        value={selection.randomizedSubsetSize}
                                        onChange={(event) => {
                                            setSelection({ ...selection, randomizedSubsetSize: parseInt(event.target.value) })
                                        }}
                                    />
                                </Stack>
                            }
                        />
                    </RadioGroup>
                </FormControl>
            </DialogContent>
            <DialogActions>
                <Button variant="outlined" onClick={() => onClose()}>
                    Close
                </Button>
                <Button
                    variant="outlined"
                    style={{ color: 'white', backgroundColor: '#2f71f5' }}
                    onClick={() => {
                        onOptionSelected(selection)
                    }}
                >
                    Save
                </Button>
            </DialogActions>
        </Dialog>
    )
}

export interface Props {
    model: RandomizationType
    open: boolean
    onClose: () => void
    onOptionSelected: (type: RandomizationType) => void
}
