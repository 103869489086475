import { useEffect } from 'react'
import { Outlet, Route, Routes } from 'react-router-dom'
import { ForgotPassword } from './components/ForgotPassword'
import { Login } from './components/Login'
import { Registration } from './components/Registration'
import { ResetPassword } from './components/ResetPassword'

const AuthLayout = () => {
    useEffect(() => {
        document.body.classList.add('bg-white')
        return () => {
            document.body.classList.remove('bg-white')
        }
    }, [])

    return (
        <div
            className="d-flex flex-column flex-column-fluid bgi-position-y-bottom position-x-center bgi-no-repeat bgi-size-contain bgi-attachment-fixed"
            style={
                {
                    // backgroundImage: `url(${toAbsoluteUrl('/media/logos/feeba-logo.png')})`,
                }
            }
        >
            {/* begin::Content */}
            <div className="d-flex flex-center flex-column flex-column-fluid p-10 pb-lg-20">
                {/* begin::Logo * /}
        {/* begin::Wrapper */}
                <div className="w-lg-500px bg-white rounded shadow-sm p-10 p-lg-15 mx-auto">
                    <Outlet />
                </div>
                {/* end::Wrapper */}
            </div>
            {/* end::Content */}
        </div>
    )
}

const AuthPage = () => (
    <Routes>
        <Route element={<AuthLayout />}>
            <Route path="login" element={<Login />} />
            <Route path="registration" element={<Registration />} />
            <Route path="reset-password/:id/:token" element={<ResetPassword />} />
            <Route path="forgot-password" element={<ForgotPassword />} />
            <Route index element={<Login />} />
        </Route>
    </Routes>
)

export { AuthPage }
