import { AccountCircle, Logout, Settings } from '@mui/icons-material'
import { Divider, ListItem, ListItemAvatar, ListItemText, Typography } from '@mui/material'
import Avatar from '@mui/material/Avatar'
import ListItemIcon from '@mui/material/ListItemIcon'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import * as React from 'react'
import { FC } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { AppConfig, DashboardBootstrapConfig } from '@feeba/types'
import * as auth from '../../../../app/modules/auth/redux/AuthRedux'

export const HeaderUserMenu: FC<Props> = ({ anchorEl, onItemClicked }) => {
    const appConfig = useSelector<auth.IAuthState>(({ auth }) => auth?.bootstrap?.config, shallowEqual) as AppConfig

    const dispatch = useDispatch()
    const logout = () => {
        dispatch(auth.actions.logout())
    }

    console.log('HeaderUserMenu::bootstrap -> ', appConfig)
    return (
        <Menu
            anchorEl={anchorEl}
            id="account-menu"
            open={true}
            onClose={onItemClicked}
            onClick={onItemClicked}
            PaperProps={{
                sx: {
                    minWidth: 200,
                    overflow: 'visible',
                    filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                    mt: 1.5,
                    '& .MuiAvatar-root': { width: 32, height: 32, ml: -0.5, mr: 1 },
                    '&:before': {
                        content: '""',
                        display: 'block',
                        position: 'absolute',
                        top: 0,
                        right: 14,
                        width: 10,
                        height: 10,
                        bgcolor: 'background.paper',
                        transform: 'translateY(-50%) rotate(45deg)',
                        zIndex: 0
                    }
                }
            }}
            transformOrigin={{ horizontal: 'right', vertical: 'top' }}
            anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
        >
            <MenuItem component={Link} to="/profile" onClick={onItemClicked}>
                <ListItem alignItems="flex-start">
                    <ListItemAvatar>
                        <Avatar alt={appConfig.name}>
                            <AccountCircle fontSize={'large'} />
                        </Avatar>
                    </ListItemAvatar>
                    <ListItemText
                        primary="My Profile"
                        secondary={
                            <React.Fragment>
                                <Typography sx={{ display: 'inline' }} component="span" variant="body2" color="text.primary">
                                    {appConfig.name}
                                </Typography>
                            </React.Fragment>
                        }
                    />
                </ListItem>
            </MenuItem>
            <Divider />
            <MenuItem onClick={onItemClicked} component={Link} to="/settings">
                <ListItemIcon>
                    <Settings fontSize="small" />
                </ListItemIcon>
                Settings
            </MenuItem>
            <MenuItem
                onClick={() => {
                    logout()
                    onItemClicked()
                }}
            >
                <ListItemIcon>
                    <Logout fontSize="small" />
                </ListItemIcon>
                Logout
            </MenuItem>
        </Menu>
    )
}

export interface Props {
    anchorEl: HTMLElement
    onItemClicked: () => void
}
