// AlertPopup.js
import { Alert, Slide, Snackbar } from '@mui/material'
import usePopup from './hook'
import React from 'react'

const NotificationPopup = () => {
    const { alert: popUpAlert } = usePopup()
    if (popUpAlert) {
        return (
            <Snackbar
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                message={popUpAlert.text}
                TransitionComponent={(props) => <Slide {...props} direction="down" />}
                open={true}
            >
                <Alert severity={popUpAlert.type} sx={{ width: '100%' }}>
                    {popUpAlert.text}
                </Alert>
            </Snackbar>
        )
    } else {
        return <></>
    }
}

export default NotificationPopup
