import { AnonymousLink } from '@feeba/types'
import { Lock } from '@mui/icons-material'
import { Box, Button, Stack, TextField, Typography } from '@mui/material'
import { FC } from 'react'
import usePopup from '../../../../../popups/notifications/hook'

export const AnonymousLinkComponent: FC<Props> = ({ data }) => {
    const { showNotification } = usePopup()

    return (
        <Box sx={{ m: 1 }}>
            <Stack
                direction={'column'}
                spacing={2}
                sx={{
                    bgcolor: 'white',
                    borderRadius: 1,
                    border: '1px solid #e0e0e0',
                    p: 2
                }}
            >
                <Typography variant={'h6'}>Get an anonymous survey link</Typography>
                <Typography variant={'body2'}>Paste this reusable link into emails or onto a website; it can’t be tracked, and can’t be used to identify respondents</Typography>
                <TextField label={'Anonymous link'} value={data.url} fullWidth />
                <Stack direction={'row'} spacing={1} alignItems={'center'}>
                    <Lock fontSize="small" sx={{ color: 'grey' }} />
                    <Typography variant={'body2'} sx={{ color: 'grey' }}>
                        Read only
                    </Typography>
                </Stack>
                <Box display="flex" justifyContent={'end'}>
                    <Button
                        variant={'contained'}
                        sx={{ width: '200px' }}
                        onClick={() => {
                            navigator.clipboard.writeText(data.url)
                            showNotification({ text: 'Link copied to clipboard', type: 'success' })
                        }}
                    >
                        Copy Survey link
                    </Button>
                </Box>
            </Stack>
        </Box>
    )
}

export interface Props {
    data: AnonymousLink
}
