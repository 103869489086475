import { DashboardBootstrapConfig } from '@feeba/types'
import { Feeba } from '@feeba/web-sdk'
import { Action } from '@reduxjs/toolkit'
import { persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'

export interface LoginAction extends Action {
    type: actionTypes.Login
    accessToken: string
}
export interface LogoutAction extends Action {
    type: actionTypes.Logout
}

export interface RegisterAction extends Action {
    type: actionTypes.Register
    accessToken: string
}

export interface BootstrapAction extends Action {
    type: actionTypes.Bootstrap
    bootstrap: DashboardBootstrapConfig
}
export type AuthAction = LoginAction | LogoutAction | RegisterAction | BootstrapAction

export enum actionTypes {
    Login,
    Logout,
    Register,
    Bootstrap
}

const initialAuthState: IAuthState = {
    auth: undefined
}

export interface IAuthState {
    auth?: {
        accessToken: string
        bootstrap?: DashboardBootstrapConfig
    }
}

export const reducer = persistReducer({ storage, key: 'feeba_auth' }, (state: IAuthState = initialAuthState, action: AuthAction) => {
    console.log('AuthReducer::action -> ', action)
    let newState: IAuthState = state
    switch (action.type) {
        case actionTypes.Login: {
            const accessToken = action.accessToken
            newState = { auth: { accessToken } }
            break
        }
        case actionTypes.Register: {
            const accessToken = action.accessToken
            newState = { auth: { accessToken } }
            break
        }
        case actionTypes.Logout: {
            newState = initialAuthState
            Feeba.User.logout()
            break
        }
        case actionTypes.Bootstrap: {
            if (!state.auth) {
                console.error('Invalid state: Access token is not set')
                newState = initialAuthState
            } else {
                newState = { auth: { ...state.auth, bootstrap: action.bootstrap } }
                const user = action.bootstrap.config.users[0]
                const {
                    name,
                    entitlement: { status }
                } = action.bootstrap.config
                Feeba.User.login(user.id, user.email)
                Feeba.User.setLanguage('en')
                Feeba.User.addTag({ role: user.role, projectName: name, status })
            }
            break
        }

        default:
            newState = state
    }
    // If Redux is restring state from local storage, we need to initialize Feeba
    if (action.type.toString() === 'persist/REHYDRATE') {
    }
    return newState
})

export const actions = {
    login: (accessToken: string) => ({ type: actionTypes.Login, accessToken }),
    register: (accessToken: string) => ({
        type: actionTypes.Register,
        accessToken
    }),
    logout: () => ({ type: actionTypes.Logout }),
    bootStrap: (bootstrap: DashboardBootstrapConfig) => ({ type: actionTypes.Bootstrap, bootstrap: bootstrap })
}
