// Backend models
import { Answer, ItemType } from '@feeba/types'

// ------------------------------ SURVEY Bank---------------------------------
export interface SurveyListModel {
    id: string
    name: string
    status: 'new' | 'active' | 'inactive'
    responses: number
    type: 'survey' | 'poll'
    owner: {
        name: string
    }
    created: Date
    updated: Date
}

// ------------------------------ SURVEY ---------------------------------
export interface SurveyLanguageUiModel {
    langCode: string
    label: string
    visibleToRespondents: boolean | null
    translationProgress: number | null
    isDefault: boolean | null
}

export interface QuestionsModel {
    id: string
    type: ItemType.Divider
    items: Array<{
        lang: string
        questionItem: QuestionLangItemModel
    }>
    created?: Date
    updated?: Date
}

export interface QuestionLangItemModel {
    question: string
    answers: Array<Answer>
}

export interface QuestionModel {
    text: string
}

export enum SelectedComponent {
    Main = 'main', //main block (category, etc... ItemType.Category)
    EndSurvey = 'end_survey' //end survey block
}
