import PasswordIcon from '@mui/icons-material/Password'
import { LoadingButton } from '@mui/lab'
import { Avatar, Box, Container } from '@mui/material'
import Grid from '@mui/material/Grid'
import TextField from '@mui/material/TextField'
import Typography from '@mui/material/Typography'
import { Form, Formik } from 'formik'
import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useParams } from 'react-router-dom'
import * as Yup from 'yup'
import { resetPassword } from '../redux/AuthCRUD'
import * as auth from '../redux/AuthRedux'
import usePopup from '../../../popups/notifications/hook'
import { Feeba } from '@feeba/web-sdk'

// https://stackoverflow.com/a/63187441/4835773
const resetScheme = Yup.object().shape({
    password: Yup.string().min(3, 'Minimum 3 symbols').max(50, 'Maximum 50 symbols').required('Password is required'),
    passwordConfirmation: Yup.string()
        .required('Password confirmation is required')
        .oneOf([Yup.ref('password')], 'Passwords must match')
})

const initialValues = {
    password: '',
    passwordConfirmation: ''
}

export function ResetPassword() {
    const [loading, setLoading] = useState(false)
    const { id, token } = useParams()
    const dispatch = useDispatch()
    const { showNotification } = usePopup()

    const onSubmit = (values: any, { setStatus, setSubmitting }: any) => {
        setLoading(true)
        resetPassword(id as string, token as string, values.password)
            .then(({ data: { jwt } }) => {
                setLoading(false)
                dispatch(auth.actions.login(jwt))
            })
            .catch((err: Error) => {
                setLoading(false)
                setSubmitting(false)
                setStatus('Errors in password reset')
                showNotification({ text: `Errors in password reset: ${err.message}`, type: 'error' })
            })
    }

    return (
        <Container component="main" maxWidth="xs">
            <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
                    <PasswordIcon />
                </Avatar>
                <Typography component="h1" variant="h5">
                    Set your new password
                </Typography>
                <Formik noValidate initialValues={initialValues} validationSchema={resetScheme} onSubmit={onSubmit}>
                    {({ values, touched, errors, handleChange, handleBlur, isValid, dirty, isSubmitting }) => (
                        <Form>
                            <Grid container spacing={2} sx={{ mt: 3, mb: 3 }}>
                                <Grid item xs={12}>
                                    <Typography sx={{ fontWeight: 'bold' }} color="lightgrey" gutterBottom>
                                        Use 8 or more characters with a mix of letters, numbers & symbols
                                    </Typography>
                                    <TextField
                                        required
                                        fullWidth
                                        name="password"
                                        label="Password"
                                        type="password"
                                        id="password"
                                        autoComplete="new-password"
                                        error={touched.password && Boolean(errors.password)}
                                        helperText={touched.password && errors.password}
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        value={values.password}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        required
                                        fullWidth
                                        name="passwordConfirmation"
                                        label="Password confirmation"
                                        type="password"
                                        id="passwordConfirmation"
                                        autoComplete="new-password"
                                        error={touched.passwordConfirmation && Boolean(errors.passwordConfirmation)}
                                        helperText={touched.passwordConfirmation && errors.passwordConfirmation}
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        value={values.passwordConfirmation}
                                    />
                                </Grid>
                            </Grid>
                            <LoadingButton loading={isSubmitting} loadingIndicator="Please wait..." type="submit" fullWidth variant="contained">
                                Submit
                            </LoadingButton>
                        </Form>
                    )}
                </Formik>
            </Box>
        </Container>
    )
}
