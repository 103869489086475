import React, { useState } from 'react'
import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import { Alert, Button, Card, CardContent, CircularProgress, Divider, Snackbar, Stack, TextField, Typography } from '@mui/material'
import { useFormik } from 'formik'
import { trelloConfigFormInitialValue, trelloConfigFormValidation } from './Models'
import { green } from '@mui/material/colors'
import { updateSettings } from '../RestClient'
import { AppConfig, TrelloConfigModel } from '../SettingsModel'

export function TrelloConfig({ config }: { config: AppConfig }) {
    const [loading, setLoading] = useState(false)
    const [snackBarOpen, setSnackBarOpen] = useState(false)

    const formik = useFormik<TrelloConfigModel>({
        initialValues: config.ticketingConfig?.trello || trelloConfigFormInitialValue,
        validationSchema: trelloConfigFormValidation,

        onSubmit: (values, helper) => {
            setLoading(true)
            config.ticketingConfig = {
                trello: {
                    apiKey: values.apiKey,
                    boardId: values.boardId,
                    listId: values.listId,
                    token: values.token
                }
            }

            updateSettings(config)
                .then((data) => {
                    setLoading(false)
                    setSnackBarOpen(true)
                })
                .catch((err) => {
                    setLoading(false)
                })
        }
    })

    return (
        <Card>
            <CardContent>
                <Stack direction={'row'}>
                    <Typography sx={{ fontSize: 24, fontWeight: 'bold', width: 1 }} variant="h4">
                        Trello Config
                    </Typography>
                    <Box>
                        <Button
                            variant={'contained'}
                            disabled={loading || !(formik.values.token && formik.values.apiKey && formik.values.listId && formik.values.boardId)}
                            onClick={() => formik.submitForm()}
                        >
                            Save
                        </Button>
                        {loading && (
                            <CircularProgress
                                size={24}
                                sx={{
                                    color: green[500],
                                    position: 'absolute',
                                    top: '50%',
                                    left: '50%',
                                    marginTop: '-12px',
                                    marginLeft: '-12px'
                                }}
                            />
                        )}
                    </Box>
                </Stack>
                <Divider sx={{ m: 2 }} />
                <Box sx={{ flexGrow: 1 }}>
                    <Grid container spacing={2}>
                        <Grid item xs={4}>
                            <Typography>Unique Trello board identifier</Typography>
                        </Grid>
                        <Grid item xs={8}>
                            <TextField
                                sx={{ width: '100%' }}
                                id="boardId"
                                label="Board ID"
                                variant="outlined"
                                error={formik.touched.boardId && Boolean(formik.errors.boardId)}
                                helperText={formik.touched.boardId && formik.errors.boardId}
                                onBlur={formik.handleBlur}
                                onChange={formik.handleChange}
                                value={formik.values.boardId}
                            />
                        </Grid>

                        <Grid item xs={4}>
                            <Typography>Trello API key</Typography>
                        </Grid>
                        <Grid item xs={8}>
                            <TextField
                                sx={{ width: '100%' }}
                                id="apiKey"
                                label="Trello API key"
                                variant="outlined"
                                error={formik.touched.apiKey && Boolean(formik.errors.apiKey)}
                                helperText={formik.touched.apiKey && formik.errors.apiKey}
                                onBlur={formik.handleBlur}
                                onChange={formik.handleChange}
                                value={formik.values.apiKey}
                            />
                        </Grid>
                        <Grid item xs={4}>
                            <Typography>Trello token</Typography>
                        </Grid>
                        <Grid item xs={8}>
                            <TextField
                                sx={{ width: '100%' }}
                                multiline
                                minRows={3}
                                id="token"
                                label="Token"
                                variant="outlined"
                                error={formik.touched.token && Boolean(formik.errors.token)}
                                helperText={formik.touched.token && formik.errors.token}
                                onBlur={formik.handleBlur}
                                onChange={formik.handleChange}
                                value={formik.values.token}
                            />
                        </Grid>
                        <Grid item xs={4}>
                            <Typography>Id of the Trello list/column to create new tickets</Typography>
                        </Grid>
                        <Grid item xs={8}>
                            <TextField
                                sx={{ width: '100%' }}
                                id="listId"
                                label="List ID"
                                variant="outlined"
                                error={formik.touched.listId && Boolean(formik.errors.listId)}
                                helperText={formik.touched.listId && formik.errors.listId}
                                onBlur={formik.handleBlur}
                                onChange={formik.handleChange}
                                value={formik.values.listId}
                            />
                        </Grid>
                    </Grid>
                </Box>
            </CardContent>
            <Snackbar anchorOrigin={{ vertical: 'top', horizontal: 'center' }} message="Saved" open={snackBarOpen} autoHideDuration={6000} onClose={() => setSnackBarOpen(false)}>
                <Alert onClose={() => setSnackBarOpen(false)} severity="success" sx={{ width: '100%' }}>
                    Settings saved!
                </Alert>
            </Snackbar>
        </Card>
    )
}
