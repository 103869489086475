import React, { useEffect } from 'react'
import { Box, Button, Card, Divider, Stack, Typography } from '@mui/material'
import { Entitlement, ProfileModel } from '../auth/models/AuthModel'
import Grid from '@mui/material/Unstable_Grid2'

export const PlanUpgrade: React.FC<Props> = ({ profile, entitlement }) => {
    useEffect(() => {
        const script = document.createElement('script')
        script.src = 'https://js.stripe.com/v3/pricing-table.js'
        script.async = true
        document.body.appendChild(script)

        return () => {
            document.body.removeChild(script)
        }
    }, [])

    console.log('profile', profile)

    // Current tier information is shown in the first column
    // If the subscription tier is free, the second column shows the upgrade plan with 2 options
    // if the subscription tier is 'basic', the second column shows the upgrade plan with 1 option and a billing button to the billing portal
    // if the subscription tier is 'enterprise', the second column shows the billing button to the billing portal
    const currentPlan = profile.entitlement.tier.current

    const currentTier = (
        <Stack direction={'column'}>
            <Typography variant={'h6'}>Your current plan</Typography>
            <Divider sx={{ mt: 1, mb: 1 }} />
            <Grid container spacing={1} columns={2}>
                <Grid xs={1}>
                    <Typography>Plan status:</Typography>
                </Grid>
                <Grid xs={1}>
                    <Typography>{profile.entitlement.status}</Typography>
                </Grid>

                <Grid xs={1}>
                    <Typography>Plan name:</Typography>
                </Grid>
                <Grid xs={1}>
                    <Typography>{currentPlan.displayName}</Typography>
                </Grid>

                <Typography>Features:</Typography>

                {currentPlan.features.map((feature, index) => (
                    <Box>
                        <Grid xs={1}>
                            <Typography>{feature.key}</Typography>
                        </Grid>
                        <Grid xs={1}>
                            <Typography>{feature.key}</Typography>
                        </Grid>
                    </Box>
                ))}
                <Grid xs={2}>
                    <Typography sx={{ fontWeight: 'bold' }}>Rate Experience</Typography>
                </Grid>
                <Grid xs={1}>
                    <Typography>Response Limit:</Typography>
                </Grid>
                <Grid xs={1}>
                    <Typography>{profile.entitlement.tier.current.rateExp.responseLimit}</Typography>
                </Grid>

                <Grid xs={2}>
                    <Typography sx={{ fontWeight: 'bold' }}>Survey </Typography>
                </Grid>
                <Grid xs={1}>
                    <Typography>Response Limit:</Typography>
                </Grid>
                <Grid xs={1}>
                    <Typography>{profile.entitlement.tier.current.survey.responseLimit}</Typography>
                </Grid>

                {currentPlan.callToAction && (
                    <Button
                        variant={'outlined'}
                        sx={{ width: '100%', height: '6rem', mt: 2 }}
                        onClick={() => {
                            window.open(currentPlan.callToAction?.url, '_blank')
                        }}
                    >
                        {currentPlan.callToAction.text}
                    </Button>
                )}
            </Grid>
        </Stack>
    )

    // profile.entitlement.stripe.tier.current.name = 'basic'
    // profile.entitlement.stripe.tier.upsell.pricingTableId = null

    let content = null
    if (profile.entitlement.tier.current.name === 'free') {
        content = (
            <Stack direction={{ sm: 'column', md: 'row' }} spacing={2} sx={{ m: 2 }}>
                <Box
                    sx={{
                        borderRadius: 1,
                        border: '1px solid',
                        borderColor: '#e0e0e0',
                        p: 1,
                        width: { sm: '100%', md: '30%' }
                    }}
                >
                    {currentTier}
                </Box>
                {profile.entitlement.tier.upsell?.pricingTableId && (
                    <Stack
                        direction={'column'}
                        sx={{
                            borderRadius: 1,
                            border: '1px solid',
                            borderColor: '#e0e0e0',
                            p: 1,
                            width: { sm: '100%', md: '65%' }
                        }}
                    >
                        <Typography variant={'h6'} sx={{ m: 1 }}>
                            Upgrade your your plan to enable Premium features
                        </Typography>
                        {React.createElement('stripe-pricing-table', {
                            'client-reference-id': profile.productName,
                            'customer-email': profile.currentUser.email,
                            'pricing-table-id': profile.entitlement.tier.upsell.pricingTableId,
                            'publishable-key': profile.entitlement.stripe.publishingToken
                        })}
                    </Stack>
                )}
            </Stack>
        )
    } else if (profile.entitlement.tier.current.name === 'basic') {
        const upsellPlan = profile.entitlement.tier.upsell

        content = (
            <Stack direction={{ sm: 'column', md: 'row' }} spacing={2} sx={{ m: 2 }}>
                <Box
                    sx={{
                        borderRadius: 1,
                        border: '1px solid',
                        borderColor: '#e0e0e0',
                        p: 1,
                        width: { sm: '100%', md: '50%' }
                    }}
                >
                    {currentTier}
                </Box>
                {upsellPlan && (
                    <Stack
                        direction={'column'}
                        sx={{
                            borderRadius: 1,
                            border: '1px solid',
                            borderColor: '#e0e0e0',
                            p: 1,
                            width: { sm: '100%', md: '50%' }
                        }}
                    >
                        <Typography variant={'h6'} sx={{ m: 1 }}>
                            Upgrade your your plan to enable Premium features
                        </Typography>
                        <Divider sx={{ mt: 1, mb: 1 }} />
                        <Grid container spacing={1} columns={2}>
                            <Grid xs={1}>
                                <Typography>Plan name:</Typography>
                            </Grid>
                            <Grid xs={1}>
                                <Typography>{upsellPlan.displayName}</Typography>
                            </Grid>

                            <Typography>Features:</Typography>

                            {upsellPlan.features.map((feature, index) => (
                                <Box>
                                    <Grid xs={1}>
                                        <Typography>{feature.key}</Typography>
                                    </Grid>
                                    <Grid xs={1}>
                                        <Typography>{feature.key}</Typography>
                                    </Grid>
                                </Box>
                            ))}
                            {upsellPlan.callToAction && (
                                <Button
                                    variant={'outlined'}
                                    sx={{ width: '100%', height: '6rem', mt: 2 }}
                                    onClick={() => {
                                        window.open(upsellPlan.callToAction?.url, '_blank')
                                    }}
                                >
                                    {upsellPlan.callToAction.text}
                                </Button>
                            )}
                        </Grid>
                    </Stack>
                )}
            </Stack>
        )
    } else {
        content = (
            <Stack direction={{ sm: 'column', lg: 'row' }} spacing={2} sx={{ m: 2 }}>
                {currentTier}
            </Stack>
        )
    }
    return <Card>{content}</Card>
}

interface Props {
    profile: ProfileModel
    entitlement: Entitlement
}
