import { FC, Suspense } from 'react'
import { Navigate, Route, Routes } from 'react-router-dom'
import TopBarProgress from 'react-topbar-progress-indicator'
import { getCSSVariableValue } from '../../_metronic/assets/ts/_utils'
import { MasterLayout } from '../../_metronic/layout/MasterLayout'
import { GalleryPage } from '../modules/gallery/Gallery'
import ProfilePage from '../modules/profile/ProfilePage'
import { Settings } from '../modules/settings/Settings'
import { SurveyItemRootPage } from '../modules/survey/survey/root/SurveyItemRootPage'
import SurveyBank from '../modules/survey/survey/SurveyBank'

const PrivateRoutes = () => {
    return (
        <Routes>
            <Route element={<MasterLayout />}>
                {/* Redirect to Dashboard after success login/registartion */}
                <Route path="auth/*" element={<Navigate to="/" />} />
                {/* Pages */}
                <Route
                    path="survey/:survey_id/*"
                    element={
                        <SuspensedView>
                            <SurveyItemRootPage />
                        </SuspensedView>
                    }
                />
                <Route
                    path="surveys_home/*"
                    element={
                        <SuspensedView>
                            <SurveyBank />
                        </SuspensedView>
                    }
                />
                {/* Lazy Modules */}
                <Route
                    path="profile/*"
                    element={
                        <SuspensedView>
                            <ProfilePage />
                        </SuspensedView>
                    }
                />
                <Route
                    path="gallery/*"
                    element={
                        <SuspensedView>
                            <GalleryPage />
                        </SuspensedView>
                    }
                />
                <Route
                    path="settings"
                    element={
                        <SuspensedView>
                            <Settings />
                        </SuspensedView>
                    }
                />
                <Route index element={<Navigate to="/surveys_home/bank" />} />
                {/* Page Not Found */}
                <Route path="*" element={<Navigate to="/error/404" />} />
            </Route>
        </Routes>
    )
}

const SuspensedView: FC<Props> = ({ children }) => {
    const baseColor = getCSSVariableValue('--bs-primary')
    TopBarProgress.config({
        barColors: {
            '0': baseColor
        },
        barThickness: 1,
        shadowBlur: 5
    })
    return <Suspense fallback={<TopBarProgress />}>{children}</Suspense>
}

interface Props {
    children: React.ReactNode
}

export { PrivateRoutes }
