import React from 'react'
import { FileModel } from './models/File'

import { ImageList, ImageListItem, ImageListItemBar } from '@mui/material'
import DeleteIcon from '@mui/icons-material/Delete'
import IconButton from '@mui/material/IconButton'

export const GalleryItems: React.FC<Props> = ({ files, removeFile }) => {
    return (
        <>
            <ImageList variant="masonry" cols={8} gap={8}>
                {files.map((el) => (
                    <ImageListItem key={el.path}>
                        <img src={`${el.url}`} srcSet={`${el.url}`} alt={el.key} loading="lazy" />

                        <ImageListItemBar
                            title={el.key}
                            actionIcon={
                                <IconButton
                                    sx={{ color: 'rgba(255, 255, 255, 0.54)' }}
                                    aria-label={`info about ${el.key}`}
                                    onClick={() => {
                                        removeFile(el?.id)
                                    }}
                                >
                                    <DeleteIcon />
                                </IconButton>
                            }
                        />
                    </ImageListItem>
                ))}
            </ImageList>
        </>
    )
}

export interface Props {
    files: FileModel[]
    removeFile(id?: string): Promise<void>
}
