import { AppBar, Box, GlobalStyles } from '@mui/material'
import { Outlet, useLocation } from 'react-router-dom'
import { Topbar } from './components/header/Topbar'
import { TimeRange, TimeRangeContext } from './TimeRangeContext'
import { useEffect, useState } from 'react'
import { DateTime } from 'luxon'

export const MasterLayout = () => {
    const location = useLocation()
    const [timeRange, setTimeRange] = useState<TimeRange>(getTimeRangeFromQueryParams(location.search))

    useEffect(() => {
        setTimeRangeToQueryParams(timeRange)
    }, [timeRange])

    return (
        <TimeRangeContext.Provider value={{ timeRange, setTimeRange }}>
            <Box style={{ width: '100%' }}>
                <AppBar position="static" sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}>
                    <Topbar />
                </AppBar>
                <Outlet />
            </Box>
        </TimeRangeContext.Provider>
    )
}

const getTimeRangeFromQueryParams = (search: string): TimeRange => {
    const params = new URLSearchParams(search)

    let start = DateTime.now().startOf('month').toJSDate()
    if (params.get('start') && typeof params.get('start') === 'number') {
        start = new Date(parseInt(params.get('start') || '0'))
    }
    let end = DateTime.now().endOf('day').toJSDate()
    if (params.get('end ') && typeof params.get('end ') === 'number') {
        start = new Date(parseInt(params.get('end ') || '0'))
    }
    return { start, end }
}

const setTimeRangeToQueryParams = (timeRange: TimeRange) => {
    const params = new URLSearchParams()
    // convert timeRange.start to unix timestamp
    params.set('start', timeRange.start.getTime().toString())
    params.set('end', timeRange.end.getTime().toString())
    window.history.replaceState({}, '', `?${params.toString()}`)
}
