import axios from 'axios'
import { FileUploadResponseModel } from './models/FileUploadResponseModel'
import { FileUploadRequestModel } from './models/FileDeleteResponseModel'
import { FileModel } from './models/File'

export const uploadFiles = async (formData: FormData, data: any): Promise<FileUploadResponseModel> => {
    try {
        const data = await axios.post<FileUploadResponseModel>(`/dashboard/gallery/`, formData, {
            headers: { ...{ 'Content-Type': 'multipart/form-data' } }
        })
        return data.data
    } catch (err) {
        console.error(`upload files :: ${JSON.stringify(err, null, 2)}`)
        throw err
    }
}

export const deleteFile = async (id: string): Promise<FileUploadRequestModel> => {
    try {
        const data = await axios.delete<FileUploadRequestModel>(`/dashboard/gallery/${id}`)

        return data.data
    } catch (err) {
        console.error(`upsert tags :: ${JSON.stringify(err, null, 2)}`)
        throw err
    }
}

export const fetchFiles = async (): Promise<FileModel[]> => {
    try {
        const data = await axios.get<{ status: boolean; files: FileModel[] }>(`/dashboard/gallery/`)
        return data.data.files
    } catch (err) {
        throw err
    }
}
