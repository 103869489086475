import { ModalSettings } from '@feeba/types'
import { Box, Divider, Slider, Stack, Typography } from '@mui/material'
import { FC } from 'react'
import { useSelector } from 'react-redux'
import { Props } from '../../builder/SurveyBuilder'
import { ActionTypes } from '../../builder/state/actions'
import { IState, surveyDispatcher } from '../../builder/state/hooks'
import { ModalLocationSelection } from './ModalLocationSelection'

export const ModalSettingsComponent: FC<Props> = () => {
    const dispatch = surveyDispatcher()
    const surveyToEdit = useSelector((state: IState) => state.survey)

    const updateGlobalSurveyInstance = (data: ModalSettings) => {
        // console.log("Style::dispatching survey update")
        dispatch({
            type: ActionTypes.SURVEY_BODY_UPDATE,
            payload: {
                survey: {
                    ...surveyToEdit,
                    presentation: {
                        ...surveyToEdit?.presentation,
                        modalSettings: data
                    }
                }
            }
        })
    }

    return (
        <Stack sx={{ padding: '2rem', width: '100%', gap: '1.5rem' }}>
            {/* Color Settings Block */}
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: '1rem', // общий отступ между элементами
                    padding: '1rem',
                    backgroundColor: '#f9f9f9',
                    borderRadius: '8px',
                    boxShadow: '0 2px 8px rgba(0,0,0,0.1)'
                }}
            >
                <Typography variant="h6">Positioning</Typography>
                <ModalLocationSelection
                    modalPosition={surveyToEdit.presentation.modalSettings.modalPosition}
                    onPositionChange={(position) =>
                        updateGlobalSurveyInstance({
                            ...surveyToEdit.presentation.modalSettings,
                            modalPosition: position
                        })
                    }
                />
                <Divider sx={{ pt: 1, pb: 1 }} />
                <Typography variant="h6" sx={{ pt: 2 }}>
                    Sizing
                </Typography>
                <Typography sx={{ pt: 2 }}>Survey Max width</Typography>
                <Typography fontSize={'small'} sx={{ pt: 2 }}>
                    When the knob is all the way on the left side, only up to 50% of the screen will be occupied VERTICALLY by survey. It is recommended to keep this value around
                    70%
                </Typography>
                <Slider
                    aria-label="y_margin"
                    sx={{ width: 400, pt: 2, pb: 2 }}
                    min={50}
                    max={100}
                    defaultValue={70}
                    getAriaValueText={(value: number) => `${value}%`}
                    step={1}
                    marks
                    valueLabelDisplay="on"
                    value={surveyToEdit.presentation.modalSettings.horizontalSizeInPercent}
                    onChange={(_, newValue: number | number[]) => {
                        updateGlobalSurveyInstance({
                            ...surveyToEdit.presentation.modalSettings,
                            horizontalSizeInPercent: newValue as number
                        })
                    }}
                />
                <Typography sx={{ pt: 2 }}>Survey Max height</Typography>
                <Typography fontSize={'small'} sx={{ pt: 2 }}>
                    When the knob is all the way on the left side, only up to 50% of the screen will be occupied HORIZONTALLY by survey. It is recommended to keep this value to 90%
                </Typography>
                <Slider
                    aria-label="x_margin"
                    sx={{ width: 400, pt: 2, pb: 2 }}
                    min={50}
                    max={100}
                    defaultValue={90}
                    getAriaValueText={(value: number) => `${value}%`}
                    step={1}
                    marks
                    valueLabelDisplay="on"
                    value={surveyToEdit.presentation.modalSettings.verticalSizeInPercent}
                    onChange={(event: Event, newValue: number | number[]) => {
                        updateGlobalSurveyInstance({
                            ...surveyToEdit.presentation.modalSettings,
                            verticalSizeInPercent: newValue as number
                        })
                    }}
                />
            </Box>
        </Stack>
    )
}
