import React, { useState } from 'react'
import Box from '@mui/material/Box'
import { Alert, Button, Card, CardContent, CircularProgress, Divider, IconButton, Snackbar, Stack, TextField, Typography } from '@mui/material'

import { AppConfig } from '../SettingsModel'
import { generateToken, updateSettings } from '../RestClient'
import { green } from '@mui/material/colors'
import { ContentCopy, DeleteForever } from '@mui/icons-material'

export function TokenConfig({ config }: { config: AppConfig }) {
    const [data, setData] = useState(config)
    const [loading, setLoading] = useState(false)
    const [snackBarOpen, setSnackBarOpen] = useState(false)

    const onGenerateClick = () => {
        setLoading(true)
        generateToken()
            // .then(config => config.tokens.so)
            .finally(() => setLoading(false))
            .then((config) => setData(config))
            .catch((e) => console.error(JSON.stringify(e, null, 2)))
    }
    const postUpdatedData = (config: AppConfig) => {
        setLoading(true)
        updateSettings(config)
            .finally(() => setLoading(false))
            .then((_) => setData(config))
            .catch((e) => console.error(JSON.stringify(e, null, 2)))
    }

    return (
        <Card>
            <CardContent>
                <Typography sx={{ fontWeight: 'bold', width: 1 }} variant="h4">
                    SDK Tokens
                </Typography>
                <Divider sx={{ m: 2 }} />
                <Stack direction={'row'} justifyContent="end">
                    <Typography sx={{ width: 1 }}>Generate a Token. The token will be used by SDKs to talk to backend</Typography>
                    <Box>
                        <Button variant={'contained'} color="error" disabled={loading} onClick={onGenerateClick}>
                            Generate
                        </Button>
                        {loading && (
                            <CircularProgress
                                size={24}
                                sx={{
                                    color: green[500],
                                    position: 'absolute',
                                    top: '50%',
                                    left: '50%',
                                    marginTop: '-12px',
                                    marginLeft: '-12px'
                                }}
                            />
                        )}
                    </Box>
                </Stack>
                <Divider sx={{ m: 2 }} />

                {data.tokens?.map((renderItem) => (
                    <Stack direction={'row'} key={renderItem.created.toString()}>
                        <TextField label={renderItem.created.toString()} sx={{ width: 0.2 }} disabled={true}></TextField>
                        <TextField label="Token" value={renderItem.jwtToken} sx={{ width: 1 }} onChange={() => {}}></TextField>
                        <IconButton
                            color="primary"
                            aria-label="upload picture"
                            component="span"
                            onClick={() => {
                                navigator.clipboard.writeText(renderItem.jwtToken)
                                setSnackBarOpen(true)
                            }}
                        >
                            <ContentCopy />
                        </IconButton>
                        <IconButton
                            color="error"
                            aria-label="upload picture"
                            component="span"
                            onClick={() => {
                                data.tokens = data.tokens.filter((t) => t !== renderItem)
                                postUpdatedData({ ...data })
                            }}
                        >
                            <DeleteForever />
                        </IconButton>
                    </Stack>
                ))}
            </CardContent>
            <Snackbar anchorOrigin={{ vertical: 'top', horizontal: 'center' }} message="Saved" open={snackBarOpen} autoHideDuration={6000} onClose={() => setSnackBarOpen(false)}>
                <Alert onClose={() => setSnackBarOpen(false)} severity="success" sx={{ width: '100%' }}>
                    Token copied to clipboard.
                </Alert>
            </Snackbar>
        </Card>
    )
}
