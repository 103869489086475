import { Help } from '@mui/icons-material'
import LockOutlinedIcon from '@mui/icons-material/LockOutlined'
import { Divider, FormControl, InputAdornment, InputLabel, Link as MuiLink, OutlinedInput, Tooltip } from '@mui/material'
import Avatar from '@mui/material/Avatar'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Checkbox from '@mui/material/Checkbox'
import Container from '@mui/material/Container'
import FormControlLabel from '@mui/material/FormControlLabel'
import Grid from '@mui/material/Grid'
import TextField from '@mui/material/TextField'
import Typography from '@mui/material/Typography'
import { Form, Formik } from 'formik'
import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { Link, useNavigate } from 'react-router-dom'
import * as Yup from 'yup'
import { register } from '../redux/AuthCRUD'
import * as auth from '../redux/AuthRedux'
import { Copyright } from './Login'
import { TermsAndConditions } from './TermsConditionsDialog'
import { LoadingButton } from '@mui/lab'
import usePopup from '../../../popups/notifications/hook'
import { Feeba } from '@feeba/web-sdk'
import { AUTH_TOKEN } from '../../../../setup/axios/SetupAxios'

const initialValues = {
    firstName: '',
    lastName: '',
    projectName: '',
    email: '',
    password: '',
    passwordConfirmation: '',
    acceptTerms: false
}

const registrationSchema = Yup.object().shape({
    firstName: Yup.string().min(3, 'Minimum 3 symbols').max(50, 'Maximum 50 symbols').required('First name is required'),
    email: Yup.string().email('Wrong email format').min(3, 'Minimum 3 symbols').max(50, 'Maximum 50 symbols').required('Email is required'),
    lastName: Yup.string().min(3, 'Minimum 3 symbols').max(50, 'Maximum 50 symbols').required('Last name is required'),
    projectName: Yup.string().min(1).max(50).required('Project Name is required'),
    password: Yup.string().min(3, 'Minimum 3 symbols').max(50, 'Maximum 50 symbols').required('Password is required'),
    passwordConfirmation: Yup.string()
        .required('Password confirmation is required')
        .oneOf([Yup.ref('password')], "Password and Confirm Password didn't match"),

    acceptTerms: Yup.bool().required('You must accept the terms and conditions')
})

export function Registration() {
    const [termsAndConditionsDialogOpen, setTermsAndConditionsDialogOpen] = useState(false)

    const dispatch = useDispatch()
    const { showNotification } = usePopup()
    const navigate = useNavigate()

    const onSubmit = (values: any, { setStatus, setSubmitting }: any) => {
        register(values.email, values.firstName, values.lastName, values.projectName, values.password, values.passwordConfirmation)
            .then(({ data: { jwt } }) => {
                localStorage.setItem(AUTH_TOKEN, jwt as string)
                dispatch(auth.actions.register(jwt))
            })
            .catch((err) => {
                setSubmitting(false)
                setStatus('Registration process has broken')
                showNotification({
                    text: `Registration failed with error: ${(err as Error).message}`,
                    type: 'error'
                })
            })
    }

    useEffect(() => {
        // Linked to: https://dashboard.feeba.io/survey/66084d4cf5418b4bc8bed9aa
        Feeba.pageOpened('registration')
        return () => {
            Feeba.pageClosed('registration')
        }
    }, [])

    return (
        <Container component="main" maxWidth="xs">
            <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
                    <LockOutlinedIcon />
                </Avatar>
                <Typography component="h1" variant="h5">
                    Create an Account
                </Typography>
                <Formik sx={{ mt: 3 }} noValidate initialValues={initialValues} validationSchema={registrationSchema} onSubmit={onSubmit}>
                    {({ values, touched, errors, handleChange, handleBlur, isValid, dirty, isSubmitting }) => (
                        <Form>
                            <Grid container spacing={2}>
                                <Grid item xs={12} sm={6}>
                                    <TextField
                                        margin="normal"
                                        autoComplete="given-name"
                                        name="firstName"
                                        required
                                        fullWidth
                                        id="firstName"
                                        label="First Name"
                                        autoFocus
                                        error={touched.firstName && Boolean(errors.firstName)}
                                        helperText={touched.firstName && errors.firstName}
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        value={values.firstName}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <TextField
                                        margin="normal"
                                        required
                                        fullWidth
                                        id="lastName"
                                        label="Last Name"
                                        name="lastName"
                                        autoComplete="family-name"
                                        error={touched.lastName && Boolean(errors.lastName)}
                                        helperText={touched.lastName && errors.lastName}
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        value={values.lastName}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        required
                                        fullWidth
                                        id="email"
                                        label="Email Address"
                                        name="email"
                                        autoComplete="email"
                                        error={touched.email && Boolean(errors.email)}
                                        helperText={touched.email && errors.email}
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        value={values.email}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <FormControl variant="outlined" fullWidth required>
                                        <InputLabel htmlFor="outlined-adornment-password">Project name</InputLabel>
                                        <OutlinedInput
                                            id="projectName"
                                            label="Project name"
                                            error={touched.projectName && Boolean(errors.projectName)}
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            value={values.projectName}
                                            endAdornment={
                                                <InputAdornment position="end">
                                                    <Tooltip title="Your project name. Once it is set, it can not be changed">
                                                        <Help fontSize="small" />
                                                    </Tooltip>
                                                </InputAdornment>
                                            }
                                        />
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12}>
                                    <Divider sx={{ mt: 1, mb: 1 }} />
                                    <Typography sx={{ fontWeight: 'bold' }} color="lightgrey" gutterBottom>
                                        Use 8 or more characters with a mix of letters, numbers & symbols
                                    </Typography>
                                    <TextField
                                        required
                                        fullWidth
                                        name="password"
                                        label="Password"
                                        type="password"
                                        id="password"
                                        autoComplete="new-password"
                                        error={touched.password && Boolean(errors.password)}
                                        helperText={touched.password && errors.password}
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        value={values.password}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        required
                                        fullWidth
                                        name="passwordConfirmation"
                                        label="Password confirmation"
                                        type="password"
                                        id="passwordConfirmation"
                                        autoComplete="new-password"
                                        error={touched.passwordConfirmation && Boolean(errors.passwordConfirmation)}
                                        helperText={touched.passwordConfirmation && errors.passwordConfirmation}
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        value={values.passwordConfirmation}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <FormControlLabel
                                        control={<Checkbox value="allowExtraEmails" color="primary" checked={values.acceptTerms} />}
                                        label={
                                            <Typography sx={{ fontWeight: 'bold' }} color="text.secondary">
                                                {'I Agree to the '}
                                                <MuiLink color="#00f" onClick={() => setTermsAndConditionsDialogOpen(true)}>
                                                    terms and conditions
                                                </MuiLink>
                                                {'.'}
                                            </Typography>
                                        }
                                        name="acceptTerms"
                                        onChange={handleChange}
                                    />
                                </Grid>
                            </Grid>
                            <LoadingButton
                                loading={isSubmitting}
                                loadingIndicator="Please wait..."
                                type="submit"
                                fullWidth
                                variant="contained"
                                sx={{ mt: 3, mb: 2 }}
                                disabled={!isValid || !values.acceptTerms}
                            >
                                Submit
                            </LoadingButton>
                            <Button fullWidth variant="contained" disabled={isSubmitting} sx={{ mb: 2 }} onClick={() => navigate('/auth/login')}>
                                Cancel
                            </Button>
                            <Grid container justifyContent="flex-end">
                                <Grid item>
                                    <Link to="/auth/login">Already have an account? Sign in</Link>
                                </Grid>
                            </Grid>
                        </Form>
                    )}
                </Formik>
            </Box>
            <Copyright sx={{ mt: 5 }} />
            {termsAndConditionsDialogOpen && <TermsAndConditions isOpen={termsAndConditionsDialogOpen} onClose={() => setTermsAndConditionsDialogOpen(false)} />}
        </Container>
    )
}
