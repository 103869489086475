import axios from 'axios'
import { SurveyLanguageUiModel, QuestionsModel, SurveyListModel } from './Models'
import { SurveyUpdateModel, SurveyModel } from '@feeba/types'

// ------------------------------ SURVEY ---------------------------------
export const deleteSurvey = (id: string): Promise<void> => {
    return axios
        .delete<void>(`/survey/dashboard/survey/${id}`)
        .then((data) => {
            console.log(`delete survey :: ${JSON.stringify(data.data, null, 2)}`)
            return
        })
        .catch((err) => {
            console.error(`fetchTickets :: ${JSON.stringify(err, null, 2)}`)
            throw err
        })
}
export const fetchSurveyList = (shouldValidate: boolean = false): Promise<Array<SurveyListModel>> => {
    return axios
        .get<Array<SurveyListModel>>(`/survey/dashboard/survey`)
        .then((data) =>
            data.data.map((survey, i) => ({
                id: survey.id,
                name: survey.name,
                status: survey.status,
                responses: survey.responses,
                type: survey.type,
                owner: {
                    name: survey.owner.name
                },
                created: new Date(survey.created),
                updated: new Date(survey.updated)
            }))
        )
        .catch((err) => {
            console.error(`Error fetching surveys`, err)
            console.error(`Error fetching surveys :: ${JSON.stringify(err, null, 2)}`)
            throw err
        })
}

export const fetchSurvey = (id: string, lang?: string): Promise<SurveyModel> => {
    return axios
        .get<SurveyModel>(`/survey/dashboard/survey/${id}`, { params: { lang } })
        .then((data) => {
            const surveyUiModel = data.data
            return surveyUiModel
        })
        .catch((err) => {
            console.error(`Error fetching tickets`, err)
            throw err
        })
}

export const fetchSurveyLanguages = (id: string): Promise<SurveyLanguageUiModel[]> => {
    return axios
        .get<SurveyLanguageUiModel[]>(`/survey/dashboard/survey/languages/${id}`)
        .then((data) => {
            const result = data.data
            return result
        })
        .catch((err) => {
            console.error(`Error fetching tickets`, err)
            throw err
        })
}

export const createSurveyLanguage = (surveyId: string, data: SurveyLanguageUiModel[]): Promise<void> => {
    return axios
        .post<void>(`/survey/dashboard/survey/languages/${surveyId}`, { data })
        .then((data) => {
            console.log(`createSurveyLanguage :: ${JSON.stringify(data.data, null, 2)}`)
            return
        })
        .catch((err) => {
            console.error(`createSurveyLanguage :: ${JSON.stringify(err, null, 2)}`)
            throw err
        })
}

export const deleteSurveyLanguage = (surveyId: string, langCode: string): Promise<void> => {
    return axios
        .delete<void>(`/survey/dashboard/survey/languages/${surveyId}/${langCode}`)
        .then((data) => {
            console.log(`deleteSurveyLanguage :: ${JSON.stringify(data.data, null, 2)}`)
            return
        })
        .catch((err) => {
            console.error(`deleteSurveyLanguage :: ${JSON.stringify(err, null, 2)}`)
            throw err
        })
}

export const updateDefaultSurveyLanguage = ({ surveyId, langCode, label }: { surveyId: string; langCode: string; label: string }): Promise<void> => {
    return axios
        .put<void>(`/survey/dashboard/survey/languages/change-default/${surveyId}`, { langCode, label })
        .then((data) => {
            console.log(`updateDefaultSurveyLanguage :: ${JSON.stringify(data.data, null, 2)}`)
            return
        })
        .catch((err) => {
            console.error(`updateDefaultSurveyLanguage :: ${JSON.stringify(err, null, 2)}`)
            throw err
        })
}

export const createSurvey = (surveyName: string): Promise<{ id: string }> => {
    return axios
        .post<{ id: string }>(`/survey/dashboard/survey`, { name: surveyName })
        .then((data) => data.data)
        .catch((err) => {
            console.error(`Error fetching tickets`, err)
            throw err
        })
}

export const createSurveyTemplate = (surveyName: string, surveyType: string): Promise<{ id: string }> => {
    return axios
        .post<{ id: string }>(`/survey/dashboard/survey/template`, { name: surveyName, type: surveyType })
        .then((data) => data.data)
        .catch((err) => {
            console.error(`Error fetching tickets`, err)
            throw err
        })
}

export const upsertSurvey = (survey: SurveyModel, excludes: string[] = []): Promise<void> => {
    // console.log("RestClient::upsertSurvey", survey);
    const surveyUpdateModel: SurveyUpdateModel = {
        id: survey.id,
        survey: {
            data: {
                ...survey,
                items: survey.items.map((category) => {
                    return {
                        ...category,
                        categoryItems: category.categoryItems
                    }
                })
            }
        }
    }

    console.log(surveyUpdateModel)

    return axios
        .put(`/survey/dashboard/survey`, surveyUpdateModel, { params: { excludes: excludes.join(',') } })
        .then((data) => {
            console.log(`Upsert Survey :: ${JSON.stringify(data.data, null, 2)}`)
        })
        .catch((err) => {
            console.error(`fetchTickets :: ${JSON.stringify(err, null, 2)}`)
            throw err
        })
}

export const deleteQuestion = (question: QuestionsModel): Promise<void> => {
    return axios
        .delete<void>(`/survey/dashboard/question/${question.id}`)
        .then((data) => {
            return
        })
        .catch((err) => {
            console.error(`fetchTickets :: ${JSON.stringify(err, null, 2)}`)
            throw err
        })
}

export const getSurveyResult = (surveyId: string, lang: string): Promise<any> => {
    return axios
        .get<any>(`/survey/dashboard/survey/surveyResult/${surveyId}`, { params: { lang } })
        .then((data) => {
            return data.data
        })
        .catch((err) => {
            console.error(`fetchTickets :: ${JSON.stringify(err, null, 2)}`)
            throw err
        })
}

export const getSurveyTextResult = (surveyId: string, questionId: string, lang: string, page: number, pageSize: number): Promise<any> => {
    return axios
        .get<any>(`/survey/dashboard/survey/surveyTextResult/${surveyId}`, { params: { lang, page, pageSize, questionId } })
        .then((data) => {
            return data.data
        })
        .catch((err) => {
            console.error(`fetchTickets :: ${JSON.stringify(err, null, 2)}`)
            throw err
        })
}

export const getSurveyCount = (surveyId: string): Promise<any> => {
    return axios
        .get<any>(`/survey/dashboard/survey/surveyCount/${surveyId}`)
        .then((data) => {
            return data.data
        })
        .catch((err) => {
            console.error(`getSurveyCount :: ${JSON.stringify(err, null, 2)}`)
            throw err
        })
}

export const getSurveyTableData = (surveyId: string, lang: string, page: number, pageSize: number): Promise<any> => {
    return axios
        .get<any>(`/survey/dashboard/survey/surveyTable/${surveyId}`, { params: { lang, page, pageSize } })
        .then((data) => {
            return data.data
        })
        .catch((err) => {
            console.error(`getSurveyTableData :: ${JSON.stringify(err, null, 2)}`)
            throw err
        })
}

export const tableSaveAsCSV = async (surveyId: string, lang: string): Promise<any> => {
    return axios
        .get<any>(`/survey/dashboard/survey/surveyTable/${surveyId}/csv`, {
            params: { lang },
            responseType: 'blob'
        })
        .then((data) => {
            return data.data
        })
        .catch((err) => {
            console.error(`tableSaveAsCSV :: ${JSON.stringify(err, null, 2)}`)
            throw err
        })
}
