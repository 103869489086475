import { TriggerRule } from '@feeba/types'
import { FormControl, MenuItem, Select, SelectChangeEvent, Stack, TextField, Typography } from '@mui/material'
import { FC, useState } from 'react'

export interface EventTriggerData {
    property: string
    propertyHint: string
    operations: Array<{ value: string; label: string }>
    valueHint: string
}

export const EventTrigger: FC<Props> = ({ initValue, onUpdate, data }) => {
    const [conditional, setConditional] = useState(initValue.conditional)
    const [eventNameVariable, setEventNameVariable] = useState(initValue.eventName)
    const [valueVariable, setValueVariable] = useState(initValue.value)

    const handleChangeEventName = (eventName: string) => {
        onUpdate({ ...initValue, eventName: eventName })
        setEventNameVariable(eventName)
    }
    const handleSelectChange = (event: SelectChangeEvent) => {
        const conditionalValue = event.target.value
        let value = valueVariable
        if (conditionalValue === 'ex' && value.trim() === '') {
            value = 'true'
            setValueVariable(value)
        }
        setConditional(conditionalValue)
        onUpdate({ ...initValue, conditional: conditionalValue, value })
    }
    const handleChangeValue = (value: string) => {
        setValueVariable(value)
        onUpdate({ ...initValue, value })
    }
    return (
        <Stack direction="row" justifyContent="space-between" spacing={1} alignContent={'center'} alignItems={'center'}>
            <Typography variant="body1">{data.property}</Typography>
            <TextField
                label={data.propertyHint}
                onChange={(event) => handleChangeEventName(event.target.value)}
                value={eventNameVariable}
                variant="outlined"
                sx={{ minWidth: 150, bgcolor: 'white' }}
                size="small"
            />
            <FormControl size="small">
                <Select labelId="demo-simple-select-label" id="demo-simple-select" sx={{ minWidth: 150, bgcolor: 'white' }} value={conditional} onChange={handleSelectChange}>
                    {data.operations.map((operator, index) => {
                        return (
                            <MenuItem key={index} value={operator.value}>
                                {operator.label}
                            </MenuItem>
                        )
                    })}
                </Select>
            </FormControl>
            <TextField
                label={data.valueHint}
                variant="outlined"
                value={valueVariable}
                onChange={(event) => handleChangeValue(event.target.value)}
                sx={{ minWidth: 150, bgcolor: 'white' }}
                size="small"
            />
        </Stack>
    )
}

export interface Props {
    initValue: TriggerRule
    data: EventTriggerData
    onUpdate: (rule: TriggerRule) => void
}
