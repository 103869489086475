import { ProfileHeader } from './ProfileHeader'
import React, { useEffect } from 'react'
import { PlanUpgrade } from './PlanUpgrade'
import { ProfileModel } from '../auth/models/AuthModel'
import { fetchProfileInformation } from './RestClient'
import { CircularProgress } from '@mui/material'

const ProfilePage: React.FC = () => {
    const [profile, setProfile] = React.useState<ProfileModel | null>(null)

    useEffect(() => {
        fetchProfileInformation()
            .then((response) => {
                console.log(response.data)
                setProfile(response.data)
            })
            .catch((error) => {
                console.log(error)
            })
    }, [])

    let element
    if (profile) {
        element = (
            <>
                <ProfileHeader profile={profile} />
                <PlanUpgrade entitlement={profile.entitlement} profile={profile} />
            </>
        )
    } else {
        element = (
            <CircularProgress
                size={24}
                sx={{
                    color: 'primary.main',
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    marginTop: '-12px',
                    marginLeft: '-12px'
                }}
            />
        )
    }
    return element
}

export default ProfilePage
