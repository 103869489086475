// Redux
// https://github.com/rt2zz/redux-persist
import { PersistGate } from 'redux-persist/integration/react'
import { Provider } from 'react-redux'
import store, { persistor } from './setup/redux/Store'
// Axios
import { ThemeProvider } from '@mui/material/styles'
/**
 * TIP: Replace this style import with dark styles to enable dark mode
 *
 * import './_metronic/assets/sass/style.dark.scss'
 *
 * TIP: Replace this style import with rtl styles to enable rtl mode
 *
 * import './_metronic/assets/css/style.rtl.css'
 **/
import { AppRoutes } from './app/routing/AppRoutes'
import { PopupProvider } from './app/popups/notifications/GlobalPoup'
import NotificationPopup from './app/popups/notifications/NotificationPopup'
import { createRoot } from 'react-dom/client'
import theme from './setup/muijs_theme'
import { Box, GlobalStyles } from '@mui/material'
import { Feeba } from '@feeba/web-sdk'

/**
 * Creates `axios-mock-adapter` instance for provided `axios` instance, add
 * basic Metronic mocks and returns it.
 *
 * @see https://github.com/ctimmerm/axios-mock-adapter
 */

Feeba.init(
    'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJleHAiOjE3NjIxMzMyNjEsInBheWxvYWQiOnsidXNlcklkIjoiNjNlMTY1NmI1YzQ2NWM4Y2U1NGE1NjY2IiwicHJvamVjdE5hbWUiOiJmZWViYSJ9LCJpYXQiOjE2NzU3MzMyNjF9.u1QKFr15wJQcEgyVjaLd0aU5A-XZoOaFGqFnGOSIXhE'
)
const container = document.getElementById('root')
if (!container) throw Error('Root container not found')
createRoot(container).render(
    <Provider store={store}>
        {/* Asynchronously persist redux stores and show `SplashScreen` while it's loading. */}
        <PersistGate persistor={persistor} loading={<div>Loading...</div>}>
            <ThemeProvider theme={theme}>
                <GlobalStyles styles={{ body: { backgroundColor: '#eee' } }} />
                <Box sx={{ height: '100vh', overflow: 'auto' }}>
                    <PopupProvider>
                        <AppRoutes />
                        <NotificationPopup />
                    </PopupProvider>
                </Box>
            </ThemeProvider>
        </PersistGate>
    </Provider>
)
