import { Divider, Stack, Tab, Tabs } from '@mui/material'
import { FC, useState } from 'react'
import { Navigate, Outlet, Route, Routes, useLocation, useNavigate } from 'react-router-dom'
import { Props } from '../builder/SurveyBuilder'
import { LookAndFeelTopBar } from './LookAndFeelTopBar'
import { ModalSettingsComponent } from './modal'
import { Style } from './style/Style'

interface TabValue {
    label: string
    path: string
    page: (() => JSX.Element) | null
}

const tabs: TabValue[] = [
    { label: 'Theme', path: 'theme', page: null },
    { label: 'Layout', path: 'layout', page: null },
    { label: 'Modal Setup', path: 'modal', page: () => <ModalSettingsComponent /> },
    { label: 'Style', path: 'general', page: () => <Style /> },
    { label: 'General', path: 'style', page: null },
    { label: 'Motion', path: 'motion', page: null },
    { label: 'Logo', path: 'logo', page: null },
    { label: 'Background', path: 'background', page: null }
]

const defaultTabIndes = 2
export const LookAndFeelPage: FC<Props> = () => {
    const location = useLocation()
    const navigate = useNavigate()

    const findCurrentTab = () => {
        const path = location.pathname.split('/')[5]
        // Check if any of the tab Values is in the pathname, if yes return the index of that tab, else return 0
        const index = tabs.findIndex((tab) => path === tab.path)

        console.log('LookAndFeelPage::index ', index)
        return index === -1 ? defaultTabIndes : index
    }
    const [tabIndex, setTabIndex] = useState(findCurrentTab)

    return (
        <Routes>
            <Route
                element={
                    <Stack direction={'column'} sx={{ width: '100%' }}>
                        <LookAndFeelTopBar />
                        <Stack id="looknfeel_stack" direction={'row'} sx={{ height: '100%', width: '100%' }}>
                            <Tabs
                                sx={{ backgroundColor: 'white', height: '100%', width: '8rem' }}
                                TabIndicatorProps={{ style: { width: '.5rem' } }}
                                value={tabIndex}
                                orientation="vertical"
                                onChange={(event, newValue) => {
                                    setTabIndex(newValue)
                                    navigate(tabs[newValue]?.path)
                                }}
                            >
                                {tabs.map((tab, index) => (
                                    <Tab
                                        label={tab.label}
                                        key={index}
                                        disabled={!tab.page}
                                        sx={{
                                            borderBottom: '1px solid gray',
                                            minWidth: '100%',
                                            width: '100%',
                                            padding: '1rem 0'
                                        }}
                                    />
                                ))}
                            </Tabs>
                            <Divider orientation="vertical" flexItem />
                            <Outlet />
                        </Stack>
                    </Stack>
                }
            >
                {tabs.map((tab, index) => (
                    <Route key={index} path={tab.path} element={tab.page ? tab.page() : <div>{tab.label} - Page not found</div>} />
                ))}
                <Route path="*" element={<Navigate to={tabs[defaultTabIndes].path} />} />
                <Route index element={<Navigate to={tabs[defaultTabIndes].path} />} />
            </Route>
        </Routes>
    )
}
