import { FC } from 'react'

import { MultipleChoiceQuestionUiModel } from '@feeba/types'
import { Grid, TextField, Typography } from '@mui/material'

export const MultilanguageAnswerComponent: FC<Props> = ({ changeAnswerTextBySelectedLanguage, answerIndex, answer, survey, categoryIndex, index }) => {
    const isEmpty = () => {
        // console.log(
        //   (
        //     survey?.items[index]?.categoryItems[
        //       categoryIndex
        //     ] as MultipleChoiceQuestionUiModel
        //   )?.answers[answerIndex]?.text
        // );
        const text = (survey?.items[index]?.categoryItems[categoryIndex] as MultipleChoiceQuestionUiModel)?.answers[answerIndex]?.text || null

        if (!text) {
            return true
        }
        return false
    }
    return (
        <>
            <Grid container xs={12} key={`mlqa-${categoryIndex}`} spacing={0}>
                <Grid item xs={6} style={{ background: '#fafafa', padding: '10px' }}>
                    {answerIndex === 0 && (
                        <Typography variant="body1" style={{ marginBottom: '10px' }}>
                            Choices
                        </Typography>
                    )}

                    <TextField fullWidth label="Answer" name="answer" size="small" variant="outlined" value={answer.text} aria-readonly />
                </Grid>
                <Grid item xs={6} style={{ background: '#fff', padding: '10px' }}>
                    {answerIndex === 0 && (
                        <Typography variant="body1" style={{ marginBottom: '10px' }}>
                            Choices
                        </Typography>
                    )}

                    <TextField
                        color={isEmpty() ? 'warning' : 'primary'}
                        focused
                        fullWidth
                        label=""
                        name={`answer-${index}-${categoryIndex}-${answerIndex}`}
                        size="small"
                        placeholder={answer.text}
                        value={(survey?.items[index]?.categoryItems[categoryIndex] as MultipleChoiceQuestionUiModel)?.answers[answerIndex]?.text || ''}
                        onChange={(event) => {
                            changeAnswerTextBySelectedLanguage({
                                text: event.target.value,
                                itemIndex: index,
                                categoryIndex: categoryIndex,
                                answerIndex: answerIndex
                            })
                        }}
                    />
                </Grid>
            </Grid>
        </>
    )
}

export interface Props {
    changeAnswerTextBySelectedLanguage: (data: { text: string; itemIndex: number; categoryIndex: number; answerIndex: number }) => void
    answerIndex: number
    answer: any
    survey: any
    categoryIndex: number
    index: number
}
