import AccountCircleIcon from '@mui/icons-material/AccountCircle'
import { Avatar, Card, CardContent, List, ListItem, ListItemAvatar, ListItemText, Stack, Typography } from '@mui/material'
import Grid from '@mui/material/Unstable_Grid2'
import React from 'react'
import { ProfileModel } from '../auth/models/AuthModel'

const ProfileHeader: React.FC<Props> = ({ profile }) => {
    return (
        <Card sx={{ width: '100%', mb: 2 }}>
            <CardContent>
                <Stack direction={{ sm: 'column', lg: 'row' }} spacing={2}>
                    <Stack
                        direction="row"
                        sx={{
                            borderRadius: 1,
                            border: '1px solid',
                            borderColor: '#e0e0e0',
                            p: 1,
                            height: '100%',
                            width: '100%'
                        }}
                    >
                        <AccountCircleIcon
                            sx={{
                                minWidth: { xs: 75, sm: 100, md: 120, lg: 150 },
                                minHeight: { xs: 75, sm: 100, md: 120, lg: 150 }
                            }}
                        />
                        <Stack direction="column" spacing={2} sx={{ ml: 4 }}>
                            <Grid container spacing={1} columns={2}>
                                <Grid xs={1}>
                                    <Typography sx={{ fontWeight: 'bold' }}>First name:</Typography>
                                </Grid>
                                <Grid xs={1}>
                                    <Typography>{profile.currentUser.firstName}</Typography>
                                </Grid>
                                <Grid xs={1}>
                                    <Typography sx={{ fontWeight: 'bold' }}>Last name:</Typography>
                                </Grid>
                                <Grid xs={1}>
                                    <Typography>{profile.currentUser.lastName}</Typography>
                                </Grid>
                                <Grid xs={1}>
                                    <Typography sx={{ fontWeight: 'bold' }}>Role:</Typography>
                                </Grid>
                                <Grid xs={1}>
                                    <Typography>{profile.currentUser.role}</Typography>
                                </Grid>
                                <Grid xs={1}>
                                    <Typography sx={{ fontWeight: 'bold' }}>Email:</Typography>
                                </Grid>
                                <Grid xs={1}>
                                    <Typography>{profile.currentUser.email}</Typography>
                                </Grid>
                            </Grid>
                        </Stack>
                    </Stack>
                    <Stack
                        direction="column"
                        sx={{
                            borderRadius: 1,
                            border: '1px solid',
                            borderColor: '#e0e0e0',
                            p: 1,
                            width: '100%'
                        }}
                    >
                        <Typography sx={{ fontWeight: 'bold' }}>All users:</Typography>
                        <List style={{ maxHeight: '100%', overflow: 'auto' }}>
                            {profile.allUsers.map((user, index) => {
                                return (
                                    <ListItem alignItems="flex-start" sx={{ m: -1 }} key={index}>
                                        <ListItemAvatar>
                                            <Avatar alt={user.firstName + ' ' + user.lastName} />
                                        </ListItemAvatar>
                                        <ListItemText primary={user.firstName + ' ' + user.lastName} secondary={`email: ${user.email}, role: ${user.role}`} />
                                    </ListItem>
                                )
                            })}
                        </List>
                    </Stack>
                </Stack>
            </CardContent>
        </Card>
    )
}

export { ProfileHeader }

interface Props {
    profile: ProfileModel
}
