import { TriggerDistributionConfig } from '@feeba/types'
import { Save, SaveAsRounded } from '@mui/icons-material'
import { Box, Card, CardContent, CardHeader, FormControl, FormControlLabel, List, ListItem, Paper, Radio, RadioGroup, SpeedDial, SpeedDialAction, Stack } from '@mui/material'
import { FC, useEffect, useState } from 'react'
import usePopup from '../../../../../popups/notifications/hook'
import { fetchTriggersConfig, upsertTriggers } from './RestClient'
import { ScheduleSectionComponent } from './ScheduleSectionComponent'
import { SectionTriggers } from './SectionTriggers'

export const TriggerEditor: FC<Props> = ({ surveyId }) => {
    const [triggersConfig, setTriggersConfig] = useState<TriggerDistributionConfig | null>(null)
    const [errorData, setErrorData] = useState<any | null>(null)

    const { showNotification } = usePopup()
    const jumpAnimation = `
  @keyframes jump {
      0%, 100% { transform: translateY(0); }
      50% { transform: translateY(-10px); }
  }
`

    useEffect(() => {
        // fetch triggers
        fetchTriggersConfig(surveyId)
            .then((config) => setTriggersConfig(config))
            .catch((err) => {
                console.error(err)
                showNotification({ text: `Loading error: ${err.message}`, type: 'error' })
            })
    }, [])

    return triggersConfig ? (
        <Paper sx={{ bgcolor: 'lightgray' }} style={{ maxHeight: '100vh', overflowX: 'auto', width: '99%', padding: 0 }}>
            <Stack
                style={{ width: '100%' }}
                direction="column"
                justifyContent="flex-start"
                alignItems="stretch"
                spacing={2}
                sx={{ pr: 8, pl: 8, pt: 4, pb: 4, padding: 0, margin: 0 }}
            >
                {errorData && (
                    <List sx={{ bgcolor: 'error.main', color: 'error.contrastText', border: '2px solid #f00' }} dense>
                        {errorData.map((err: string, index: number) => (
                            <ListItem key={index}>{err}</ListItem>
                        ))}
                    </List>
                )}
                <Card>
                    <CardHeader title={'1. Audiance'}></CardHeader>
                    <CardContent>
                        <FormControl disabled>
                            <RadioGroup name="radio-buttons-group">
                                <FormControlLabel value="0" control={<Radio size="small" />} label="Show to all users" />
                                <FormControlLabel value="1" control={<Radio size="small" />} label="Show to Particular Segment(s)" />
                            </RadioGroup>
                        </FormControl>
                    </CardContent>
                </Card>

                <SectionTriggers
                    initData={triggersConfig.triggers}
                    onUpdate={(updated) => {
                        console.log(`SectionTriggers Updated: ${JSON.stringify(updated, null, 2)}`)
                        setTriggersConfig({ ...triggersConfig, triggers: updated })
                    }}
                />

                <Card>
                    <CardHeader title={'3. Schedule'}></CardHeader>
                    <CardContent>
                        <ScheduleSectionComponent
                            initialConfig={triggersConfig.schedule}
                            onUpdate={(updated) => {
                                setTriggersConfig({ ...triggersConfig, schedule: updated })
                            }}
                        />
                    </CardContent>
                </Card>
            </Stack>
            <Box
                sx={{
                    position: 'absolute',
                    bottom: '10px',
                    right: 10,
                    height: 330,
                    transform: 'translateZ(0px)',
                    flexGrow: 1
                }}
            >
                <div>
                    <style>{jumpAnimation}</style>
                    <SpeedDial
                        ariaLabel="SpeedDial tooltip example"
                        sx={{
                            position: 'absolute',
                            bottom: 18,
                            right: 100,
                            '& .MuiSpeedDial-fab': {
                                width: '85px', // Adjust width of the circle
                                height: '85px' // Adjust height of the circle
                            },
                            animation: 'jump 0.5s ease-in-out infinite',
                            '&:hover': {
                                animation: 'none' // Stops the animation on hover
                            }
                        }}
                        icon={<SaveAsRounded style={{ fontSize: '55px' }} />}
                    >
                        <SpeedDialAction
                            key={'Save'}
                            icon={<Save />}
                            tooltipTitle={'Save'}
                            tooltipOpen
                            onClick={() => {
                                const errorData = validate(triggersConfig)
                                if (errorData.length > 0) {
                                    console.error(`Error: ${errorData}`)
                                    showNotification({ text: `Error: ${errorData}`, type: 'error' })
                                    setErrorData(errorData)
                                    return
                                } else {
                                    setErrorData(null)
                                    upsertTriggers(surveyId, triggersConfig)
                                        .then((res) => {
                                            console.log(res)
                                            showNotification({ text: 'Saved', type: 'success' })
                                        })
                                        .catch((err) => {
                                            console.error(err)
                                            showNotification({ text: `Error: ${err.message}`, type: 'error' })
                                        })
                                }
                            }}
                        />
                        {/* <SpeedDialAction
                            key={'Save&Enable'}
                            icon={<Send />}
                            tooltipTitle={'Save&Enable'}
                            tooltipOpen
                            onClick={() => {
                                console.log('Save&Enable -> TODO')
                            }}
                        /> */}
                    </SpeedDial>
                </div>
            </Box>
        </Paper>
    ) : (
        <Paper sx={{ bgcolor: 'lightgray' }} style={{ maxHeight: '100vh', overflow: 'auto', width: '100%' }}>
            <Stack
                style={{ maxHeight: '100%', overflow: 'auto' }}
                direction="column"
                justifyContent="flex-start"
                alignItems="stretch"
                spacing={2}
                sx={{ pr: 8, pl: 8, pt: 4, pb: 4 }}
            >
                <div style={{ width: '100%' }}>Loading...</div>
            </Stack>
        </Paper>
    )
}
export interface Props {
    surveyId: string
}

const validate = (toValidate: TriggerDistributionConfig): string[] => {
    console.log(`Validate: ${JSON.stringify(toValidate, null, 2)}`)
    const errorData: string[] = []
    toValidate.triggers.screen.rules
        .map((ruleSet) => ruleSet.triggers)
        .flat()
        .forEach((rule, index) => {
            if (rule.conditional.trim().length === 0) {
                errorData.push(`OnPage::Rule ${index + 1}'s(${rule.type}) condition is empty`)
            }
            if (rule.value.trim().length === 0) {
                errorData.push(`OnPage::Rule ${index + 1}'s(${rule.type}) value is empty`)
            }
        })
    toValidate.triggers.conditional.rules
        .map((ruleSet) => ruleSet.triggers)
        .flat()
        .forEach((rule, index) => {
            if (rule.conditional.trim().length === 0) {
                errorData.push(`OnEvent::Rule ${index + 1}'s(${rule.type}) condition is empty`)
            }
            if (rule.value.trim().length === 0) {
                errorData.push(`OnEvent::Rule ${index + 1}'s(${rule.type}) value is empty`)
            }
        })
    if (errorData.length > 0) {
        console.error(`Error: ${errorData}`)
    }
    return errorData
}
