import { TextField } from '@mui/material'
import { TextFieldProps } from '@mui/material/TextField/TextField'
import { FC } from 'react'

export const FramelessEdit: FC<TextFieldProps & Props> = (props) => {
    // This is done to avoid the warning: "Warning: Received `true` for a non-boolean attribute `frameless`."
    const updatedProps = { ...props }
    updatedProps.frameless = undefined

    return (
        <TextField
            {...updatedProps}
            inputProps={{ autoComplete: 'off', ...updatedProps.inputProps }}
            sx={{
                '&:hover fieldset': {
                    borderColor: 'gray',
                    backgroundColor: 'rgba(0,0,0, 0.2)',
                    borderWidth: props.frameless ? 0 : 1
                },
                '&.Mui-focused fieldset': {
                    borderColor: props.frameless ? 'black' : 'transparent',
                    backgroundColor: 'transparent'
                    // color: "black"
                },
                '& .MuiOutlinedInput-root': {
                    '& fieldset': {
                        borderWidth: 0
                    }
                },
                ...updatedProps.sx
            }}
        />
    )
}

export interface Props {
    frameless?: boolean
}
