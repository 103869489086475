import { RuleSet, RuleType, TriggerRule } from '@feeba/types'
import { AlarmOn, Close, Sell, Timelapse } from '@mui/icons-material'
import { Box, Divider, IconButton, Stack, Typography } from '@mui/material'
import { FC, useState } from 'react'
import { AddConditionButton, MenuItemData } from './AddConditionButton'
import { DurationSinceLastInApp } from './DurationSinceLastInApp'
import { EventTrigger, EventTriggerData } from './EventTrigger'
import { SessionDuration } from './SessionDuration'
import { StartWithKnobBlock } from './StartWithKnobBlock'

export const pagesData = {
    eventsData: {
        property: 'Page Trigger:',
        propertyHint: 'Page name',
        operations: [{ value: 'eq', label: 'equals' }],
        valueHint: 'Value'
    } as EventTriggerData,
    sessionDurationData: {
        property: 'Wait time:',
        operations: [{ value: 'gt', label: 'greater than' }],
        valueHint: 'Enter number'
    } as EventTriggerData,
    sinceLastData: {
        property: 'Since last survey:',
        operations: [
            { value: 'gt', label: 'greater than' },
            { value: 'gte', label: 'greater than or equal' },
            { value: 'lt', label: 'less than' },
            { value: 'lte', label: 'less than or equal' }
        ],
        valueHint: 'Enter number'
    } as EventTriggerData
}

export const pagesMenus: MenuItemData[] = [
    {
        label: 'Page Trigger',
        icon: <Sell />,
        type: RuleType.SCREEN,
        default: {
            type: RuleType.SCREEN,
            eventName: 'YOUR_SCREEN_NAME_HERE',
            value: '1',
            conditional: pagesData.eventsData.operations[0].value
        }
    },
    {
        label: 'Timer to open a survey',
        icon: <AlarmOn />,
        type: RuleType.SESSION_DURATION,
        default: {
            type: RuleType.SESSION_DURATION,
            eventName: RuleType.SESSION_DURATION,
            value: '90',
            conditional: pagesData.sessionDurationData.operations[0].value
        }
    },
    {
        label: 'Duration since Survey',
        icon: <Timelapse />,
        type: RuleType.SINCE_LAST,
        default: {
            type: RuleType.SINCE_LAST,
            eventName: RuleType.SINCE_LAST,
            value: '86400',
            conditional: pagesData.sinceLastData.operations[0].value
        }
    }
]

export const PageConditionalAndBlock: FC<Props> = ({ initData, onAllTriggerRulesRemoved, updateRuleSet }) => {
    const [ruleSet, setRuleSet] = useState<RuleSet>(initData)

    const addingNewRule = (newRule: TriggerRule) => {
        for (const rule of ruleSet.triggers) {
            // Ensure there is no rule with the same type
            if (rule.type === newRule.type) {
                return
            }
        }
        const updatedRules: RuleSet = {
            triggers: [],
            startWithKnob: ruleSet.startWithKnob
        }
        if (newRule.type === RuleType.EVENT) {
            // Event should stay on top of the rule set
            updatedRules.triggers = [newRule, ...ruleSet.triggers]
        } else {
            updatedRules.triggers = [...ruleSet.triggers, newRule]
        }

        setRuleSet(updatedRules)
        updateRuleSet(updatedRules)
    }

    const updateSigneRuleByIndex = (indexConditional: number, rule: TriggerRule) => {
        // No need to update the state. State is updated by the child component. Array value is used to send to server
        ruleSet.triggers[indexConditional] = rule
        updateRuleSet(ruleSet)
    }

    const disabledTypes: RuleType[] = []
    if (ruleSet.triggers.filter((r) => r.type === RuleType.SCREEN).length > 0) {
        disabledTypes.push(...ruleSet.triggers.map((r) => r.type))
    }

    return (
        <Stack
            direction="column"
            justifyContent="center"
            alignItems="stretch"
            spacing={2}
            sx={{
                bgcolor: '#f5f4f2',
                p: 2,
                border: '1px solid #D3D3D3',
                borderRadius: 2
            }}
        >
            {ruleSet.triggers.map((rule, index, arr) => {
                let ruleComponent: JSX.Element | null = null

                if (rule.type === RuleType.SCREEN) {
                    ruleComponent = <EventTrigger data={pagesData.eventsData} key={index} initValue={rule} onUpdate={(newRule) => updateSigneRuleByIndex(index, newRule)} />
                }
                if (rule.type === RuleType.SESSION_DURATION) {
                    ruleComponent = (
                        <SessionDuration data={pagesData.sessionDurationData} key={index} initValue={rule} onUpdate={(newRule) => updateSigneRuleByIndex(index, newRule)} />
                    )
                } else if (rule.type === RuleType.SINCE_LAST) {
                    ruleComponent = (
                        <DurationSinceLastInApp data={pagesData.sinceLastData} key={index} initValue={rule} onUpdate={(newRule) => updateSigneRuleByIndex(index, newRule)} />
                    )
                } else {
                }
                if (ruleComponent == null) return <div></div>

                return (
                    <Stack direction="row" justifyContent="space-between" spacing={1} key={index} alignContent={'center'} alignItems={'center'}>
                        {ruleComponent}
                        <Box sx={{ flexGrow: 1 }} />
                        {index === arr.length - 1 ? (
                            <AddConditionButton
                                onSelection={addingNewRule}
                                disabledTypes={[...ruleSet.triggers.filter((e) => e.type !== RuleType.SINCE_LAST).map((r) => r.type), RuleType.SCREEN]}
                                menuItems={pagesMenus}
                            />
                        ) : (
                            <Typography>Add</Typography>
                        )}
                        <IconButton
                            onClick={() => {
                                console.log('delete rule', rule)
                                // If user is deleting Page name rule, then all rules should be deleted
                                if (rule.type === RuleType.SCREEN) {
                                    onAllTriggerRulesRemoved()
                                    return
                                }

                                if (ruleSet.triggers.length === 1) {
                                    onAllTriggerRulesRemoved()
                                } else {
                                    const newRuleSet: RuleSet = {
                                        triggers: [...ruleSet.triggers],
                                        startWithKnob: ruleSet.startWithKnob
                                    }
                                    newRuleSet.triggers.splice(index, 1)
                                    setRuleSet(newRuleSet)
                                    updateRuleSet(newRuleSet)
                                }
                            }}
                        >
                            <Close />
                        </IconButton>
                    </Stack>
                )
            })}
            <Divider sx={{ bgcolor: 'black', mx: -1, my: -1 }} />
            <StartWithKnobBlock
                initValue={ruleSet.startWithKnob}
                onUpdate={(updated) => {
                    const updatedRuleSet = { ...ruleSet, startWithKnob: updated }
                    setRuleSet(updatedRuleSet)
                    updateRuleSet(updatedRuleSet)
                }}
            />
        </Stack>
    )
}

export interface Props {
    initData: RuleSet
    onAllTriggerRulesRemoved: () => void
    updateRuleSet: (rules: RuleSet) => void
}
