import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import DialogTitle from '@mui/material/DialogTitle'
import * as React from 'react'
import { toAbsoluteUrl } from '../../../../_metronic/helpers'

export const TermsAndConditions: React.FC<Props> = ({ isOpen, onClose }) => {
    const [terms, setTerms] = React.useState('')

    React.useEffect(() => {
        fetch(toAbsoluteUrl('/terms.txt'))
            .then((res) => {
                console.log('Reading file: ', res)
                return res.text()
            })
            .then((text) => setTerms(text))
            .catch((err) => console.log(err))
    }, [])

    return (
        <Dialog fullWidth maxWidth={'lg'} open={isOpen} onClose={onClose}>
            <DialogTitle>Terms and Conditions</DialogTitle>
            <DialogContent>
                <DialogContentText>{terms}</DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={() => onClose()}>Close</Button>
            </DialogActions>
        </Dialog>
    )
}

interface Props {
    isOpen: boolean
    onClose: () => void
}
