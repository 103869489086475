import {
    Answer,
    AnswerSelectionType,
    BipolarAnswerType,
    ItemType,
    MatrixTableQuestionUiModel,
    MatrixType,
    MessageEndModel,
    MultipleChoiceQuestionUiModel,
    NpsScoreQuestionUiModel,
    Statement,
    SurveyModel,
    TextEntryQuestionUiModel,
    defaultMessageEndOfSurvey
} from '@feeba/types'
import { ObjectID } from '../../../../../utils/uuid'
import { OptionalPageDividerUiModel } from './component/OptionalPageDividerComponent'

export const addInitialCategoryBlockIfEmpty = (survey: SurveyModel): SurveyModel => {
    if (survey.items.length === 0) {
        survey.items.push({
            id: ObjectID(),
            type: ItemType.Category,
            name: '',
            description: '',
            categoryItems: []
        })
    }
    return survey
}

export const adjustAnswers = (answers: Answer[], type: ItemType): Answer[] => {
    let newAnswers: Answer[] = answers
    if (type === ItemType.TextEntry) {
        newAnswers = answers.length === 0 ? [generateMockMultichoiceAnswer(0)] : [answers[0]]
    } else if (type === ItemType.NpsScore) {
        newAnswers = answers.length === 0 ? generateMockNpsScore(0).answers : [answers[0]]
    }
    return newAnswers
}

export const generateMockQuestion = (
    index: number,
    questionType: ItemType
): MultipleChoiceQuestionUiModel | TextEntryQuestionUiModel | MatrixTableQuestionUiModel | NpsScoreQuestionUiModel => {
    switch (questionType) {
        case ItemType.MultipleChoice:
            return generateMockMultiChoiceQuestion(index)
        case ItemType.TextEntry:
            return generateMockTextEntry(index)
        case ItemType.MatrixTable:
            return generateMockMatrixTable(index)
        case ItemType.NpsScore:
            return generateMockNpsScore(index)
        default:
            throw new Error(`Unknown question type: ${questionType}`)
    }
}
const generateMockMultiChoiceQuestion = (index: number): MultipleChoiceQuestionUiModel => {
    const newQuestion: MultipleChoiceQuestionUiModel = {
        id: ObjectID(),
        answerRandomization: {
            type: 'no',
            randomizedSubsetSize: 0
        },
        answerType: AnswerSelectionType.Multiple,
        answers: [generateMockMultichoiceAnswer(1), generateMockMultichoiceAnswer(2), generateMockMultichoiceAnswer(3)] as Array<Answer>,
        question: {
            text: `Question #${index}`
        },
        required: false,
        type: ItemType.MultipleChoice
    }
    return newQuestion
}

export const generateMockMultichoiceAnswer = (index: number): Answer => {
    return {
        id: ObjectID(),
        text: `Answer #${index}`,
        image: null,
        hint: '',
        type: 'predefined'
    }
}

const generateMockTextEntry = (index: number): TextEntryQuestionUiModel => {
    const newTextEntry: TextEntryQuestionUiModel = {
        id: ObjectID(),
        question: {
            text: `Thank you for your recent online order with our company!\nWe'd appreciate your honest feedback!\n\n\nThis survey will take about 2 minutes`
        },
        required: false,
        type: ItemType.TextEntry,
        answerType: AnswerSelectionType.Single,
        answers: [
            {
                id: ObjectID(),
                text: '',
                hint: 'Type here',
                type: 'custom'
            }
        ]
    }
    return newTextEntry
}

export const generateEndMessage = (): MessageEndModel => {
    const newEndMessage: MessageEndModel = {
        id: ObjectID(),
        value: defaultMessageEndOfSurvey,
        visible: true,
        required: true
    }
    return newEndMessage
}

const generateMockNpsScore = (index: number): NpsScoreQuestionUiModel => {
    const newNpsScore: NpsScoreQuestionUiModel = {
        id: ObjectID(),
        type: ItemType.NpsScore,
        question: {
            text: `* 1. How likely is it that you would recommend this company to a friend or colleague?`
        },
        required: false,
        answerType: AnswerSelectionType.Single,
        answers: [
            {
                id: ObjectID(),
                text: '0',
                hint: '',
                type: 'predefined'
            }
        ]
    }
    return newNpsScore
}

export const generateNewPageDivider = (): OptionalPageDividerUiModel => {
    return {
        type: ItemType.Divider,
        isSet: false
    }
}

const generateMockMatrixTable = (index: number): MatrixTableQuestionUiModel => {
    return {
        id: ObjectID(),
        type: ItemType.MatrixTable,
        question: {
            text: `Question #1`
        },
        statements: [generateMockStatement(1), generateMockStatement(2), generateMockStatement(3)],
        answers: [generateMockBlitzAnswer(0), generateMockBlitzAnswer(1), generateMockBlitzAnswer(2)],
        matrixType: {
            type: MatrixType.Bipolar,
            answerType: BipolarAnswerType.YesNo
        },
        answerRandomization: {
            type: 'no',
            randomizedSubsetSize: 0
        },
        required: false
    }
}

export const generateMockStatement = (index: number): Statement => {
    const newMockStatement = {
        id: ObjectID(),
        text: `Statement #${index}`,
        rightText: 'Right Text'
    }
    return newMockStatement
}
export const generateMockBlitzAnswer = (index: number): Answer => {
    let text = `Choice ${index + 1}`
    if (index === 0) text = 'Yes'
    else if (index === 1) text = 'Maybe'
    else if (index === 2) text = 'No'
    return { id: ObjectID(), text, image: null, type: 'predefined' }
}
