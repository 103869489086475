import { Feeba } from '@feeba/web-sdk'
import { BugReport } from '@mui/icons-material'
import { Box, Button, IconButton, Tooltip, Typography, Link as MuiJsLink, Stack, CardMedia } from '@mui/material'
import { Link, Outlet, Route, Routes, useNavigate } from 'react-router-dom'
import { toAbsoluteUrl } from '../../../_metronic/helpers'
import { pink } from '@mui/material/colors'

const ErrorsPage = () => {
    const navigate = useNavigate()
    return (
        <Box id="error-view" sx={{ height: '100vh', width: '100vw', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <Box component={Link} to="/" sx={{ position: 'absolute' }}>
                <Box component="img" src={toAbsoluteUrl('/media/404.svg')} alt="Go to homepage" sx={{ width: 'auto', height: '100vh' }} onClick={() => navigate('/')} />
            </Box>
            <Stack
                sx={{
                    position: 'absolute',
                    bottom: '20px',
                    alignItems: 'center',
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'center'
                }}
            >
                <Stack direction="row" spacing={2} alignItems="flex-end" sx={{ pb: 2 }}>
                    {/* Linked to: https://dashboard.feeba.io/survey/6689fcb056ad8437d91f2b07 */}
                    <MuiJsLink href="#" onClick={() => Feeba.triggerEvent('error_page_contact_us')}>
                        Contact Us
                    </MuiJsLink>{' '}
                    <Button sx={{ bgcolor: pink[100] }} variant="outlined" onClick={() => Feeba.triggerEvent('error_page_bug_report')}>
                        Report a bug
                        <BugReport />
                    </Button>
                </Stack>
                <Typography variant="caption">© 2024 Feeba.io. All Rights Reserved.</Typography>
            </Stack>
        </Box>
    )
}

export { ErrorsPage }
